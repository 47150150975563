import React, { useEffect, useRef, useState } from 'react';
import ResizableRect from '@posturize/react-resizable-rotatable-draggable';
import { useSelector } from 'react-redux';
import { selectPanZoomScale } from '@editor/reducers/panzoom.slice';

export const ResizableContent = ({
  locked,
  children,
  defaultX,
  defaultY,
  defaultWidth,
  defaultHeight,
  defaultRotateAngle,
  onResize,
  aspectRatio,
  onResizeEnd,
  onRotateEnd,
  onDragEnd,
}) => {
  const [allowDrag, setAllowDrag] = useState(false);
  const [width, setWidth] = useState<number>(defaultWidth);
  const [height, setHeight] = useState<number>(defaultHeight);
  const [top, setTop] = useState<number>(defaultY);
  const [left, setLeft] = useState<number>(defaultX);
  const [rotateAngle, setRotateAngle] = useState<number>(defaultRotateAngle);
  const assetContainer = useRef(null);
  const scale = useSelector(selectPanZoomScale);
  const controls = (window as any).panZoomControls;

  /* start: undo-redo */
  useEffect(() => {
    setRotateAngle(defaultRotateAngle);
  }, [defaultRotateAngle]);

  useEffect(() => {
    handleResize({ width: defaultWidth, height: defaultHeight, top: defaultY, left: defaultX })
  }, [defaultWidth, defaultHeight, defaultX, defaultY]) // eslint-disable-line
  /* end: undo-redo */

  const handleResize = ({ width, height, top, left }) => {
    const newWidth = Math.round(width);
    const newHeight = Math.round(height);

    setWidth(newWidth);
    setHeight(newHeight);
    setTop(Math.round(top));
    setLeft(Math.round(left));
    onResize({ width: newWidth, height: newHeight });
  };

  const handleRotate = (rotateAngle) => {
    setRotateAngle(rotateAngle);
  };

  const handleResizeEnd = () => {
    onResizeEnd({ width, height, top, left });
    controls.pan.disabled = false;
  }

  const handleDrag = (data) => {
    const { e, deltaX, deltaY } = data;
    e.stopPropagation();
    e.preventDefault();

    if (allowDrag) {
      setLeft(Math.round(left + deltaX));
      setTop(Math.round(top + deltaY));
    }
  };

  const handleDragStart = (e) => {
    setAllowDrag(true);
    controls.pan.disabled = true;
  };

  const handleDragEnd = () => {
    setAllowDrag(false);
    onDragEnd({ top, left });
    controls.pan.disabled = false;
  };

  const handleRotateEnd = () => {
    onRotateEnd(rotateAngle);
    controls.pan.disabled = false;
  };

  return (
    <>
      <div
        ref={assetContainer}
        style={{
          top,
          left,
          width,
          height,
          position: 'absolute',
          transform: `rotate(${rotateAngle}deg)`,
        }}
      >
        {children}
      </div>

      <ResizableRect
        scale={scale}
        top={top}
        rotatable
        left={left}
        aspectRatio={aspectRatio}
        width={width}
        height={height}
        onDrag={!locked ? handleDrag : null}
        onDragStart={!locked ? handleDragStart : null}
        onDragEnd={handleDragEnd}
        onRotate={handleRotate}
        onResize={!locked ? handleResize : null}
        onResizeEnd={handleResizeEnd}
        onRotateEnd={handleRotateEnd}
        zoomable="nw, ne, se, sw"
        rotateAngle={rotateAngle}
      />
    </>
  );
};
