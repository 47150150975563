import React, { FC, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { Link, useHistory } from 'react-router-dom';
import { Input, Button, Loader } from '@common/components';
import { useForm } from 'react-hook-form';
import { useToastContext } from '@common/context';

export const ResetPasswordContainer: FC<{ resetCode: string }> = ({
  resetCode,
}) => {
  const [isInvalidResetCode, setIsInvalidResetCode] = useState(false);
  const { register, handleSubmit, formState, errors } = useForm<{
    password: string;
  }>();
  const { addToast } = useToastContext();
  const [requestPasswordResetState, setRequestPasswordResetState] = useState({
    requestSent: false,
    loading: false,
  });
  const history = useHistory();

  useEffect(() => {
    firebase
      .auth()
      .verifyPasswordResetCode(resetCode)
      .catch(() => setIsInvalidResetCode(true));
  }, [resetCode]);

  return (
    <div className="h-full">
      <div className="h-full flex max-w-md m-auto items-center justify-center">
        <div className="w-full px-8 py-8 bg-white shadow-xl rounded-2xl">
          {isInvalidResetCode ? (
            <>
              <h1 className="font-light text-3xl py-4">
                This reset password link has expired or it is invalid.
              </h1>

              <div className="flex justify-between">
                <Link
                  to="/signup"
                  className="text-primary hover:text-primary-dark block text-center mt-4"
                >
                  Sign up
                </Link>
                <Link
                  to="/forgot-password"
                  className="text-primary hover:text-primary-dark block text-center mt-4"
                >
                  Forgot password
                </Link>
              </div>
            </>
          ) : (
            <form
              onSubmit={handleSubmit(({ password }) => {
                setRequestPasswordResetState((prev) => ({
                  ...prev,
                  loading: true,
                }));
                firebase
                  .auth()
                  .confirmPasswordReset(resetCode, password)
                  .then(() => {
                    addToast({
                      type: 'success',
                      title: 'Password changed',
                      message: 'Password changed successfully!',
                    });
                  })
                  .catch(() => {
                    addToast({
                      type: 'warning',
                      title: 'Something went wrong',
                      message: 'Something went wrong, try again later',
                    });
                  })
                  .finally(() => {
                    setRequestPasswordResetState((prev) => ({
                      ...prev,
                      loading: false,
                    }));
                    history.push('/login');
                  });
              })}
            >
              <h1 className="font-light text-3xl py-4 text-center font-medium">
                Choose a new password
              </h1>
              <Input
                ref={register({
                  required: true,
                  minLength: {
                    value: 8,
                    message: 'Password must have at least 8 characters',
                  },
                })}
                name="password"
                type="password"
                placeholder="New password"
                error={!!errors?.password}
                errorMessage={errors?.password?.message}
                className="mb-4"
              />

              <Button
                color="primary"
                size="large"
                type="submit"
                className="w-full"
                disabled={
                  !!errors?.password ||
                  !formState.isDirty ||
                  requestPasswordResetState.loading
                }
              >
                {requestPasswordResetState.loading ? (
                  <Loader className="flex justify-center" />
                ) : (
                  <>Reset password</>
                )}
              </Button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
