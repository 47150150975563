import React from 'react';
import * as InspectorUI from '../InspectorUI';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useLayers } from '@editor/hooks/useLayers';
import { Layer } from './Layer';
import useControls from '@editor/hooks/useControls';

export const LayersInspectorInterface = () => {
  const { layers, reorder } = useLayers();
  const { isControlled, setControlledObject, setFocusedObject } = useControls();

  const DraggableLayer = ({ layer, index }) => (
    <Draggable draggableId={layer} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {/* {layer} */}
          <Layer
            objectId={layer}
            controlled={isControlled(layer)}
            onSelected={() => setControlledObject(layer)}
            onFocused={() => setFocusedObject(layer)}
            onUnfocused={() => setFocusedObject(null)}
          />
        </div>
      )}
    </Draggable>
  );

  const LayerList = ({ layers }) => {
    return layers.map((layer: string, index: number) => (
      <DraggableLayer key={layer} layer={layer} index={index} />
    ));
  };

  return (
    <InspectorUI.Section title="Layers">
      {layers.length === 0 ? (
        <p className="text-center p-2">Canvas is empty.</p>
      ) : (
        <DragDropContext onDragEnd={reorder}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <LayerList layers={layers} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </InspectorUI.Section>
  );
};
