import shortid from 'shortid';
import { Canvas, CanvasSvgAssetObject, CanvasUserAssetObject } from '@editor/utils/canvas.types';
import {
  CanvasSkeletonBone,
  CanvasSkeleton,
  CanvasObjectTypes,
} from './canvas.types';
import { v4 as uuid } from 'uuid';
import slugify from 'slugify';
import firebase from 'firebase';
import humanPresets from '@editor/features/inspector/object-interface/human.presets';
import * as EditorConstants from '@editor/utils/editor.constants';
import { applyPresetToObject } from '@editor/features/inspector/object-interface/HumanPresets';
import { Asset } from '@editor/features/canvas/object-types/Asset/asset.types';
import { UserAsset } from '@editor/features/canvas/object-types/Asset/user-asset.types';

export function createCanvasObject(
  projectName: string,
  ownerId: string,
  canvasId: string = uuid()
): Canvas {
  const initialObject = createHumanObject();
  const slug = slugify(projectName, { strict: true, lower: true });

  return {
    canvasId,
    ownerId,
    slug,

    layers: [initialObject.id],
    objects: {
      [initialObject.id]: initialObject,
    },
    properties: {
      width: EditorConstants.DEFAULT_CANVAS_WIDTH,
      height: EditorConstants.DEFAULT_CANVAS_HEIGHT,
    },
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    schemaVersion: 1,
  };
}

export function createBone(
  name: string,
  parentId: string,
  defaultProperties?: any,
  id: string = shortid()
): CanvasSkeletonBone {
  const properties = defaultProperties || {
    angle: 0,
  };

  return {
    id,
    name,
    parentId,
    properties,
  };
}

export function createSvgAssetObject(
  asset: Asset,
  id: string = shortid()
): CanvasSvgAssetObject {
  const { id: assetId, svg, name: objectName } = asset;
  // Determine SVG width and height
  const viewBoxWidth = svg.width();
  const viewBoxHeight = svg.height();

  return {
    id,
    name: objectName,
    assetId,
    assetType: 'SVG',
    objectType: CanvasObjectTypes.ASSET,
    properties: {
      locked: false,
      rotateAngle: 0,
      opacity: 1,
      viewBoxWidth,
      viewBoxHeight,
      flipHorizontally: false,
      position: { x: 0, y: 0 },
    },
  };
}

export function createUserAssetObject(
  asset: UserAsset,
  id: string = shortid()
): CanvasUserAssetObject {
  const { id: assetId, fileUrl, name: objectName, metadata } = asset;

  return {
    id,
    name: objectName,
    assetId,
    assetType: 'UserAsset',
    objectType: CanvasObjectTypes.USER_ASSET,
    fileUrl,
    metadata,
    properties: {
      locked: false,
      rotateAngle: 0,
      opacity: 0.75,
      viewBoxWidth: metadata.width,
      viewBoxHeight: metadata.height,
      flipHorizontally: false,
      position: { x: 0, y: 0 },
    },
  };
}

export function createHumanObject(
  id: string = shortid(),
  defaultBones?: CanvasSkeletonBone[]
): CanvasSkeleton {
  let human: CanvasSkeleton = {
    id,
    name: 'Human',
    objectType: CanvasObjectTypes.HUMAN,
    bones: defaultBones ? loadHumanBones(defaultBones) : createHumanBones(id),
    properties: {
      locked: false,
      scale: 1,
      opacity: 1,
      viewBoxWidth: EditorConstants.HUMAN_SVG_SIZE,
      viewBoxHeight: EditorConstants.HUMAN_SVG_SIZE,
      faceAngle: 50,
      bodyAngle: 0,
      flipHorizontally: false,
      position: null,
      leftHand: {
        type: 'Hand1',
        flipVertically: false,
        flipHorizontally: true,
      },
      rightHand: {
        type: 'Hand1',
        flipVertically: false,
        flipHorizontally: false,
      },
      rightFoot: {
        flipHorizontally: false,
      },
      leftFoot: {
        flipHorizontally: false,
      },
    },
  };

  // Apply default preset
  const { obj, parkedValues } = applyPresetToObject(human, humanPresets[0]);
  obj.properties.bodyAngle = parkedValues.bodyAngle;

  return obj;
}

function createHumanBones(parentObjectId: string) {
  return {
    Head: createBone('Head', parentObjectId),
    UpperBody: createBone('UpperBody', parentObjectId),
    ArmSpan: createBone('ArmSpan', parentObjectId),
    RightUpperArm: createBone('RightUpperArm', parentObjectId),
    RightLowerArm: createBone('RightLowerArm', parentObjectId),
    RightHand: createBone('RightHand', parentObjectId),
    LeftUpperArm: createBone('LeftUpperArm', parentObjectId),
    LeftLowerArm: createBone('LeftLowerArm', parentObjectId),
    LeftHand: createBone('LeftHand', parentObjectId),
    LowerBody: createBone('LowerBody', parentObjectId),
    RightUpperLeg: createBone('RightLeg', parentObjectId),
    RightLowerLeg: createBone('RightLowerLeg', parentObjectId),
    RightFoot: createBone('RightFoot', parentObjectId),
    LeftUpperLeg: createBone('LeftLeg', parentObjectId),
    LeftLowerLeg: createBone('LeftLowerLeg', parentObjectId),
    LeftFoot: createBone('LeftFoot', parentObjectId),
  };
}

function loadHumanBones(bones: CanvasSkeletonBone[]) {
  const bonesObj: { [k: string]: CanvasSkeletonBone } = {};

  bones.forEach((bone) => {
    bonesObj[bone.name] = bone;
  });

  return bonesObj;
}
