import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import firebase from 'firebase/app';
import { useAuthContext } from '@common/context';
import { Organization } from '@common/types';
import { PlanTypes } from '@posturize/shared/payments/plan.types';
import {
  composeUserPermissions,
  PermissionsDict,
} from '@posturize/shared/permissions';
import { useSubscriptions } from '../../../+subscriptions/hooks/useSubscription';
import { useEffect, useState } from 'react';
import { Subscription } from '@common/types/Subscription';
import isEqual from 'lodash/isEqual';

export function useUserSubscription() {
  const [subscription, setSubscription] = useState(null);
  const [error, setError] = useState(false);
  const { userData, currentUser, loading: loadingUser } = useAuthContext();
  const { getSubscriptionById } = useSubscriptions();
  const userId = currentUser?.uid;
  // We always start with loading, unless the user is not authenticated
  const [loading, setLoading] = useState(true);
  const [organization] = useDocumentData<Organization>(userData?.organization, {
    idField: 'id',
  });

  const subscriptionQuery = userId ? firebase
      .firestore()
      .collection('subscriptions')
      .where('userId', '==', userId)
      .where('active', '==', true)
      .limit(1) : null;

  const [subscriptions, loadingSubscriptionsQuery] = useCollectionData<Subscription>(subscriptionQuery, {
    idField: 'id',
  });

  useEffect(() => {
    // User is not authenticated, let pass
    if (!loadingUser && !userId) {
      setLoading(false);
      return;
    }
    // Still loading
    if (!subscriptions || loadingSubscriptionsQuery) return;
    // Finished loading, user is not subscribed
    if (subscriptions && !subscriptions.length) {
      // user had subscription, now it's gone
      if (subscription) setSubscription(null);
      setLoading(false);
      return;
    }
    // Finished loading, user is subscribed
    const { provider, id, updatedAt } = subscriptions[0];
    // Subscription is not updated, do not fetch
    if (id === subscription?.id && updatedAt === subscription?.updatedAt) return;
    getSubscriptionById(provider, id).then((res) => {
      const newSubscription = {
        ...subscriptions[0],
        ...res,
      };
      setError(false);
      setLoading(false);
      if (!subscription || !isEqual(subscription, newSubscription)) {
        setSubscription(newSubscription);
      }
    }).catch(() => {
      setSubscription({
        ...subscriptions[0],
      });
      setError(true);
      setLoading(false);
    });
  }, [subscriptions, getSubscriptionById, loadingUser, userId, loadingSubscriptionsQuery, subscription]);

  const planType = organization
    ? PlanTypes.BUSINESS
    : subscription
    ? PlanTypes.PREMIUM
    : PlanTypes.FREE;

  const permissions: PermissionsDict = composeUserPermissions(planType);

  return {
    subscription,
    planType,
    permissions,
    loading,
    error,
  };
}
