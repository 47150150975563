import React, { useCallback } from 'react';
import styled from 'styled-components';
import humanPresets from './human.presets';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch } from 'react-redux';
import { replaceObject } from '@editor/reducers/canvas.slice';
import * as InspectorUI from '../InspectorUI';
import {
  AnalyticsEvents,
  useProjectAnalytics,
} from '@common/analytics/analytics.util';

const PRESET_SIZE = 120;

const Preset = styled.img`
  box-sizing: border-box;
  display: inline-block;
  width: ${PRESET_SIZE}px;
  height: ${PRESET_SIZE}px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: white;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

const StyledInspectorUIContent = styled(InspectorUI.Section)`
  /* max-height: ${PRESET_SIZE * 3 + PRESET_SIZE / 2}px; */
  max-width: ${PRESET_SIZE * 2}px; // 20 for scrollbar

  overflow-y: scroll; /* Add the ability to scroll y axis*/

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */


  ::-webkit-scrollbar {
    display: none;
  }
`;

export const applyPresetToObject = (object, preset) => {
  const clone = cloneDeep(object);
  const presetClone = cloneDeep(preset);
  const { properties, bones } = presetClone.values;

  const bodyAngle = properties.bodyAngle;

  /**
   * Background:
   * For some reason (probably logic in HumanV2.tsx and HumanSkeleton.ts),
   * If we apply the bodyAngle properties at the same time with others, things fail.
   * As a temporary fix, we will use a timeout to apply the body angle.
   */
  // delete properties.bodyAngle;
  delete properties.flipHorizontally;

  merge(clone, {
    properties,
    bones,
  });

  return {
    obj: clone,
    parkedValues: {
      bodyAngle,
    },
  };
};

export const HumanPresets = ({ object }) => {
  const { locked } = object.properties;
  const dispatch = useDispatch();
  const { logEvent } = useProjectAnalytics();

  const applyPreset = useCallback(
    (preset, presetName) => {
      const { obj } = applyPresetToObject(object, preset);
      logEvent(AnalyticsEvents.EDITOR_APPLY_HUMAN_PRESET, { presetName });
      dispatch(replaceObject(object.id, obj));
    },
    [dispatch, logEvent, object]
  );

  const renderPreset = useCallback(
    (preset) => {
      return (
        <Preset
          key={preset.name}
          src={preset.thumbnail}
          onClick={() => applyPreset(preset, preset.name)}
        />
      );
    },
    [applyPreset]
  );

  return (
    <StyledInspectorUIContent title="Presets">
      {locked ? (
        <p className="text-center p-2">The object is locked.</p>
      ) : (
        humanPresets.map((preset) => renderPreset(preset))
      )}
    </StyledInspectorUIContent>
  );
};
