import React, { FC } from 'react';
import { VscLoading as LoadingIcon } from 'react-icons/vsc';
import classNames from 'classnames';

export interface LoaderProps {
  size?: 'default' | 'medium' | 'large';
  color?: string;
  className?: string;
}

export const loaderIconSizes = {
  default: '1.5em',
  medium: '2.5em',
  large: '5em',
};

export const Loader: FC<LoaderProps> = ({
  size = 'default',
  color = 'primary',
  className,
}) => {
  return (
    <div
      aria-label="Loading"
      className={classNames(className, `text-${color}`)}
    >
      <LoadingIcon size={loaderIconSizes[size]} className="animate-spin" />
    </div>
  );
};
