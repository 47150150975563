import { PlanTypes } from '../payments/plan.types';

export enum Permission {
  CANVAS_OBJECTS_LIMIT = 'CANVAS_OBJECTS_LIMIT',
  CANVAS_CAN_USE_ASSETS = 'CANVAS_CAN_USE_ASSETS',
  CANVAS_CAN_EXPORT_PNG = 'CANVAS_CAN_EXPORT_PNG',
  PROJECTS_LIMIT = 'PROJECTS_LIMIT',
  CUSTOM_ASSETS_LIMIT = 'CUSTOM_ASSETS_LIMIT',
  CAN_USE_APP = 'CAN_USE_APP',
}

export interface PermissionDefinition {
  name: Permission;
  value: any;
  message?: string;
  upgradable?: boolean;
}

export type PermissionsDict = Record<Permission, PermissionDefinition>;

const basePermissions: PermissionsDict = {
  [Permission.CAN_USE_APP]: {
    name: Permission.CAN_USE_APP,
    message:
      'You have not verified your email address yet. Please check your email inbox.',
    value: true,
    upgradable: false,
  },
  [Permission.CANVAS_OBJECTS_LIMIT]: {
    name: Permission.CANVAS_OBJECTS_LIMIT,
    value: 10,
    message:
      'For the BETA version of Posturize, you are limited to 10 canvas objects.',
    upgradable: false,
  },
  [Permission.CUSTOM_ASSETS_LIMIT]: {
    name: Permission.CUSTOM_ASSETS_LIMIT,
    value: 10,
    message:
      'For the BETA version of Posturize, you are limited to 10 canvas objects.',
    upgradable: false,
  },
  [Permission.CANVAS_CAN_USE_ASSETS]: {
    name: Permission.CANVAS_CAN_USE_ASSETS,
    value: true,
  },
  [Permission.CANVAS_CAN_EXPORT_PNG]: {
    name: Permission.CANVAS_CAN_EXPORT_PNG,
    value: true,
  },
  [Permission.PROJECTS_LIMIT]: {
    name: Permission.PROJECTS_LIMIT,
    value: 3,
    message:
      'For the BETA version of Posturize, you are limited to 3 projects.',
    upgradable: false,
  },
};

export const composeUserPermissions = (plan: PlanTypes): PermissionsDict => {
  let clone: PermissionsDict = { ...basePermissions };

  // Override limits for free users
  // if (plan === PlanTypes.FREE) {
  //   clone[Permission.CANVAS_OBJECTS_LIMIT] = {
  //     ...clone[Permission.CANVAS_OBJECTS_LIMIT],
  //     value: 1,
  //     message:
  //       'As a free member, you are only allowed to have one object per canvas.',
  //     upgradable: true,
  //   };
  //   clone[Permission.CANVAS_CAN_USE_ASSETS] = {
  //     ...clone[Permission.CANVAS_CAN_USE_ASSETS],
  //     value: false,
  //     message: 'Asset objects are only available to premium members.',
  //     upgradable: true,
  //   };
  //   clone[Permission.CANVAS_CAN_EXPORT_PNG] = {
  //     ...clone[Permission.CANVAS_CAN_EXPORT_PNG],
  //     value: false,
  //     message: 'As a free member, you can only export your canvas as JPEG.',
  //     upgradable: true,
  //   };
  //   clone[Permission.PROJECTS_LIMIT] = {
  //     ...clone[Permission.PROJECTS_LIMIT],
  //     message: 'As a free member, you can only have one project.',
  //     value: 1,
  //     upgradable: true,
  //   };
  // }

  return clone;
};
