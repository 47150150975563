import firebase from 'firebase';

export type CanvasObject =
  | CanvasSkeleton
  | CanvasAssetObject
  | CanvasSvgAssetObject
  | CanvasUserAssetObject;

export type CanvasSkeletonDict = { [key: string]: CanvasObject };

export enum CanvasObjectTypes {
  HUMAN = 'HUMAN',
  ASSET = 'ASSET',
  USER_ASSET = 'USER_ASSET'
}

export interface Canvas {
  canvasId: string;
  ownerId: string;
  slug: string;
  objects: CanvasSkeletonDict;
  properties: CanvasProperties;
  layers: string[];
  schemaVersion: number;
  disabled?: boolean;
  disableReason?: string;
  updatedAt?: firebase.firestore.FieldValue;
}

export interface Position {
  x: number;
  y: number;
}

export interface CanvasSkeletonHandProperties {
  type: string;
  flipVertically: boolean;
  flipHorizontally: boolean;
}

export interface CanvasSkeletonFootProperties {
  flipHorizontally: boolean;
}

export interface BaseCanvasObjectProperties {
  locked: boolean;
  viewBoxWidth: number;
  viewBoxHeight: number;
  flipHorizontally: boolean;
  position: Position | null;
  opacity: number;
}

export interface CanvasSkeletonProperties extends BaseCanvasObjectProperties {
  faceAngle: number;
  bodyAngle: number;
  scale: number;
  leftHand: CanvasSkeletonHandProperties;
  rightHand: CanvasSkeletonHandProperties;
  leftFoot: CanvasSkeletonFootProperties;
  rightFoot: CanvasSkeletonFootProperties;
}

export interface CanvasSkeletonBone {
  id: string;
  name: string;
  parentId: string;
  properties: {
    angle: number;
  };
}

export interface CanvasAssetProperties extends BaseCanvasObjectProperties {
  rotateAngle: number;
}

export interface CanvasAssetObject {
  id: string;
  name: string;
  objectType: CanvasObjectTypes.ASSET;
  bucketObjectUrl: string;
  assetType: 'IMAGE';
  properties: CanvasAssetProperties;
}

export interface CanvasSvgAssetObject {
  id: string;
  name: string;
  assetId: string;
  objectType: CanvasObjectTypes.ASSET;
  assetType: 'SVG';
  properties: CanvasAssetProperties;
}

export interface CanvasUserAssetObject {
  id: string;
  name: string;
  assetId: string;
  objectType: CanvasObjectTypes.USER_ASSET;
  assetType: 'UserAsset';
  metadata: {
    mimetype: string,
    width: number,
    height: number
  }
  fileUrl: string;
  properties: CanvasAssetProperties;
}

export interface CanvasSkeleton {
  id: string;
  name: string;
  objectType: CanvasObjectTypes.HUMAN;
  bones: {
    [k: string]: CanvasSkeletonBone;
  };
  properties: CanvasSkeletonProperties;
}

export interface CanvasProperties {
  width: number;
  height: number;
}
