import React from 'react';
import { KnowledgeBaseTopic, DemoGIF } from '../knowledge-base.common';

export const topic: KnowledgeBaseTopic = {
  id: 'undo_redo',
  label: 'Undo/Redo',
  premium: false,
  Content: () => (
    <div>
      <div>
        <h2 className="text-lg font-medium mb-2">Using keyboard shortcuts</h2>
        <div>
          <p>
            Undo: Ctrl + Z (Windows) <i className="italic">or</i> ⌘ + Z (macOS).
          </p>
          <p>
            Redo: Ctrl + Shift + Z (Windows) <i className="italic">or</i> ⌘ +
            Shift + Z (macOS).
          </p>
        </div>
      </div>

      <div className="mt-4">
        <h2 className="text-lg font-medium mb-2">Using the layout</h2>
        <p>
          You can use the buttons located at the bottom-left of the editor to
          perform redo/undo operations.
        </p>
        <div className="mt-6">
          <DemoGIF width={160} src="https://static-assets-cdn.aws.posturize.app/knowledge-base/UndoRedoButtons.png" />
        </div>
      </div>
    </div>
  ),
};
