import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  controlledObject: null,
  focusedObject: null,
  isCapturingCanvas: false,
  displayGrid: false,
  inspectorOpen: true,
  isKnowledgeBaseOpen: false,
};

export const controlsSlice = createSlice({
  name: 'controls',
  initialState,
  reducers: {
    setControlledObject: (state, action) => {
      state.focusedObject = null;
      state.controlledObject = action.payload;
    },
    setFocusedObject: (state, action) => {
      state.focusedObject = action.payload;
    },
    setIsCapturingCanvas: (state, action) => {
      state.isCapturingCanvas = action.payload;
    },
    resetControls: (state) => {
      state.focusedObject = null;
      state.controlledObject = null;
    },
    setDisplayGrid: (state, action) => {
      state.displayGrid = action.payload;
    },
    toggleInspector: (state) => {
      state.inspectorOpen = !state.inspectorOpen;
    },
    setIsKnowledgeBaseOpen: (state, action) => {
      state.isKnowledgeBaseOpen = action.payload;
    }
  },
});

export const {
  setControlledObject,
  setIsCapturingCanvas,
  resetControls,
  setFocusedObject,
  toggleInspector,
  setDisplayGrid,
  setIsKnowledgeBaseOpen
} = controlsSlice.actions;

export const selectControlledObject = (state) =>
  state.controls.controlledObject;
export const selectIsCapturingCanvas = (state) =>
  state.controls.isCapturingCanvas;
export const selectFocusedObject = (state) => state.controls.focusedObject;
export const selectDisplayGrid = (state) => state.controls.displayGrid;
export const selectInspectorOpen = (state) => state.controls.inspectorOpen;
export const selectIsKnowledgeBaseOpen = (state) => state.controls.isKnowledgeBaseOpen;

export default controlsSlice.reducer;
