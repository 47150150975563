import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

interface Props {
  tag?: string;
  className?: string;
}

export const Card = ({
  children,
  className,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={cn(
        'focus:outline-none rounded-lg shadow-md bg-white',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export const CardTitle = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <h1
    className={cn(
      'text-4xl text-primary-dark font-medium text-center',
      className
    )}
  >
    {children}
  </h1>
);
