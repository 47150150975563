import React from 'react';

import { Invitation } from '@common/types';

export interface InvitationState {
  invitation?: Invitation;
  setInvitation: React.Dispatch<React.SetStateAction<Invitation>>;
}

const initialState: InvitationState = {
  setInvitation: () => {},
};

export const InvitationContext = React.createContext<InvitationState>(
  initialState
);
