import React, { useRef } from 'react';
import styled from 'styled-components';
import { TransformWrapper, TransformComponent } from '@posturize/react-zoom-pan-pinch';
import { TOPBAR_HEIGHT } from '@editor/utils/editor.constants';

import { usePanZoom } from '../usePanZoom';
import { useWindowSize } from '@editor/hooks/useWindowSize';

const PanZoomContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const PannableFullScreen = styled.div<any>`
  width: 100vw;
  height: ${props => props.height - TOPBAR_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PanZoom = ({ height, children }) => {
  useWindowSize();
  const { transformProps } = usePanZoom();
  const transformRef = useRef();

  return (
    <PanZoomContainer>
      <TransformWrapper {...transformProps}>
        {(controls) => {
          (window as any).panZoomControls = controls;
          return (
            <>
              <TransformComponent ref={transformRef}>
                <PannableFullScreen height={height}>
                  {children}
                </PannableFullScreen>
              </TransformComponent>
            </>
          );
        }}
      </TransformWrapper>
    </PanZoomContainer>
  );
};
