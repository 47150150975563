import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineFileImage as ImageErrorIcon } from 'react-icons/ai';
import { VscLoading as LoadingIcon } from 'react-icons/vsc';
import {
  Button,
  EditableInput,
  Dropdown,
  IconButton,
} from '@common/components';
import {
  MdModeEdit as PencilIcon,
  MdMoreVert as MoreIcon,
  MdDelete as DeleteIcon,
} from 'react-icons/md';
import firebase from 'firebase/app';
import { useDownloadURL } from 'react-firebase-hooks/storage';

import { CANVAS_STORAGE_URL } from '../../config/firebaseStorage';
import { ConfirmationModal } from '@common/modals/ConfirmationModal';

export const ProjectCard = ({
  name,
  canvasId,
  onClick,
  onNameChange,
  onDelete,
}) => {
  const {
    register,
    getValues,
    setError,
    clearErrors,
    reset,
    errors,
  } = useForm<{
    projectName: string;
  }>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [thumbnailSrc, thumbnailLoading, thumbnailError] = useDownloadURL(
    firebase
      .app()
      .storage(CANVAS_STORAGE_URL)
      .ref()
      .child(`${canvasId}_thumbnail.png`)
  );

  const [
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal,
  ] = useState(false);

  const handleChangeNameConfirm = () => {
    const { projectName } = getValues();
    onNameChange(projectName);
  };

  const handleDropdownItemSelection = (value: string) => {
    setShowDropdown(false);
    if (value === 'delete') {
      setShowDeleteConfirmationModal(true);
    }
  };

  const projectPreviewThumbnail = thumbnailLoading ? (
    <LoadingIcon
      className="text-primary animate-spin m-auto tex-center"
      size="1.5em"
    />
  ) : (
    <img src={thumbnailSrc} alt="preview-thumbnail" />
  );

  const projectPreviewError = (
    <ImageErrorIcon className="text-gray-500 m-auto self-center" size="4em" />
  );

  return (
    <div className="m-auto">
      <div className="flex justify-center items-center relative max-w-sm rounded overflow-hidden shadow-md bg-white h-64 w-64 mx-2 first:m-0">
        <div className="absolute top-0 right-0">
          <Dropdown
            onDismiss={() => setShowDropdown(false)}
            isOpen={showDropdown}
            options={[
              {
                value: 'delete',
                viewValue: 'Delete project',
                icon: DeleteIcon,
              },
            ]}
            onSelect={handleDropdownItemSelection}
          >
            <IconButton onClick={() => setShowDropdown(true)}>
              <MoreIcon color="#b8bbbf" size="2em" style={{ marginTop: 5 }} />
            </IconButton>
          </Dropdown>
        </div>

        <ConfirmationModal
          title="Are you sure?"
          bodyHTML={
            <>
              Are you sure you want to delete{' '}
              <span className="font-bold">{name}</span>?
            </>
          }
          isOpen={showDeleteConfirmationModal}
          onDismiss={() => setShowDeleteConfirmationModal(false)}
          onConfirm={onDelete}
          confirmColor="danger"
        />

        <Button
          onClick={onClick}
          color="transperent"
          className="self-center w-full text-black"
        >
          {thumbnailError ? projectPreviewError : projectPreviewThumbnail}
        </Button>
      </div>

      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mx-2 mb-3 flex items-center">
          <EditableInput
            defaultValue={name}
            onChange={(e) =>
              e.target.value.length >= 20 || !e.target.value
                ? setError('projectName', {
                    message: 'Project name can contain up to 20 characters.',
                  })
                : clearErrors()
            }
            onConfirm={handleChangeNameConfirm}
            onCancel={reset}
            error={!!errors.projectName}
            errorMessage={errors.projectName?.message}
            ref={register}
            name="projectName"
          >
            <div className="group cursor-pointer">
              <p className="mt-4 mx-2 border-secondary group-hover:border-gray-300 border-b-2 border-dashed inline-block">
                {name}
              </p>
              <IconButton className="opacity-0 group-hover:opacity-100">
                <PencilIcon />
              </IconButton>
            </div>
          </EditableInput>
        </div>
      </form>
    </div>
  );
};
