import React, { FC, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { Link, useHistory } from 'react-router-dom';
import { Loader } from '@common/components';

import { useAuthContext, useToastContext } from '@common/context';

export const VerifyEmailContainer: FC<{ verifyCode: string }> = ({
  verifyCode,
}) => {
  const history = useHistory();
  const { currentUser } = useAuthContext();
  const { addToast } = useToastContext();
  const [verificationInvalid, setVerificationInvalid] = useState(false);

  useEffect(() => {
    firebase
      .auth()
      .applyActionCode(verifyCode)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Success',
          message: 'Your email is now verified!',
        });
        history.push('/');
      })
      .catch(() => setVerificationInvalid(true));
  }, [verifyCode, history, currentUser, addToast]);

  return (
    <div className="h-full">
      <div className="h-full flex max-w-md m-auto items-center justify-center">
        {verificationInvalid ? (
          <div className="w-full px-8 py-8 bg-white shadow-xl rounded-2xl">
            <>
              <h1 className="font-light text-3xl py-4">
                This email verification link has expired or it is invalid.
              </h1>

              <div className="flex justify-between">
                <Link
                  to="/signup"
                  className="text-primary hover:text-primary-dark block text-center mt-4"
                >
                  Sign up
                </Link>
                <Link
                  to="/forgot-password"
                  className="text-primary hover:text-primary-dark block text-center mt-4"
                >
                  Forgot password
                </Link>
              </div>
            </>
          </div>
        ) : (
          <Loader size="large" />
        )}
      </div>
    </div>
  );
};
