import React from 'react';
import { KnowledgeBaseTopic, DemoGIF } from '../knowledge-base.common';

export const topic: KnowledgeBaseTopic = {
  id: 'adding_objects',
  label: 'Adding Objects',
  premium: false,
  Content: () => (
    <div>
      You can add objects to your canvas via the "Add Object" menu at the top bar.
      <div className="mt-6">
        <DemoGIF src="https://static-assets-cdn.aws.posturize.app/knowledge-base/AddingObjects.png" width={240} />
      </div>
    </div>
  ),
};
