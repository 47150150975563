import { useDispatch, useSelector } from 'react-redux';
import { deleteObject, renameObject, selectObject, setBulkObjectProperties, setObjectProperty } from '@editor/reducers/canvas.slice';
import { CanvasObjectTypes } from '@editor/utils/canvas.types';

export const useObject = (objectId: string) => {
  const dispatch = useDispatch();
  const object = useSelector(selectObject(objectId));

  const checkObjectType = (types: CanvasObjectTypes[]) => {
    return types.some(type => type === object.objectType);
  };

  return {
    name: object?.name,
    objectType: object?.objectType,
    objectProperties: object?.properties,
    objectBones: object?.bones,
    renameObject: (name: string) => dispatch(renameObject(objectId, name)),
    setObjectProperty: (property: string, value: any) => dispatch(setObjectProperty(objectId, property, value)),
    setBulkObjectProperties: properties => dispatch(setBulkObjectProperties(objectId, properties)),
    deleteObjectFromCanvas: () => dispatch(deleteObject(objectId)),
    checkObjectType,
  };
};
