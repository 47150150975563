import { Svg } from '@svgdotjs/svg.js';

export enum AssetCategories {
  ALL = 'All',
  FURNITURE = 'Furniture',
  ANIMALS = 'Animals',
  ELECTRONICS = 'Electronics',
  PLANTS = 'Plants',
  ACCESSORIES = 'Accessories',
}

export interface AssetDefinition {
  svg: string;
  displayName: string;
  categories: AssetCategories[];
}

export interface AssetCategory {
  id: string;
  name: AssetCategories;
}

export interface AssetLink {
  id: string;
  url: string;
  name: string;
  category: AssetCategories;
}

export interface Asset {
  id: string;
  name: string;
  svg: Svg;
  category: AssetCategories;
}
