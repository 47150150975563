import React from 'react';
import styled from 'styled-components';
import * as EditorConstants from '@editor/utils/editor.constants';
import { useCanvasV2 } from '@editor/hooks/useCanvasV2';
import useControls from '@editor/hooks/useControls';
import { ObjectInspectorInterface } from './object-interface/ObjectInspectorInterface';
import { LayersInspectorInterface } from './layers-interface/LayersInspectorInterface';

import {
  FaChevronRight as OpenIcon,
  FaChevronLeft as CloseIcon,
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectInspectorOpen,
  toggleInspector,
} from '@editor/reducers/controls.slice';
import { CanvasInspectorInterface } from './object-interface/CanvasInspectorInterface';

const InspectorContainer = styled.div<any>`
  position: absolute;
  display: ${(props) => (props.open ? 'flex' : 'none')};
  box-sizing: content-box;
  min-width: ${(props) => (props.open ? EditorConstants.INSPECTOR_WIDTH : 0)}px;
  width: ${(props) => (props.open ? EditorConstants.INSPECTOR_WIDTH : 0)}px;
  max-height: 100%;
  height: 100%;
  top: 0;
  right: 0;
  justify-content: flex-start;
  flex-direction: column;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 101;

  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Toggle = styled.button<any>`
  position: absolute;
  width: 30px;
  height: 80px;
  z-index: 9999;
  top: calc(50% - 40px);
  right: ${(props) =>
    `calc(${props.inspectorOpen ? EditorConstants.INSPECTOR_WIDTH : 0}px)`};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
`;

export const Inspector = () => {
  const { getObjectById } = useCanvasV2();
  const { controlledObject } = useControls();
  const dispatch = useDispatch();
  const inspectorOpen = useSelector(selectInspectorOpen);

  const renderControlledObjectInterface = () => {
    const object = getObjectById(controlledObject);

    if (object) {
      return <ObjectInspectorInterface object={object} />;
    }
  };

  return (
    <div className="disable-gestures">
      <Toggle
        inspectorOpen={inspectorOpen}
        onClick={() => dispatch(toggleInspector())}
      >
        {inspectorOpen ? (
          <OpenIcon color="#7a7a7a" />
        ) : (
          <CloseIcon color="#7a7a7a" />
        )}
      </Toggle>
      <InspectorContainer open={inspectorOpen} className="bg-gray-50">
        <LayersInspectorInterface />
        <CanvasInspectorInterface />
        {controlledObject && renderControlledObjectInterface()}
      </InspectorContainer>
    </div>
  );
};
