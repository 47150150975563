import React, { forwardRef, FC } from 'react';
import shortid from 'shortid';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  ref?: any;
  error?: boolean;
  errorMessage?: string;
  endAdornment?: React.ReactElement;
}

export const Input: FC<InputProps> = forwardRef(
  (
    {
      label,
      error,
      errorMessage,
      className,
      autoComplete,
      endAdornment,
      ...inputProps
    },
    inputRef
  ) => (
    <div className={className ?? ''}>
      {label && (
        <label
          htmlFor={`input-${shortid()}`}
          className={'block text-gray-700 py-2 font-semibold text-lg'}
        >
          {label}
        </label>
      )}
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          type="text"
          className={`border-primary.darker focus:border-primary border-primary.darker block w-full pr-10 py-2 px-2 rounded-sm ${
            error ? `border-warning placeholder-red-300` : ''
          }`}
          aria-invalid={!!error}
          aria-describedby="error"
          {...inputProps}
          autoComplete={autoComplete}
          ref={inputRef as any}
        />
        {!!endAdornment && (
          <div className="absolute flex items-center h-full top-0 right-3">
            {endAdornment}
          </div>
        )}

        {error && (
          <>
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <svg
                className="h-5 w-5 text-red-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </>
        )}
      </div>
      <p className="mt-2 text-sm font-semibold text-warning" id="input-error">
        {errorMessage}
      </p>
    </div>
  )
);
