import { topic as AddingObjects } from './AddingObjects';
import { topic as AssetLibrary } from './AssetLibrary';
import { topic as AssetManipulation } from './AssetManipulation';
import { topic as HumanHands } from './HumanHands';
import { topic as HumanJoints } from './HumanJoints';
import { topic as HumanPresets } from './HumanPresets';
import { topic as HumanProperties } from './HumanProperties';
import { topic as HumanFace } from './HumanFace';
import { topic as Layers } from './Layers';
import { topic as Exporting } from './Exporting';
import { topic as UndoRedo } from './UndoRedo';
import { KnowledgeBaseTopic } from '../knowledge-base.common';

const topics: KnowledgeBaseTopic[] = [
  AddingObjects,
  AssetLibrary,
  AssetManipulation,
  HumanHands,
  HumanJoints,
  HumanPresets,
  HumanProperties,
  HumanFace,
  Layers,
  Exporting,
  UndoRedo,
];

export default topics;