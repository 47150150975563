import { createContext } from 'react';
import { PlanTypes } from '@posturize/shared/payments/plan.types';
import { Subscription } from '@common/types/Subscription';
import { PermissionsDict } from '@posturize/shared/permissions';

interface SubscriptionState {
  subscription?: Subscription;
  planType?: PlanTypes;
  permissions: PermissionsDict;
  loading?: boolean;
  error?: boolean;
}

export const SubscriptionContext = createContext<SubscriptionState | null>(null);
