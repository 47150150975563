import React, { useState } from 'react';
import { Button, Modal } from '@common/components';
import { AiOutlineQuestionCircle as KnowledgeBaseIcon } from 'react-icons/ai';
import { Checkbox, FormControlLabel } from '@material-ui/core';

export const OfferKnowledgeBaseModal = ({ isOpen, onConfirm, onDismiss }) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      title="Welcome to Posturize!"
      onDismiss={onDismiss}
      contentPadding={false}
      withCloseBtn
    >
      <div className="p-4">
        <p>
          We recommend that you take a look at our Knowledge Base to quickly
          become familiar with our features.
        </p>
        <p className="mt-4">
          You can always access the knowledge base by pressing the{' '}
          <KnowledgeBaseIcon size={24} style={{ display: 'inline' }} /> icon at
          the top.
        </p>
      </div>

      <div className="p-4 text-center">
        <div>
          <Button color="primary" style={{ padding: 12 }} onClick={() => onConfirm(dontShowAgain)}>
            Take me there!
          </Button>
        </div>
        <div>
        <Button className="mt-2" color="transparent" style={{ color: '#686868' }} onClick={() => onDismiss(dontShowAgain)}>
          No thanks
        </Button>
        </div>
        <div className="mt-1">
          <FormControlLabel
            control={
              <Checkbox
                checked={dontShowAgain}
                onChange={(_e, checked) => setDontShowAgain(checked)}
                color="primary"
              />
            }
            label="Don't show this again"
          />
        </div>
      </div>
    </Modal>
  );
};
