import React from 'react';

import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';

import {
  ImSvg as SvgIcon,
} from 'react-icons/im';
import {
  AiOutlineFileImage as PNGIcon,
  AiOutlineFileJpg as JPEGIcon,
} from 'react-icons/ai';

import {
  SiSvg as SVGIcon,
} from 'react-icons/si';

import useControls from '@editor/hooks/useControls';
import { useExport } from '../export/useExport';
import { ExportType } from '@editor/reducers/export.slice';
import { usePermission } from '@common/hooks/usePermission';
import { useSubscriptionContext } from '@common/context/subscription/useSubscriptionContext';

export const ExportMenu = (props: MenuProps) => {
  const { permissions } = useSubscriptionContext();
  const canvasCanExportPngPermission = usePermission(permissions.CANVAS_CAN_EXPORT_PNG);

  const { openExportWizard } = useExport();
  const { setIsCapturingCanvas } = useControls();

  const handleSaveCanvas = async (type: ExportType) => {
    props.onClose(null, null);

    if (type === ExportType.PNG && !canvasCanExportPngPermission.value) {
      return canvasCanExportPngPermission.displayModal();
    }

    setIsCapturingCanvas(true);
    openExportWizard(type);
  };

  return (
    <Menu {...props}>
      <MenuItem disabled onClick={() => {}} style={{ display: 'none' }}>
        <ListItemIcon>
          <SvgIcon size={18} />
        </ListItemIcon>
        <ListItemText>Export as SVG</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleSaveCanvas(ExportType.JPEG)}>
        <ListItemIcon>
          <JPEGIcon size={18} />
        </ListItemIcon>
        <ListItemText>Export as JPEG</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleSaveCanvas(ExportType.PNG)}>
        <ListItemIcon>
          <PNGIcon size={18} />
        </ListItemIcon>
        <ListItemText>Export as PNG (transparent background)</ListItemText>
      </MenuItem>
      <MenuItem disabled>
        <ListItemIcon>
          <SVGIcon size={18} />
        </ListItemIcon>
        <ListItemText>Export as SVG <Chip style={{ marginLeft: 12 }} label="COMING SOON" /></ListItemText>
      </MenuItem>
    </Menu>
  );
};