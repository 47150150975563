import { selectIsKnowledgeBaseOpen, setIsKnowledgeBaseOpen } from '@editor/reducers/controls.slice';
import { useDispatch, useSelector } from 'react-redux';

export const useKnowledgeBase = () => {
  const dispatch = useDispatch();
  const isKnowledgeBaseOpen = useSelector(selectIsKnowledgeBaseOpen);

  const getShouldOfferKnowledgeBase = () => {
    const value = window.localStorage.getItem('posturize.knowledgebase.offer');
    return !value || value === 'true';
  };

  const saveDontShowAgain = () => {
    window.localStorage.setItem('posturize.knowledgebase.offer', 'false');
  };

  return {
    setIsKnowledgeBaseOpen: (state: boolean) => dispatch(setIsKnowledgeBaseOpen(state)),
    isKnowledgeBaseOpen,
    shouldOfferKnowledgeBase: getShouldOfferKnowledgeBase(),
    saveDontShowAgain,
  }
};