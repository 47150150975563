import { Modal } from '@common/components';
import React from 'react';
import { Input, Button } from '@common/components';
import { useForm } from 'react-hook-form';

const OBJECT_NAME_MAXLENGTH = 14;

export const RenameLayerModal = ({ defaultName = '', isOpen, onDismiss, onSubmit }) => {
  const {
    register,
    handleSubmit,
    errors,
  } = useForm<{
    name: string;
  }>();

  const getErrorMessage = () => {
    if (!errors.name) {
      return null;
    }

    if (errors.name.type === 'maxLength' || errors.name.type === 'required') {
      return `Object name must contain up to ${OBJECT_NAME_MAXLENGTH} characters`;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Rename object"
      background="white"
      onDismiss={onDismiss}
    >
      <form onSubmit={handleSubmit(({ name }) => onSubmit(name))}>
        <Input
          type="text"
          name="name"
          defaultValue={defaultName}
          placeholder="Untitled Project"
          label="Choose a new name for this object"
          error={!!errors.name}
          autoComplete="off"
          errorMessage={getErrorMessage()}
          ref={register({ required: true, maxLength: OBJECT_NAME_MAXLENGTH })}
        />
        <Button
          type="submit"
          color="primary"
          className="text-white my-4 w-full"
        >
          Rename
        </Button>
      </form>
    </Modal>
  );
};
