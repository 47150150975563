import { selectLayers, setLayers } from '@editor/reducers/canvas.slice';
import { useSelector, useDispatch } from 'react-redux';
import { DropResult } from 'react-beautiful-dnd';

export const useLayers = () => {
  const layers = useSelector(selectLayers);
  const dispatch = useDispatch();

  const reorder = (result: DropResult) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    const { draggableId, source, destination } = result;
    const sourceIndex = source.index;
    const destinationIndex = destination.index;

    const newLayers = [...layers];
    newLayers.splice(sourceIndex, 1);
    newLayers.splice(destinationIndex, 0, draggableId);
    dispatch(setLayers(newLayers));
  };

  return {
    layers,
    reorder,
  };
};