import { CanvasObjectTypes } from '@editor/utils/canvas.types';
import React, { useState } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import {
  BsThreeDotsVertical as MenuIcon,
} from 'react-icons/bs';
import { IoCubeOutline as AssetIcon } from 'react-icons/io5';
import { IoBodyOutline as HumanIcon } from 'react-icons/io5'
import {
  AiOutlineDelete as DeleteIcon,
  AiOutlineEdit as RenameIcon,
  AiFillLock as LockIcon,
  AiFillUnlock as UnlockIcon,
} from 'react-icons/ai';
import { Dropdown } from '@common/components';
import { RenameLayerModal } from './RenameLayerModal';
import { useObject } from '@editor/hooks/useObject';


const renderIcon = (type: CanvasObjectTypes) => {
  switch (type) {
    case CanvasObjectTypes.HUMAN:
      return <HumanIcon color="#5861f1" size={18} />;
    case CanvasObjectTypes.ASSET:
      return <AssetIcon color="#5861f1" size={16} />;
    case CanvasObjectTypes.USER_ASSET:
      return <AssetIcon color="#5861f1" size={16} />;
  }
};

const LayerContainer = styled.div`
  z-index: 10;
  
  &:hover {
    background-color: #f3f3f3;
  }

  &.controlled {
    background: #e4e4e4;
  }
`;

export const Layer = ({ objectId, controlled, onSelected, onFocused, onUnfocused }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const {
    renameObject,
    name: objectName,
    objectType,
    deleteObjectFromCanvas,
    objectProperties,
    setObjectProperty,
  } = useObject(objectId);

  const { locked } = objectProperties;

  const dropdownOptions = [
    {
      value: 'rename',
      viewValue: 'Rename',
      icon: RenameIcon,
    },
    {
      value: 'delete',
      viewValue: 'Delete',
      icon: DeleteIcon,
    },
  ];

  const renderDropdown = () => (
    <Dropdown
      style={{ zIndex: 999 }}
      onDismiss={() => setDropdownVisible(false)}
      isOpen={dropdownVisible}
      options={dropdownOptions}
      onSelect={(value) => {
        switch (value) {
          case 'delete':
            deleteObjectFromCanvas();
            break;
          case 'rename':
            setRenameModalVisible(true);
            break;
        }
      }}
    />
  );

  const handleRename = (name: string) => {
    renameObject(name);
    setRenameModalVisible(false);
  };

  return (
    <LayerContainer
      className={cn({ controlled })}
      onMouseLeave={onUnfocused}
    >
      <RenameLayerModal
        isOpen={renameModalVisible}
        onDismiss={() => setRenameModalVisible(false)}
        onSubmit={handleRename}
        defaultName={objectName}
      />
      {renderDropdown()}
      <div className="grid grid-cols-10 p-1">
        <div className="col-span-2 flex justify-center items-center" onMouseDown={onSelected}>
          {renderIcon(objectType)}
        </div>
        <div className="col-span-6 flex justify-start items-center text-sm" onMouseDown={onSelected}>
          {objectName}
        </div>
        <div className="col-span-1 flex justify-center items-center"onClick={() => setObjectProperty('locked', !locked)}>
          {locked ? <LockIcon color="#acacac" /> : <UnlockIcon color="#acacac" />}
        </div>
        <div className="col-span-1 flex justify-center items-center" onClick={() => setDropdownVisible(true)}>
          <MenuIcon color="#acacac" />
        </div>
      </div>
    </LayerContainer>
  );
};
