import React from 'react';
import { KnowledgeBaseTopic, DemoGIF } from '../knowledge-base.common';

export const topic: KnowledgeBaseTopic = {
  id: 'human_presets',
  label: 'Human Presets',
  premium: false,
  Content: () => (
    <div>
      You can choose from a variety of presets and apply them to your Human objects via the Inspector.
      <div className="mt-6">
        <DemoGIF src="https://static-assets-cdn.aws.posturize.app/knowledge-base/HumanPresets.gif" />
      </div>
    </div>
  ),
};
