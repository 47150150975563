import { createMuiTheme } from '@material-ui/core';

export const DefaultMuiTheme = createMuiTheme({
  typography: {
   "fontFamily": `"Wotfard"`,
   "fontSize": 13,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500
  }
});