import React, { FC } from 'react';
import cn from 'classnames';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color: 'primary' | 'secondary' | 'transperent' | 'danger' | 'white' | string;
  variant?: 'outlined' | 'link';
  shape?: 'rounded' | 'block';
  size?: 'default' | 'large' | 'small';
  classNames?: string;
  disabled?: boolean;
}
const sizes = {
  default: '',
  large: 'py-2',
  small: 'px-2 text-sm',
};

const shapes = {
  rounded: 'rounded-full',
};
export const Button: FC<ButtonProps> = ({
  color,
  variant,
  shape = 'rounded',
  size = 'default',
  className,
  disabled,
  children,
  ...restProps
}) => {
  const classes = cn(
    'focus:outline-none',
    className,
    shapes[shape],
    sizes[size],
    {
      'p-2 font-medium': variant !== 'link',
      [`bg-${color}`]:
        color && !['outlined', 'link'].includes(variant) && !disabled,

      'bg-gray-200': disabled && variant !== 'outlined',
      [`border border-${color}`]: variant === 'outlined' && !disabled,
      [`text-${color}`]: ['outlined', 'link'].includes(variant) && !disabled,
      [`border border-gray-200`]: variant === 'outlined' && disabled,

      'text-black': ['transparent', 'white'].includes(color) && !disabled,
      'text-white': ['primary', 'secondary'].includes(color) && !disabled,
      'text-gray-400 cursor-not-allowed': disabled,
      'bg-none b-0 p-0': variant === 'link',
      'text-gray-200': variant === 'link' && disabled,
    }
  );

  return (
    <button className={classes} {...restProps} disabled={disabled}>
      {children}
    </button>
  );
};
