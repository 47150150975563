import React from 'react';
import firebase from 'firebase/app';

import { SignUpFormDetails, User, Invitation } from '@common/types';

export const noop = () => void 0;

interface AuthState {
  currentUser: firebase.User | undefined;
  userData: User | undefined;
  loading: boolean;
  error: firebase.auth.Error | undefined;
  signUpError: string | undefined;
  signUp: (
    signUpFormDetails: SignUpFormDetails,
    invitation: Invitation
  ) => Promise<firebase.auth.UserCredential | undefined>;
  signInWithGoogle: (invitation?: Invitation) => Promise<void>;
  signInWithEmailPassword: (
    email: string,
    password: string,
    invitation?: Invitation
  ) => Promise<firebase.auth.UserCredential | undefined>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  signOut: () => void;
}

const initialState: AuthState = {
  currentUser: undefined,
  userData: undefined,
  loading: false,
  error: undefined,
  signUpError: undefined,
  signUp: noop,
  signOut: noop,
  signInWithGoogle: noop,
  signInWithEmailPassword: noop,
  sendPasswordResetEmail: noop,
};

export const AuthContext = React.createContext<AuthState>(initialState);
