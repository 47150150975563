import React from 'react';
import { IconType } from 'react-icons/lib/cjs';

export type ToastType = 'warning' | 'success';

export type Toast = {
  id?: string;
  icon?: IconType;
  type?: ToastType;
  title: string;
  message?: string;
};

export interface ToastState {
  addToast: (toast: Toast) => void;
}

const initialState: ToastState = {
  addToast: () => {},
};

export const ToastContext = React.createContext<ToastState>(initialState);
