import { PlanTypes } from '@posturize/shared/payments/plan.types';

export enum BillingPeriods {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
}

export enum PaymentProviders {
  STRIPE = 'STRIPE',
  PAYPAL = 'PAYPAL',
}

export enum PaymentMethods {
  CARD = 'CARD',
  IDEAL = 'IDEAL',
  PAYPAL = 'PAYPAL',
}

export interface Coupon {
  code: string;
  id: string;
  amount_off: number;
  created: number;
  currency: string;
  duration: string;
  duration_in_months: number;
  name: string;
  percent_off: number;
  valid: boolean;
}

export interface ApprovePaypalSubscriptionRequest {
  billingToken: string;
  facilitatorAccessToken: string;
  orderId: string;
  paymentId?: string;
  subscriptionId: string;
}

export interface CreateOrganizationRequest {
  name: string;
  invitees: string[];
}

export interface CreateSubscriptionBaseRequest {
  userId: string;
  email: string;
  couponId?: string;
  organization?: CreateOrganizationRequest;
  priceId: string;
}

export interface CreateStripeSubscription extends CreateSubscriptionBaseRequest {
  customerId?: string;
  paymentMethodId: string;
}

export interface ChangeStripePaymentMethod {
  selectedPaymentMethod: PaymentMethods;
  userId: string;
}

export interface SubscribeRequest extends CreateSubscriptionBaseRequest {
  selectedPaymentMethod: PaymentMethods;
  selectedBillingPeriod: BillingPeriods;
  selectedPlan: PlanTypes;
}