import { Canvas } from '../canvas.types';
import firebase from 'firebase/app';
import { BaseSaveDriver } from './base.driver';
import { createLogger } from '@common/utils/logger';
import { CANVAS_STORAGE_URL } from '../../../config/firebaseStorage';

const SAVE_CANVAS_DEBOUNCE_TIME = 500;
const SAVE_THUMBNAIL_DEBOUNCE_TIME = 3000;

export class FirebaseSaveDriver extends BaseSaveDriver {
  constructor() {
    super(SAVE_CANVAS_DEBOUNCE_TIME, SAVE_THUMBNAIL_DEBOUNCE_TIME);
    this.logger = createLogger('FirebaseSaveDriver');
  }

  protected async _saveCanvas(canvas: Canvas) {
    const { canvasId } = canvas;
    this.logger.debug(`Saving Canvas to Firebase (canvasId: ${canvasId})`);

    try {
      await firebase
        .firestore()
        .doc(`canvases/${canvasId}`)
        .update({ ...canvas });
      
    } catch (error) {
      console.error('Could not save canvas', error);
    }
  }

  protected async _saveThumbnail(canvasId: string, base64: string) {
    this.logger.debug(`Saving Thumbnail to Firebase (canvasId: ${canvasId})`);
    const blob = await (await fetch(base64)).blob();
    const storageRef = firebase.app().storage(CANVAS_STORAGE_URL).ref(`${canvasId}_thumbnail.png`);
    storageRef.put(blob);
  }
}
