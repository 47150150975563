import React from 'react';
import { useUserSubscription } from './useUserSubscription';
import { SubscriptionContext } from '@common/context/subscription/SubscriptionContext';

export const SubscriptionProvider = ({ children }) => {
  const context = useUserSubscription();

  return (
    <SubscriptionContext.Provider
      value={context}
    >
      {children}
    </SubscriptionContext.Provider>
  )
}
