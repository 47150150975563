import firebase from 'firebase/app';
import { AuthError, Invitation } from '@common/types';
import { userService } from './user.service';
import { createLogger } from '@common/utils/logger';

const logger = createLogger('AuthService');

export const addUser = async (
  userCredential: firebase.auth.UserCredential,
  username = ""
) => {
  if (!userCredential.user) {
    throw new Error(AuthError.SIGNUP_FAILED);
  }

  return userService
    .createUser(userCredential.user.uid, {
      username: username ?? userCredential.user.displayName,
      email: userCredential.user.email,
    })
    .catch((error) => {
      if (error) {
        userCredential.user?.delete();
        logger.debug(error);
        throw new Error(AuthError.SIGNUP_FAILED);
      }
    });
};

export const addUserToOrganization = async (
  userId: string,
  invitation: Invitation
) => {
  return firebase
    .firestore()
    .doc(`pending_organization_members/${userId}`)
    .set({
      invitationId: invitation.id,
    })
    .catch((error) => logger.debug(error));
};
