import { captureException } from '@sentry/react';
import { SVG, Svg } from '@svgdotjs/svg.js';
import * as queryString from 'query-string';
import {
  AssetCategory,
  AssetLink,
  AssetCategories,
} from './object-types/Asset/asset.types';

const BASE_URL = process.env.REACT_APP_ASSETS_LIBRARY_URL;

export const getCategories = async (): Promise<AssetCategory[]> => {
  try {
    const categories = await fetch(`${BASE_URL}/categories`);
    return categories.json();
  } catch (error) {
    captureException(error);
  }
};

export const getAssetLinks = async (
  categoryName?: string
): Promise<AssetLink[]> => {
  const baseQueryParams: {
    category: string;
    status?: string;
  } = {
    category: categoryName || AssetCategories.ALL,
    status: 'LIVE',
  };

  try {
    const links = await fetch(
      `${BASE_URL}/assets?${queryString.stringify(baseQueryParams)}`
    );
    return links.json();
  } catch (error) {
    captureException(error);
  }
};

export const getAssetLinkById = async (assetId: string): Promise<AssetLink> => {
  try {
    const asset = await fetch(`${BASE_URL}/assets/${assetId}`);
    return asset.json();
  } catch (error) {
    captureException(error);
  }
};

export const getSVGFromAssetLink = async (url: string): Promise<Svg> => {
  try {
    return fetch(url).then(async (response) => {
      const assetSvg = await response.text();
      return SVG(assetSvg) as Svg;
    });
  } catch (error) {
    captureException(error);
  }
};
