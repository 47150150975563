// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { createLogger } from '@common/utils/logger';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const logger = createLogger('initializeFirebase');

// This information is not secret. No issue with keeping it here.
const devConfig = {
  apiKey: 'AIzaSyB9sIf_Dxl7SOW_0bnyr-RlTrMArEyX94M',
  authDomain: 'posturize-dev.firebaseapp.com',
  databaseURL: 'https://posturize-dev.firebaseio.com',
  projectId: 'posturize-dev',
  storageBucket: 'posturize-dev.appspot.com',
  messagingSenderId: '766254471563',
  appId: '1:766254471563:web:332e9a6fa14a2edacb778e',
  measurementId: 'G-9JX9F3BBDL',
};

// This information is not secret. No issue with keeping it here.
const prodConfig = {
  apiKey: 'AIzaSyA8zbw-hXr2bVfLXFrzoCZQq6gTg-R1gZ0',
  authDomain: 'posturize-prod.firebaseapp.com',
  databaseURL: 'https://posturize-prod.firebaseio.com',
  projectId: 'posturize-prod',
  storageBucket: 'posturize-prod.appspot.com',
  messagingSenderId: '385082093086',
  appId: '1:385082093086:web:f0ec543f5349f97d26880f',
  measurementId: 'G-XV7TV0CDPG',
};

const config =
  process.env.REACT_APP_DEPLOYMENT_STAGE === 'dev' ? devConfig : prodConfig;

export const initializeFirebase = () => {
  firebase.initializeApp(config);

  if (process.env.REACT_APP_EMULATE_FIREBASE) {
    logger.info('Using Firebase emulator');
    firebase.firestore().useEmulator('localhost', 8080);
    firebase.functions().useEmulator('localhost', 5001);
    firebase.auth().useEmulator('http://localhost:9099');
  }
};
