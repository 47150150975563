import log, { Logger as LogLevelLogger, LogLevelDesc } from 'loglevel';
import prefix from 'loglevel-plugin-prefix';

export interface Logger extends LogLevelLogger {}

export const createLogger = (context: string): Logger => {
  log.setLevel(process.env.REACT_APP_LOG_LEVEL as LogLevelDesc);
  const logger = log.noConflict();
  prefix.reg(logger);
  prefix.apply(logger, {
    template: `[%t] %l (${context}):`
  });

  return logger;
};