import {
  createCanvasSnapshot,
  saveElementAsPNG,
  saveElementAsJPEG,
} from '@editor/utils/saveCanvas.utils';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useExport } from './useExport';
import { AiOutlineClose as CloseIcon, AiOutlineQuestionCircle as HowToIcon } from 'react-icons/ai';
import { IoReturnDownBackOutline as BackIcon } from 'react-icons/io5';
import { useProjectContext } from '@common/context/project';
import { Button, IconButton } from '@common/components';
import useControls from '@editor/hooks/useControls';
import { ExportType } from '@editor/reducers/export.slice';
import { HowToDownloadModal } from './HowToDownloadModal';

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 14px;
  right: 14px;
`;

const ExportWrapper = styled.div`
  position: absolute;
  background: #fafafa;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  z-index: 103;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px;
  overflow: hidden;
`;

const Decoration = styled.img`
  width: 500px;
`;

const Image = styled.img<any>`
  display: block;
  border-radius: 48px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background: white;
  height: ${(props) =>
    props.dimensions.landscape ? `${props.dimensions.height}px` : 'auto'};
  width: ${(props) =>
    props.dimensions.landscape ? 'auto' : `${props.dimensions.width}px`};
`;

export const ExportContainer = () => {
  const { exporting, exportType, closeExportWizard } = useExport();
  const { setIsCapturingCanvas } = useControls();
  const [base64, setBase64] = useState(null);
  const [howToDownloadOpen, setHowToDownloadOpen] = useState(false);
  const [imageDimensions, setImageDimensions] = useState(null);
  const { currentProject } = useProjectContext();
  const imageContainerRef = useRef<any>();

  const handleClose = () => {
    setBase64(null);
    closeExportWizard();
    setIsCapturingCanvas(false);
  };

  useEffect(() => {
    const produceExport = () => {
      createCanvasSnapshot(async (canvas) => {
        const dataUrl = await (exportType === ExportType.PNG
          ? saveElementAsPNG(canvas)
          : saveElementAsJPEG(canvas));
        setBase64(dataUrl);
      });
    };

    if (exporting === true) {
      produceExport();
    }
  }, [exporting, exportType, currentProject]);

  useEffect(() => {
    if (imageContainerRef.current) {
      const {
        width,
        height,
      } = imageContainerRef.current.getBoundingClientRect();
      const landscape = width > height;
      setImageDimensions({ width, height, landscape });
    }
  }, [imageContainerRef, exporting, setImageDimensions]);

  if (!exporting) {
    return null;
  }

  return (
    <>
      <HowToDownloadModal open={howToDownloadOpen} onDismiss={() => setHowToDownloadOpen(false)} />
      <ExportWrapper>
        <CloseButton>
          <CloseIcon size={34} onClick={handleClose} />
        </CloseButton>
        <Decoration
          src="https://static-assets-cdn.aws.posturize.app/images/decoration_1.png"
          alt="decoration"
          style={{ position: 'absolute', top: -20, left: -20, zIndex: 1 }}
        />
        <Decoration
          src="https://static-assets-cdn.aws.posturize.app/images/decoration_2.png"
          alt="decoration"
          style={{ position: 'absolute', bottom: -20, right: -20, zIndex: 1 }}
        />
        <div style={{ zIndex: 2 }} className="flex flex-col h-full p-4">
          <div className="flex-none">
            <div className="mb-6">
              <h1 className="text-4xl text-center mb-4">
                Woohoo!{' '}
                <span role="img" aria-label="congratulations">
                  🎉
                </span>
              </h1>
              <p className="text-center">
                Download the export to your device and unleash your creativity!
              </p>
            </div>
          </div>
          <div
            className="flex-grow relative flex justify-center items-center"
            ref={imageContainerRef}
          >
            {base64 && imageDimensions && (
              <Image
                src={base64}
                landscape={false}
                dimensions={imageDimensions}
                alt="export"
              />
            )}
          </div>
          <div className="flex-none">
            <div className="text-md my-6" style={{ maxWidth: '80vw' }}>
              <div className="text-center">
                <button
                  onClick={() => setHowToDownloadOpen(true)}
                  type="button"
                  className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-gray-500 hover:bg-gray-700 focus:outline-none"
                >
                  <HowToIcon style={{ marginRight: 12 }} color='white' size={20} />
                  How to Download
                </button>
              </div>

              <h2 className="font-bold">Share your talent!</h2>
              <p>
                Upload your illustrations to social media with the{' '}
                <span className="font-bold">#madewithposturize</span> hashtag for a
                chance to get featured and win a 3-months free premium
                subscription.
              </p>
            </div>
            <div>
              <Button color="primary text-white" onClick={handleClose}>
                <span className="mr-2">
                  <BackIcon style={{ display: 'inline' }} size={20} />
                </span>
                Back to Project
              </Button>
            </div>
          </div>
        </div>
      </ExportWrapper>
    </>
  );
};
