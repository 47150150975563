import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/styles.css';
import './assets/tailwind.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeFirebase } from './config/initializeFirebase';
import { AuthProvider, ToastProvider } from '@common/context';
import { Provider } from 'react-redux';
import store from './store';
import { ScreensizeProvider } from '@common/context';
import { setupSentry } from './config/setupSentry';
import { Elements } from '@stripe/react-stripe-js';
import { SubscriptionProvider } from '@common/context/subscription/SubscriptionProvider';
import { loadStripe } from '@stripe/stripe-js/pure';
import { ApolloProvider } from '@apollo/client';
import { graphQLClient } from '@common/utils/graphql-client';

setupSentry();
initializeFirebase();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={graphQLClient}>
      <ToastProvider>
        <AuthProvider>
          <ScreensizeProvider>
            <Elements stripe={stripePromise}>
              <SubscriptionProvider>
                <App />
              </SubscriptionProvider>
            </Elements>
          </ScreensizeProvider>
        </AuthProvider>
      </ToastProvider>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
