import Default from './presets/Default.preset.json';
import Walking from './presets/Walking.preset.json';
import Superman from './presets/Superman.preset.json';
import Reading from './presets/Reading.preset.json';
import OhYeah from './presets/OhYeah.preset.json';
import Chilling from './presets/Chilling.preset.json';
import Ladder from './presets/Ladder.preset.json';
import Ballerina from './presets/Ballerina.preset.json';
import Selfie from './presets/Selfie.preset.json';
import Sprint from './presets/Sprint.preset.json';
import Skating from './presets/Skating.preset.json';
import Handstand from './presets/Handstand.preset.json';
import Modelling from './presets/Modelling.preset.json';
import Stretching from './presets/Stretching.preset.json';
import CrossingHands from './presets/CrossingHands.preset.json';
import PushingAhead from './presets/PushingAhead.preset.json';

const BASE_PRESET_URL = 'https://static-assets-cdn.aws.posturize.app/presets';

const presets = [
  { name: 'Default', values: Default, thumbnail: `${BASE_PRESET_URL}/Default.png` },
  { name: 'Walking', values: Walking, thumbnail: `${BASE_PRESET_URL}/Walking.png` },
  { name: 'Superman', values: Superman, thumbnail: `${BASE_PRESET_URL}/Superman.png` },
  { name: 'Reading', values: Reading, thumbnail: `${BASE_PRESET_URL}/Reading.png` },
  { name: 'OhYeah', values: OhYeah, thumbnail: `${BASE_PRESET_URL}/OhYeah.png` },
  { name: 'Chilling', values: Chilling, thumbnail: `${BASE_PRESET_URL}/Chilling.png` },
  { name: 'Ladder', values: Ladder, thumbnail: `${BASE_PRESET_URL}/Ladder.png` },
  { name: 'Ballerina', values: Ballerina, thumbnail: `${BASE_PRESET_URL}/Ballerina.png` },
  { name: 'Selfie', values: Selfie, thumbnail: `${BASE_PRESET_URL}/Selfie.png` },
  { name: 'Sprint', values: Sprint, thumbnail: `${BASE_PRESET_URL}/Sprint.png` },
  { name: 'Skating', values: Skating, thumbnail: `${BASE_PRESET_URL}/Skating.png` },
  { name: 'Handstand', values: Handstand, thumbnail: `${BASE_PRESET_URL}/Handstand.png` },
  { name: 'Modelling', values: Modelling, thumbnail: `${BASE_PRESET_URL}/Modelling.png` },
  { name: 'Stretching', values: Stretching, thumbnail: `${BASE_PRESET_URL}/Stretching.png` },
  { name: 'CrossingHands', values: CrossingHands, thumbnail: `${BASE_PRESET_URL}/CrossingHands.png` },
  { name: 'PushingAhead', values: PushingAhead, thumbnail: `${BASE_PRESET_URL}/PushingAhead.png` },
];

export default presets;