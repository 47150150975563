import firebase from 'firebase/app';
import { User } from '@common/types';
import { createLogger } from '@common/utils/logger';

const logger = createLogger('UserService');
class UserService {
  private readonly USERS_COLLECTION = 'users';

  public async findUserRefById(userId: string) {
    return firebase.firestore().doc(`users/${userId}`);
  }

  public async findUserByEmail(email: string): Promise<User | undefined> {
    try {
      const [userSnapshot] = (
        await firebase
          .firestore()
          .collection(this.USERS_COLLECTION)
          .where('email', '==', email)
          .limit(1)
          .get()
      ).docs;

      return userSnapshot?.exists
        ? { ...(userSnapshot.data() as User), id: userSnapshot.id }
        : undefined;
    } catch (error) {
      logger.debug(error);
      throw error;
    }
  }

  public async findUserById(userId: string): Promise<User> {
    try {
      const userSnapshot = await firebase
        .firestore()
        .doc(`${this.USERS_COLLECTION}/${userId}`)
        .get();
      return userSnapshot?.data() as User;
    } catch (error) {
      logger.debug(error);
      throw error;
    }
  }

  public async updateUser(userId: string, data: Partial<User>): Promise<void> {
    try {
      await firebase
        .firestore()
        .doc(`${this.USERS_COLLECTION}/${userId}`)
        .update(data);
    } catch (error) {
      logger.debug(error);
      throw error;
    }
  }

  public async updateUserByEmail(
    email: string,
    data: Partial<User>
  ): Promise<void> {
    try {
      const [userSnapshot] = (
        await firebase
          .firestore()
          .collection(this.USERS_COLLECTION)
          .where('email', '==', email)
          .limit(1)
          .get()
      ).docs;
      await userSnapshot?.ref.update(data);
    } catch (error) {
      logger.debug(error);
      throw error;
    }
  }

  public async createUser(
    userId: string,
    user: Omit<User, 'id'>
  ): Promise<void> {
    try {
      return await firebase
        .firestore()
        .doc(`${this.USERS_COLLECTION}/${userId}`)
        .set(user);
    } catch (error) {
      logger.debug(error);
      throw error;
    }
  }
}

export const userService = new UserService();
