import React from 'react';
import { Modal, Loader } from '@common/components';
import styled from 'styled-components';
import { Asset } from './asset.types';
import { AssetThumbnail } from './AssetThumbnail';
import cn from 'classnames';

const THUMBNAIL_SIZE = 200;
const THUMBNAILS_PER_ROW = 3;

const CategoriesContainer = styled.div`
  width: 200px;
  padding: 10px;
`;

const Category = styled.div<any>`
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 4px;

  &:hover {
    cursor: pointer;
    background: #dedede;
  }

  &.selected {
    color: white;
    background: #5861f1;
  }
`;

const ThumbnailsContainer = styled.div`
  background: #efefef;
  width: ${THUMBNAIL_SIZE * THUMBNAILS_PER_ROW}px;
  height: ${THUMBNAIL_SIZE * THUMBNAILS_PER_ROW}px;

  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  ::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
  }
`;

export const AddLibraryAssetModal = ({
  assets,
  isOpen,
  onAssetSelected,
  onCategorySelected,
  onDismiss,
  selectedCategory,
  categories,
  loading,
}) => {

  return (
    <Modal
      isOpen={isOpen}
      title="Select an asset"
      onDismiss={onDismiss}
      contentPadding={false}
      withCloseBtn
    >
      <div className="flex">
        <CategoriesContainer>
          {Object.values(categories).map(({ name, id }) => (
            <Category
              key={id}
              className={cn({ selected: selectedCategory === id })}
              onClick={() =>
                id !== selectedCategory ? onCategorySelected(id) : undefined
              }
            >
              {name}
            </Category>
          ))}
        </CategoriesContainer>
        <ThumbnailsContainer className="flex flex-wrap content-start overflow-hidden overflow-y-auto rounded-md">
          {loading ? (
            <div className="flex items-center w-full h-full">
              <Loader size="large" className="flex justify-center m-auto" />
            </div>
          ) : (
            assets?.map((asset: Asset) => (
              <AssetThumbnail
                key={asset.id}
                style={{ width: THUMBNAIL_SIZE, height: THUMBNAIL_SIZE }}
                svg={asset.svg.node}
                onClick={() => onAssetSelected(asset)}
              />
            ))
          )}
        </ThumbnailsContainer>
      </div>
    </Modal>
  );
};
