import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const SVGContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #efefef;
  padding: 14px;

  &:hover {
    cursor: pointer;
    background: #e2e2e2;
  }

  svg {
    max-width: 180px;
    max-height: 180px;
  }

  #AssetGroup {
    stroke-width: 2px;
    stroke-dasharray: 6 4;
  }
`;

export const AssetThumbnail = ({ svg, style, onClick }) => {
  const svgRef = useRef();

  useEffect(() => {
    const current = svgRef.current as SVGElement | undefined;
    current?.appendChild(svg);

    return () => {
      current?.removeChild(svg);
    };
  }, [svg]);

  return <SVGContainer ref={svgRef} style={style} onClick={onClick} />;
};
