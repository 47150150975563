import firebase from 'firebase/app';

import { Project } from '@common/types';
import { useCollectionData } from 'react-firebase-hooks/firestore/';

export const useProjects = (ownerId: string) => {
  const [projects, loading, error] = useCollectionData<Project>(
    firebase
      .firestore()
      .collection('projects')
      .where('ownerId', '==', ownerId)
      .orderBy('createdAt', 'desc'),
    {
      idField: 'id',
    }
  );

  return {
    projects,
    loading,
    error,
  };
};
