import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Input, Button, Loader } from '@common/components';
import { useAuthContext } from '@common/context';

export const ForgotPasswordContainer = () => {
  const { register, handleSubmit, errors, formState } = useForm<{
    email: string;
  }>();

  const [emailRequestState, setEmailRequestState] = useState({
    requestSent: false,
    loading: false,
    email: '',
  });

  const { sendPasswordResetEmail } = useAuthContext();

  return (
    <div className="h-full">
      <div className="h-full flex max-w-md m-auto items-center justify-center">
        <div className="w-full px-8 py-8 bg-white shadow-xl rounded-2xl">
          <h1 className="font-light text-3xl py-4 text-center font-medium">
            {!emailRequestState.requestSent ? 'Forgot your password?' : 'Check your inbox'}
          </h1>

          {!emailRequestState.requestSent ? (
            <>
              <p className="mb-8">
                Enter the email address you used to sign up and we'll help you
                reset your password.
              </p>

              <form
                onSubmit={handleSubmit(({ email }) => {
                  setEmailRequestState((prev) => ({
                    ...prev,
                    requestSent: false,
                    loading: true,
                  }));
                  sendPasswordResetEmail(email).finally(() => {
                    setEmailRequestState(() => ({
                      requestSent: true,
                      email,
                      loading: false,
                    }));
                  });
                })}
                autoComplete="off"
              >
                <Input
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Enter a valid e-mail address',
                    },
                  })}
                  name="email"
                  type="email"
                  placeholder="Email"
                  error={!!errors?.email}
                  errorMessage={errors?.email?.message}
                  className="mb-4"
                />

                <Button
                  color="primary"
                  size="large"
                  type="submit"
                  className="w-full"
                  disabled={
                    !!errors?.email ||
                    !formState.isDirty ||
                    emailRequestState.loading
                  }
                >
                  {emailRequestState.loading ? (
                    <Loader className="flex justify-center" />
                  ) : (
                    <>Reset Password</>
                  )}
                </Button>
              </form>
              <div className="text-center">
                <Link
                  to="/login"
                  className="text-primary hover:text-primary-dark block text-center mt-4"
                >
                  Back to login
                </Link>
              </div>
            </>
          ) : (
            <div>
              If <strong>{emailRequestState?.email}</strong> corresponds to an account in our system, you should receive an email with further instructions on how to reset your password.
              <div className="text-right">
                <Link
                    to="/login"
                    className="text-primary hover:text-primary-dark block text-center mt-4"
                  >
                  Back to login
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
