 /* eslint-disable */ 
 
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';

import { PersonalDetailsBox } from '../components/PersonalDetailsBox';
import { BillingBox } from '../components/BillingBox';
import { PlanBox } from '../components/PlanBox';
import { useSubscriptions } from '../../+subscriptions/hooks/useSubscription';
import { PlanTypes } from '@posturize/shared/payments/plan.types';
import { useAuthContext } from '@common/context';
import { useSubscriptionContext } from '@common/context/subscription/useSubscriptionContext';
import { AuthErrorCode } from '@common/types';
import { ChangePasswordModal } from '../components/ChangePasswordModal';
import { CancelSubscriptionConfirmation } from '../components/CancelSubscriptionConfirmation';
import { ChangePaymentMethodModal } from '../components/ChangePaymentMethodModal';
import { PaymentMethods } from '../../+subscriptions/types';

export const AccountContainer = () => {
  const history = useHistory();
  const { subscription, planType, error } = useSubscriptionContext();
  const [loadingSubscriptionMutation, setLoadingSubscriptionMutation] = useState(false);
  const { cancelSubscription, undoCancelSubscription, changePaymentMethod } = useSubscriptions();
  const [
    showCancelConfirmationModal,
    setShowCancelConfirmationModal,
  ] = useState(false);
  const [changePasswordState, setChangePasswordState] = useState({
    loading: false,
    error: undefined,
    modalOpen: false,
  });
  const [changePaymentMethodState, setChangePaymentMethodState] = useState({
    loading: false,
    error: undefined,
    modalOpen: false,
  });

  const { currentUser } = useAuthContext();

  const handleUndoCancelSubscription = async () => {
    setLoadingSubscriptionMutation(true);
    await undoCancelSubscription(subscription.provider, currentUser.uid);
    // Data arrives a bit later from firebase, so we wait a bit more
    setTimeout(() => {
      setLoadingSubscriptionMutation(false);
    }, 1500);
  };

  const handleCancelSubscription = async () => {
    setShowCancelConfirmationModal(false);

    setLoadingSubscriptionMutation(true);
    await cancelSubscription(subscription.provider, currentUser.uid);
    // Data arrives a bit later from firebase, so we wait a bit more
    setTimeout(() => {
      setLoadingSubscriptionMutation(false);
    }, 1500);
  };

  const handleCancelSubscriptionRequest = () => {
    setShowCancelConfirmationModal(true);
  };

  const handleChangePassword = async ({ currentPassword, newPassword }) => {
    setChangePasswordState((prev) => ({
      ...prev,
      error: undefined,
      loading: true,
    }));
    const credential = firebase.auth.EmailAuthProvider.credential(
      firebase.auth().currentUser.email,
      currentPassword
    );

    try {
      await currentUser?.reauthenticateWithCredential(credential);
      await currentUser.updatePassword(newPassword);
      setChangePasswordState((prev) => ({
        ...prev,
        modalOpen: false,
        loading: false,
      }));
    } catch (error) {
      const errorMessage =
        error.code === AuthErrorCode.WRONG_PASSWORD
          ? 'The password you entered is invalid'
          : 'Something went wrong, Try again later.';

      setChangePasswordState((prev) => ({
        ...prev,
        loading: false,
        error: errorMessage,
      }));
    }
  };

  async function handleChangePaymentMethod(selectedPaymentMethod: PaymentMethods) {
    setChangePaymentMethodState((prev) => ({
      ...prev,
      error: undefined,
      loading: true,
    }));

    try {
      await changePaymentMethod({
        selectedPaymentMethod,
        userId: currentUser.uid,
      });
      setChangePaymentMethodState((prev) => ({
        ...prev,
        modalOpen: false,
        loading: false,
      }));
    } catch (e) {
      setChangePaymentMethodState((prev) => ({
        ...prev,
        error: e,
        loading: false,
      }));
    }
  }

  return (
    <div className="max-w-screen-xl md:px-12 m-auto">
      <div className="mx-12 mx-auto my-4 p-12 rounded-lg bg-secondary">
        <div className="m-auto max-w-screen-md">
          <h1 className="mb-12 text-4xl text-primary-dark font-medium">
            Your account
          </h1>
          <PersonalDetailsBox
            onChangePassword={() =>
              setChangePasswordState((prev) => ({ ...prev, modalOpen: true }))
            }
          />
          {/* {subscription && (
            <BillingBox
              subscription={subscription}
              onChangePaymentInfo={() =>
                setChangePaymentMethodState((prev) => ({ ...prev, modalOpen: true }))
              }
              error={error}
            />
          )} */}
          {/* {currentUser?.emailVerified && (
            <PlanBox
              onCancelPlan={
                planType !== PlanTypes.FREE
                  ? handleCancelSubscriptionRequest
                  : undefined
              }
              onChangePlan={() => history.push('/subscription')}
              onUndoCancelPlan={handleUndoCancelSubscription}
              planType={planType}
              endDate={subscription?.endDate}
              buttonLoader={loadingSubscriptionMutation}
            />
          )} */}
        </div>
        {/* <CancelSubscriptionConfirmation
          isOpen={showCancelConfirmationModal}
          onDismiss={() => setShowCancelConfirmationModal(false)}
          onConfirm={handleCancelSubscription}
        />
        <ChangePaymentMethodModal
          onSubmit={handleChangePaymentMethod}
          onDismiss={() =>
            setChangePaymentMethodState((prev) => ({ ...prev, modalOpen: false }))
          }
          {...changePaymentMethodState}
        /> */}
        <ChangePasswordModal
          onSubmit={handleChangePassword}
          onDismiss={() =>
            setChangePasswordState((prev) => ({ ...prev, modalOpen: false }))
          }
          {...changePasswordState}
        />
      </div>
    </div>
  );
};
