import React from 'react';

import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { MdKeyboardBackspace as BackToDashboardIcon } from 'react-icons/md';
import { useHistory } from 'react-router';

export const SandwichMenu = (props: MenuProps) => {
  const history = useHistory();

  return (
    <Menu {...props}>
      <MenuItem onClick={() => history.push('/projects')}>
        <ListItemIcon>
          <BackToDashboardIcon />
        </ListItemIcon>
        <ListItemText>Back to Dashboard</ListItemText>
      </MenuItem>
    </Menu>
  );
};
