import firebase from 'firebase/app';
import { createCanvasObject } from '@editor/utils/canvasObjectUtils';

export const addProject = async (projectName: string, ownerId: string) => {
  const canvasObject = createCanvasObject(projectName, ownerId);
  const canvasRef = await firebase.firestore().collection('canvases').doc(canvasObject.canvasId);
  await canvasRef.set(canvasObject);

  const project = await firebase.firestore().collection('projects').add({
    ownerId,
    name: projectName,
    createdAt: new Date(),
    updatedAt: new Date(),
    lastUpdatedUserId: ownerId,
    canvasId: canvasObject.canvasId,
    canvasSlug: canvasObject.slug,
  });

  return project;
};
