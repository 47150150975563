import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useCanvasV2 } from '@editor/hooks/useCanvasV2';
import cn from 'classnames';

import { ObjectContext } from '../../ObjectContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectObject,
  setObjectProperty,
} from '@editor/reducers/canvas.slice';
import { Subject } from 'rxjs';

import { HumanSkeleton } from './engine/HumanSkeleton';
import { HumanSvg } from './Human.svg';
import { Svg, SVG } from '@svgdotjs/svg.js';
import { HandSelectionModal } from './HandSelectionModal';
import { useObject } from '@editor/hooks/useObject';
import { Joint } from './engine/Joint';
import { createLogger } from '@common/utils/logger';
import { selectPanZoomScale } from '@editor/reducers/panzoom.slice';
import { HumanV2Style } from './HumanV2.style';
import { FootSelectionModal } from './FootSelectionModal';
import CanvasEventBus from '@common/services/human-events.service';

const HumanContainer = styled.div<any>`${HumanV2Style}`;

const HumanV2 = ({ object, controlled, onSelected }) => {
  const logger = createLogger(`Human:${object.id}`);
  const dispatch = useDispatch();
  const { objectId } = useContext(ObjectContext);
  const { objectProperties, objectBones } = useObject(objectId);
  const { canvasDimensions } = useCanvasV2();
  const canvasScale = useSelector(selectPanZoomScale);
  const [svgRef, setSvgRef] = useState(null);
  const svgContainerRef = useRef<any>();
  const svgTransitionRef = useRef<any>();
  const objectFromState = useSelector(selectObject(objectId));
  const [humanObject, setHumanObject] = useState<HumanSkeleton>(null);
  const [selectingHand, setSelectingHand] = useState(null);
  const [selectingFoot, setSelectingFoot] = useState(null);

  // Injecting the SVG dynamically. That's our only way to achieve
  // synced re-renders, which is very important for our sidebar controls.
  //
  // We have to re-render the SVG every time the global state changes (and thus props),
  // otherwise the pivot points are misaligned and everything breaks.
  useEffect(() => {
    if (svgContainerRef.current) {
      logger.debug('Injecting SVG');
      const svg = SVG(HumanSvg.replace(/(\r\n|\n|\r)/gm, ''));
      svg.node.classList.add('draggable-subject');
      svg.node.setAttribute('data-initial-width', `${svg.width()}`);
      svg.node.setAttribute('data-initial-height', `${svg.height()}`);
      svg.opacity(0);
      svgContainerRef.current.appendChild(svg.node);
      setSvgRef(svg.node);

      svg.node.querySelector('#HumanContainer').classList.add('object-bounds');

      svg.node.classList.add('canvas-object');
      svg.node.id = `object-${objectId}`;
      
      svg.node.addEventListener('mousedown', (e) => onSelected(e));
      svg.node.addEventListener('touchstart', (e) => onSelected(e));

      return () => {
        svgTransitionRef.current = svg;
        setSvgRef(null);
        setHumanObject(null);
      };
    }
    // Here we insert dependencies for a complete re-render.
  }, [svgContainerRef, objectProperties.leftHand, objectProperties.rightHand, objectProperties.leftFoot, objectProperties.rightFoot, objectBones]); // eslint-disable-line

  useEffect(() => {
    const { locked } = objectProperties;
    if (svgContainerRef.current && humanObject && humanObject.face) {
      humanObject.setJointsLockStatus(locked);
      humanObject.setFaceLockStatus(locked);
      const humanContainer = svgContainerRef.current.querySelector('#HumanContainer');

      if (locked) {
        humanContainer.classList.add('locked');
      } else {
        humanContainer.classList.remove('locked');
      }
    }
  }, [objectProperties.locked, humanObject]); // eslint-disable-line

  useEffect(() => {
    if (svgRef && canvasScale && !humanObject) {
      const human = new HumanSkeleton({
        svgElement: svgRef,
        object: objectFromState,
        canvasScale,
        canvasDimensions,
        flipHorizontally: objectProperties.flipHorizontally,
        dispatch,
      });

      setHumanObject(human);
    }
  }, [svgRef, canvasScale]); // eslint-disable-line

  const initializeHuman = () => {
    if (!humanObject.initialized) {
      humanObject.initializeFace();
      humanObject.face.renderFace();
    }

    // Store initial width and height
    humanObject.initializeJoints();
    humanObject.initializeHands();
    humanObject.initializeFeet();
    humanObject.initialized = true;
    humanObject.renderInitialJointAngles();
    humanObject.setJointsLockStatus(objectProperties.locked);
    humanObject.setFaceLockStatus(objectProperties.locked);
    humanObject.applyBodyAngle(objectProperties.bodyAngle);
    humanObject.initializeHoverEffects();
    
    humanObject.joints.LeftHand.onClickWithoutMove = () => setSelectingHand('leftHand');
    humanObject.joints.RightHand.onClickWithoutMove = () => setSelectingHand('rightHand');
    humanObject.joints.LeftFoot.onClickWithoutMove = () => setSelectingFoot('leftFoot');
    humanObject.joints.RightFoot.onClickWithoutMove = () => setSelectingFoot('rightFoot');
    
    Object.values(humanObject.joints).forEach((joint: Joint) => {
      joint.rotationHandleNode.node.addEventListener('mousedown', () => onSelected());
      joint.rotationHandleNode.node.addEventListener('touchdown', () => onSelected());
    });
    
    humanObject.applyFlipHorizontally(objectProperties.flipHorizontally);
    humanObject.removeSupports();
    humanObject.applyColors();
    humanObject.applyScale(objectProperties.scale);

    // This part is very important. We use this effect to
    // completely rerender the human in very specific cases.
    // This allows us to have a temporary clone so we don't see a "flickering effect"
    // between the time the SVG is created (T-Shape) and the joints are initialized.
    (SVG(svgRef) as Svg).opacity(1);

    if (svgTransitionRef.current) {
      svgTransitionRef.current.remove();
    }
  };

  useEffect(() => {
    const setupEventBus = () => {
      const bodyAngle$ = new Subject();
      const opacity$ = new Subject();

      CanvasEventBus.addObservable(`${objectId}_bodyAngle`, bodyAngle$);
      CanvasEventBus.addObservable(`${objectId}_opacity`, opacity$);

      const bodyAngleSubscription = bodyAngle$.subscribe((newBodyAngle: number) => humanObject.applyBodyAngle(newBodyAngle));
      const opacitySubscription = opacity$.subscribe((newOpacity: number) => humanObject.applyOpacity(newOpacity));

      return (() => {
        bodyAngleSubscription.unsubscribe();
        CanvasEventBus.removeObservable(`${objectId}_bodyAngle`);
        opacitySubscription.unsubscribe();
        CanvasEventBus.removeObservable(`${objectId}_opacity`);
      });
    };

    if (humanObject) {
      return setupEventBus();
    }
  }, [humanObject, objectId]);

  useEffect(() => {
    if (humanObject && !humanObject.initialized && canvasScale) {
      initializeHuman();
    }
  }, [humanObject, objectProperties.bodyAngle]); // eslint-disable-line

  useEffect(() => {
    if (humanObject?.initialized) {
      humanObject.applyBodyAngle(objectProperties.bodyAngle);
      humanObject.applyColors();
    }
  }, [objectProperties.bodyAngle]); // eslint-disable-line

  useEffect(() => {
    if (humanObject?.face) {
      const path = humanObject.face.drawPath(objectProperties.faceAngle);
      humanObject.face.applyPathToSvg(path, objectProperties.faceAngle);
    }
  }, [humanObject, objectProperties.faceAngle])

  useEffect(() => {
    if (humanObject) {
      humanObject.applyFlipHorizontally(objectProperties.flipHorizontally);
    }
  }, [humanObject, objectProperties.flipHorizontally]);

  useEffect(() => {
    if (humanObject) {
      humanObject.applyOpacity(objectProperties.opacity);
    }
  }, [humanObject, objectProperties.opacity]);

  useEffect(() => {
    if (humanObject) {
      humanObject.applyScale(objectProperties.scale);
    }
  }, [humanObject, objectProperties.scale]);

  const handleHandSelected = (newHandType: string) => {
    logger.debug(`Selected hand "${newHandType}" for hand ${selectingHand}`);
    const currentHandObjectFromState = object.properties[selectingHand];
    dispatch(setObjectProperty(objectId, selectingHand, {
      ...currentHandObjectFromState,
      type: newHandType,
    }));
    setSelectingHand(null);
  };

  const handleHandFlipped = (flipVertically: boolean) => {
    const currentHandObjectFromState = object.properties[selectingHand];
    
    dispatch(setObjectProperty(objectId, selectingHand, {
      ...currentHandObjectFromState,
      flipVertically,
    }));
  };

  const handleFootFlipped = (flipHorizontally: boolean) => {
    const currentFootObjectFromState = object.properties[selectingFoot];
    
    dispatch(setObjectProperty(objectId, selectingFoot, {
      ...currentFootObjectFromState,
      flipHorizontally,
    }));
  };

  return (
    <>
    {
      !!selectingHand && (
        <HandSelectionModal
          open={!!selectingHand}
          onClose={() => setSelectingHand(null)}
          onHandSelected={handleHandSelected}
          onHandFlipped={handleHandFlipped}
          flipped={object.properties[selectingHand].flipVertically}
        />
      )
    }
        {
      !!selectingFoot && (
        <FootSelectionModal
          open={!!selectingFoot}
          onClose={() => setSelectingFoot(null)}
          onFootFlipped={handleFootFlipped}
          flipped={object.properties[selectingFoot].flipHorizontally}
        />
      )
    }
      <HumanContainer className={cn({ controlled, locked: objectProperties.locked })} ref={svgContainerRef} controlled={controlled} />
    </>
  );
};

export default HumanV2;
