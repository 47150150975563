import React, { FC } from 'react';
import { TermsOfService } from './TermsOfService';
import styled from 'styled-components';
import { PrivacyPolicy } from './PrivacyPolicy';
import { BetaTermsOfService } from './BetaTermsOfService';

const TermsWrapper = styled.div`
  h2 {
    font-weight: bold;
    margin-top: 12px;
    margin-bottom: 6px;
  }

  li {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 32px;
    list-style: disc;
  }
`;

export const TermsRoute: FC<{}> = () => {
  return (
    <TermsWrapper>
      <div className="container mx-auto px-4 p-10">
        {/* Terms of Service */}
        <div className="mb-12">
          <h1 className="mb-4 text-3xl text-primary-dark font-medium">
            Terms of Service
          </h1>
          <div>
            <TermsOfService />
          </div>
        </div>
        {/* Privacy Policy */}
        <div className="mb-12">
          <h1 className="mb-4 text-3xl text-primary-dark font-medium">
            Privacy Policy
          </h1>
          <div>
            <PrivacyPolicy />
          </div>
        </div>
        {/* Beta Terms of Service */}
        <div className="mb-12">
          <h1 className="mb-4 text-3xl text-primary-dark font-medium">
            Beta Terms of Service
          </h1>
          <div>
            <BetaTermsOfService />
          </div>
        </div>
      </div>
    </TermsWrapper>
  );
};
