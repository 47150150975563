import firebase from 'firebase/app';

import { Project } from '@common/types';

export const updateProject = (
  projectId: string,
  updateObject: Partial<Project>
) => {
  return firebase.firestore().doc(`projects/${projectId}`).update(updateObject);
};
