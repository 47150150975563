import firebase from 'firebase/app';
import { User } from './User';

export enum OrganizationMemberRole {
  MEMBER = 'member',
  ADMIN = 'admin',
}

export interface FirebaseOrganizationMember {
  ref: firebase.firestore.DocumentReference<User>;
  role: OrganizationMemberRole;
  email: string;
}

export interface OrganizationMember {
  role: OrganizationMemberRole;
  active: boolean;
  email: string;
  ref?: firebase.firestore.DocumentReference<User>;
}

export interface Organization {
  id: string;
  name: string;
  admins: string[]; //ADMIN USER IDS
  members: OrganizationMember[];
}
