import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Input, Button, Loader } from '@common/components';

export const ChangePasswordForm = ({ onSubmit, loading, error }) => {
  const { register, handleSubmit, reset, formState, errors } = useForm();
  useEffect(() => {
    if (error) {
      reset();
    }
  }, [error, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="my-6">
        <label htmlFor="current-password">Current password</label>
        <Input
          className="mt-2"
          name="currentPassword"
          id="current-password"
          type="password"
          ref={register({ required: true, maxLength: 16 })}
          error={!!errors?.currentPassword}
          placeholder="Current password"
        />
      </div>
      <div className="my-6">
        <label htmlFor="new-password" className="mb-2">
          New password
        </label>
        <Input
          className="mt-2"
          name="newPassword"
          id="new-password"
          type="password"
          ref={register({ required: true, maxLength: 16 })}
          error={!!errors?.newPassword}
          placeholder="New password"
        />
      </div>
      {error && <p className="text-warning text-medium my-2">{error}</p>}
      <div className="flex flex-row-reverse">
        <Button
          color="primary"
          size="large"
          className="w-52"
          disabled={loading || !formState.isDirty}
        >
          {loading ? (
            <Loader className="flex justify-center" />
          ) : (
            <>Update password</>
          )}
        </Button>
      </div>
    </form>
  );
};
