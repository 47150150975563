import React from 'react';
import { KnowledgeBaseTopic, DemoGIF } from '../knowledge-base.common';

export const topic: KnowledgeBaseTopic = {
  id: 'human_hands',
  label: 'Human Hands',
  premium: false,
  Content: () => (
    <div>
      <div>
        <div className="mt-4">
          <h2 className="text-lg font-medium">Changing hands</h2>
          You can hange the hands of a Human object by clicking or tapping on
          the hand you want to change.
          <div className="mt-6">
            <DemoGIF src="https://static-assets-cdn.aws.posturize.app/knowledge-base/ChangingHands.gif" />
          </div>
        </div>

        <div className="mt-4">
          <h2 className="text-lg font-medium">Flipping hands</h2>
          Using the same dialog, you can flip the hand as well.
          <div className="mt-6">
            <DemoGIF src="https://static-assets-cdn.aws.posturize.app/knowledge-base/FlippingHands.png" width={180} />
          </div>
        </div>
      </div>
    </div>
  ),
};
