import { simplifyEvent } from '@editor/utils/touch.utils';
import { G, SVG, Svg } from '@svgdotjs/svg.js';
import { getSubstractedCenterOffset } from '@editor/utils/svg.utils';
import { generatePathData } from '../draw.utils';
import { calcProportions } from '@editor/utils/saveCanvas.utils';
import { createLogger, Logger } from '@common/utils/logger';

const FACE_COLOR = 'none';

interface FaceOptions {
  svg: Svg;
  objectId: string;
  defaultValue: number;
  canvasScale: number;
  onFaceAngleChange: Function;
  flipHorizontally: boolean;
  locked?: boolean;
}

export class Face {
  logger: Logger;

  svg: Svg;
  defaultFaceAngle: number;
  faceAngle: number;
  faceClassName: string;
  originX: number;
  originY: number;
  canvasScale: number;
  onFaceAngleChange: Function;
  flipHorizontally: boolean;
  faceMask: G;
  locked: boolean;

  private FACE_WIDTH = 110;
  private FACE_HEIGHT = 100;
  private FACE_MARGIN_BOTTOM = 55;
  private FACE_BORDER_RADIUS_MULTIPLIER = 100;

  constructor(options: FaceOptions) {
    const {
      svg,
      objectId,
      defaultValue,
      canvasScale,
      onFaceAngleChange,
      flipHorizontally,
      locked = false,
    } = options;

    this.logger = createLogger(`HumanSkeleton:${objectId}:Face`);

    this.flipHorizontally = flipHorizontally;
    this.svg = svg;
    this.defaultFaceAngle = defaultValue;
    this.faceAngle = this.defaultFaceAngle;
    this.faceClassName = `object_${objectId}_face`;
    this.canvasScale = canvasScale;
    this.onFaceAngleChange = onFaceAngleChange;
    this.locked = locked;
  }

  setLocked(locked: boolean) {
    this.locked = locked;
  }

  setFaceAngle(angle: number) {
    this.faceAngle = angle;
  }

  handleSlideStart(e: MouseEvent) {
    if (this.locked) {
      return;
    }
    
    e.preventDefault();
    e.stopPropagation();

    const { clientX, type } = simplifyEvent(e);
    const mouseMoveEventName = type === 'mouse' ? 'mousemove' : 'touchmove';
    const mouseUpEventName = type === 'mouse' ? 'mouseup' : 'touchend';

    let startX, currentX, diff;
    let newFaceAngle = this.faceAngle;
    const currentFaceAngle = this.faceAngle;
    startX = clientX;
    let path;

    const handleSlideMovement = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const { clientX } = simplifyEvent(e);
      currentX = clientX;
      diff = currentX - startX;

      if (this.flipHorizontally) {
        diff *= -1;
      }

      newFaceAngle = currentFaceAngle + diff;

      if (newFaceAngle > 100) {
        newFaceAngle = 100;
      } else if (newFaceAngle < 0) {
        newFaceAngle = 0;
      }

      this.faceAngle = newFaceAngle;
      path = this.drawPath();
      this.applyPathToSvg(path);
    };

    let _handleSlideMovement;
    let _mouseUpEventHandler;

    const mouseUpEventHandler = () => {
      window.removeEventListener(mouseMoveEventName, _handleSlideMovement);
      window.removeEventListener(mouseUpEventName, _mouseUpEventHandler);
      this.defaultFaceAngle = newFaceAngle;
      this.onFaceAngleChange(newFaceAngle);
    };

    _handleSlideMovement = handleSlideMovement.bind(this);
    _mouseUpEventHandler = mouseUpEventHandler.bind(this);

    window.addEventListener(mouseMoveEventName, _handleSlideMovement);
    window.addEventListener(mouseUpEventName, _mouseUpEventHandler);
  }

  removeExistingFaces() {
    const head = SVG(this.svg.findOne('#Head'));

    const existingFaces = head.node.querySelectorAll(`.${this.faceClassName}`);

    if (existingFaces) {
      existingFaces.forEach((ex) => ex.remove());
    }
  }

  applyPathToSvg(path, faceAngle = this.faceAngle) {
    const head = SVG(this.svg.findOne('#Head'));
    // Face stroke
    this.removeExistingFaces();

    const mult =
      faceAngle <= 50
        ? calcProportions(faceAngle, 0, 50, 0.8, 1)
        : calcProportions(faceAngle, 50, 100, 1.0, 0.8);
    const diffMult = 1.0 - mult;

    let calcTranslate = this.FACE_WIDTH * mult * diffMult;
    if (faceAngle < 50) {
      calcTranslate *= -1;
    }

    path.transform({ scaleX: mult, translateX: calcTranslate });
    head.add(path);
    path.back();
  }

  renderFace() {
    this.logger.debug('renderFace');

    const facePivotPoint = this.svg.findOne('#HeadPivotPoint');
    const { offsetX, offsetY } = getSubstractedCenterOffset(
      this.svg.node,
      facePivotPoint.node
    );
    this.originX = offsetX;
    this.originY = offsetY;

    const path = this.drawPath();
    const faceMask = SVG(this.svg.findOne('#FaceMask')) as G;

    faceMask.node.addEventListener(
      'touchstart',
      this.handleSlideStart.bind(this)
    );
    faceMask.node.addEventListener(
      'mousedown',
      this.handleSlideStart.bind(this)
    );

    this.applyPathToSvg(path);
    faceMask.front();
  }

  drawPath(faceAngle = this.faceAngle) {
    const border = {
      topLeft: 0,
      topRight: 0,
      bottomRight:
        (faceAngle < 0
          ? -faceAngle
          : this.FACE_BORDER_RADIUS_MULTIPLIER - faceAngle) / 1,
      bottomLeft:
        (this.faceAngle < 0
          ? this.FACE_BORDER_RADIUS_MULTIPLIER + faceAngle
          : faceAngle) / 1,
    };

    const gen = generatePathData(
      0,
      0,
      this.FACE_WIDTH,
      this.FACE_HEIGHT,
      border.topRight,
      border.bottomRight,
      border.bottomLeft,
      border.topLeft
    );
    const facePath = SVG().path(gen);

    // frame start //
    facePath.cx(this.originX / this.canvasScale);
    facePath.cy(this.originY / this.canvasScale - this.FACE_MARGIN_BOTTOM);

    facePath.attr({
      id: 'DrawnFace',
      stroke: 'black',
      'stroke-dasharray': '6 4',
      color: '#000',
      width: 2,
      fill: FACE_COLOR,
    });

    // frame end //

    // cross start //

    // Horizontal Line
    const verticalLine = SVG().line(
      this.FACE_WIDTH / 2,
      0,
      this.FACE_WIDTH / 2,
      this.FACE_HEIGHT
    );
    const minCrossLeft = -45;
    const maxCrossLeft = 45;
    const margin = calcProportions(
      faceAngle,
      0,
      100,
      minCrossLeft,
      maxCrossLeft
    );
    verticalLine.cx(this.originX / this.canvasScale + margin);
    verticalLine.cy(this.originY / this.canvasScale - this.FACE_MARGIN_BOTTOM);

    // Vertical Line
    const marginTop = -13;
    const horizontalLine = SVG().line(
      0,
      marginTop,
      this.FACE_WIDTH - 10,
      marginTop
    );
    horizontalLine.cx(this.originX / this.canvasScale);
    horizontalLine.cy(
      this.originY / this.canvasScale + marginTop - this.FACE_MARGIN_BOTTOM
    );

    // Group cross
    const crossGroup = SVG().group();
    crossGroup.add(verticalLine);
    crossGroup.add(horizontalLine);

    crossGroup.attr({
      stroke: 'black',
      'stroke-dasharray': '6 4',
      width: 2,
    });

    // cross end //

    // Grouping
    const facePathGroup = SVG().group();
    facePathGroup.add(facePath);
    facePathGroup.add(crossGroup);
    facePathGroup.node.classList.add(this.faceClassName);

    return facePathGroup;
  }
}
