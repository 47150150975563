import React from 'react';
import { HumanPresets } from './HumanPresets';
import { ObjectProperties } from './ObjectProperties';
import { useDispatch } from 'react-redux';
import { setObjectProperty } from '@editor/reducers/canvas.slice';
import { CanvasObjectTypes } from '@editor/utils/canvas.types';

export const ObjectInspectorInterface = ({ object }) => {
  const dispatch = useDispatch();

  const setHumanProperty = (property: string, value: any) => {
    dispatch(setObjectProperty(object.id, property, value));
  };

  return (
    <>
      <ObjectProperties object={object} setProperty={setHumanProperty} />
      {object.objectType === CanvasObjectTypes.HUMAN  && <HumanPresets object={object} />}
    </>
  );
};