import React from 'react';
import { Switch } from '@material-ui/core';
import useControls from '@editor/hooks/useControls';
import * as InspectorUI from '../InspectorUI';

export const CanvasInspectorInterface = () => {
  const { displayGrid, setDisplayGrid } = useControls();

  return (
    <InspectorUI.Section title="Canvas">
      <div className="px-4">
        <div className="grid grid-cols-6  h-10 items-center justify-center">
          <div className="col-span-2 text-sm">Grid</div>
          <div className="col-span-4 flex items-center justify-center">
            <Switch
              color="primary"
              checked={displayGrid}
              onClick={(e: React.ChangeEvent<any>) =>
                setDisplayGrid(e.target.checked)
              }
            />
          </div>
        </div>
      </div>
    </InspectorUI.Section>
  );
};
