import { CanvasSvgAssetObject } from '@editor/utils/canvas.types';
import styled from 'styled-components';
import cn from 'classnames';
import React from 'react';
import { useAsset } from './useAsset';
import { useObject } from '@editor/hooks/useObject';
import { ResizableContent } from './ResizableContent';
import InlineSVG from 'svg-inline-react';

const AssetContainer = styled.div<any>`
  .rect {
    border-radius: 4px;
    border: 1px solid transparent;
    transition: 0.4s border-color;

    .square {
      background: transparent;
      border: none;
      transition: 0.4s background-color;
      width: 18px;
      height: 18px;
    }

    .rotate {
      transform: scale(1.8);
      opacity: 0;
      transition: 0.4s opacity;
      margin-top: -4px;
    }
  }

  &:hover,
  &.controlled {
    &:not(.locked) {
      .rect {
        // Resize cursor
        cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAMAAABhEH5lAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAANVQTFRFAAAACgoKBgYGAAAACgoKAwMDAQEBCAgIBQUFAAAAAAAAFxcXCAgIAQEBBgYGCAgIBAQEAQEBAQEBAgICCgoKEBAQBQUFAgICBQUFDAwMAAAABwcHAAAAAQEBBgYGGRkZBQUFBAQEAQEBBAQEBQUFAgICAgICAAAAAwMDCAgIAAAAAgICAAAAAwMDBQUFAwMDAQEBAwMDBQUFCgoKCAgIBQUFCAgIDg4OAAAAAAAAAAAABAQEBAQEAwMDDg4OBQUFAwMDAAAABQUFAAAABgYGAwMDCwsLWkWURQAAAEd0Uk5TADJ5CRql4ERj+P8LQd+JQ4XP95xmEKDwOEIhZwTehR+mROOQM8/b+bRh/vP1r2Rk8spqGWYwqiUBMfKrjskkMcz0aANcqhgaDmZgAAAAs0lEQVR4nHXO1w6CQBAF0EUF3MGOW1RUwAKyKnaxd///kyQGE9fE+zQ5yZ0ZhJIoqTSSk1E1/YeyGFRDklweABckKpbKFbMqFwll/LtV+1C9Yb1vZZstQto20wlx3E7XQoqKoUep3fd8Sn0PegOU0gAqjDEvEIyJAIYjlNZVbHKu+2LMeTiZzubxMqOw+KxfrtbRvyfi1B1fhBuJFq4XbHcSWXuAw1Funs6XayTT7f54JuMLnvIQjMuAU0AAAAAASUVORK5CYII='),
          crosshair;
        border-color: #8c8c8c;

        .square {
          background: #4e4e4e;
        }

        .rotate {
          opacity: 1;
        }
      }
    }
  }
`;

export interface AssetProps {
  zIndex?: number;
  object: CanvasSvgAssetObject;
  controlled: boolean;
  locked: boolean;
  onSelected: Function;
}

export const Asset = ({
  zIndex = 0,
  object,
  controlled,
  locked,
  onSelected,
}: AssetProps) => {
  const { svg, aspectRatio, inlineSvg } = useAsset(
    object
  );

  const { setBulkObjectProperties } = useObject(object.id);
  const {
    position,
    rotateAngle,
    viewBoxHeight,
    viewBoxWidth,
  } = object.properties;

  if (!svg) {
    return null;
  }

  return (
    <AssetContainer
      zIndex={zIndex}
      className={cn('object-bounds', { controlled, locked })}
      onMouseDown={onSelected}
      onPointerDown={onSelected}
      onTouchStart={onSelected}
    >
      <ResizableContent
        locked={locked}
        defaultX={position.x}
        defaultY={position.y}
        defaultWidth={viewBoxWidth}
        defaultHeight={viewBoxHeight}
        defaultRotateAngle={rotateAngle}
        aspectRatio={aspectRatio}
        onResize={() => {}}
        onResizeEnd={({
          width: viewBoxWidth,
          height: viewBoxHeight,
          top: y,
          left: x,
        }) =>
          setBulkObjectProperties({
            viewBoxWidth,
            viewBoxHeight,
            position: { x, y },
          })
        }
        onRotateEnd={(rotateAngle) => setBulkObjectProperties({ rotateAngle })}
        onDragEnd={({ top: newY, left: newX }) => {
          if (position.x !== newX || position.y !== newY) {
            // Only commit to store if something actually changed
            setBulkObjectProperties({ position: { x: newX, y: newY } });
          }
        }}
      >
        <InlineSVG src={inlineSvg} />
      </ResizableContent>
    </AssetContainer>
  );
};
