import { selectPanZoomScale } from '@editor/reducers/panzoom.slice';
import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';

export const calculateObjectOffsetOnCanvas = (
  canvasEl,
  objectEl,
  scalePercentage
) => {
  const { left: objectX, top: objectY } = objectEl.getBoundingClientRect();
  const { top: canvasY, left: canvasX } = canvasEl.getBoundingClientRect();

  return {
    offsetX: (objectX - canvasX) / scalePercentage,
    offsetY: (objectY - canvasY) / scalePercentage,
  };
};

const DraggableOverride = (props) => {
  const draggableProps = {
    onStart: props.onStart,
    handle: props.handle,
  };
  const {
    position,
    onPositionChange,
    canvasWidth,
    canvasHeight,
    viewBoxWidth,
    viewBoxHeight,
    onDragStart,
    locked,
  } = props;
  const draggedRef = useRef<any>();
  const scale = useSelector(selectPanZoomScale);
  const [positionX, setPositionX] = useState(null);
  const [positionY, setPositionY] = useState(null);

  useEffect(() => {
    const getAbsoluteCenter = () => {
      return {
        x: canvasWidth / 2 - viewBoxWidth / 2,
        y: canvasHeight / 2 - viewBoxHeight / 2,
      };
    };

    if (!position) {
      const { x, y } = getAbsoluteCenter();
      setPositionX(x);
      setPositionY(y);
    } else {
      setPositionX(position.x);
      setPositionY(position.y);
    }
  }, [position, canvasWidth, viewBoxWidth, canvasHeight, viewBoxHeight]);

  const handleDragStart = (e) => {
    e.stopPropagation();
    onDragStart(e);
  };

  const handleDragStop = (_e, data) => {
    const { x, y } = data;
    setPositionX(x);
    setPositionY(y);
    onPositionChange({ x, y });
  };

  return (
    <Draggable
      {...draggableProps}
      onStart={handleDragStart}
      scale={scale}
      disabled={locked}
      position={{ x: positionX, y: positionY }}
      // defaultPosition={getObjectDefaultPosition()}
      onStop={handleDragStop}
    >
      <div ref={draggedRef}>{props.children}</div>
    </Draggable>
  );
};

export default DraggableOverride;
