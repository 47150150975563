import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import firebase from 'firebase/app';

import { useProjectContext } from '@common/context/project';
import { addProject, deleteProject, updateProject } from '../utils';
import { useProjects } from '../hooks/useProjects';
import { Project } from '@common/types/Project';

import { Modal, Button, Loader } from '@common/components';
import { ProjectCard, CreateProjectForm } from '../components';
import { usePermission } from '@common/hooks/usePermission';
import { useSubscriptionContext } from '@common/context/subscription/useSubscriptionContext';
import { AnalyticsEvents, useAnalytics } from '@common/analytics/analytics.util';

interface ProjectsContainerProps {
  user: firebase.User;
}

export const ProjectsContainer: FC<ProjectsContainerProps> = ({ user }) => {
  const { push } = useHistory();
  const { handleSubmit, register, errors } = useForm();
  const { logEvent } = useAnalytics();
  const { permissions } = useSubscriptionContext();
  const projectsLimitPermission = usePermission(permissions.PROJECTS_LIMIT);
  const displayVerifiyEmailAddressModal = usePermission(permissions.CAN_USE_APP)
    .displayModal;

  const [showModal, setShowModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const { projects, loading, error } = useProjects(user.uid);
  const { selectProject } = useProjectContext();

  logEvent(AnalyticsEvents.PROJECT_PAGE_VISIT);

  const handleNewProject = () => {
    logEvent(AnalyticsEvents.PROJECT_PAGE_CREATE_MODAL_SHOW);

    if (projects?.length >= projectsLimitPermission.value) {
      return projectsLimitPermission.displayModal();
    }
    if (!user.emailVerified) {
      return displayVerifiyEmailAddressModal(null, 'Verify your email address');
    }


    setShowModal(true);
  };

  const handleCreateNewProject = async ({
    projectName,
  }: {
    projectName: string;
  }) => {
    setAddLoading(true);
    logEvent(AnalyticsEvents.PROJECTS_PAGE_CREATE_MODAL_SUBMIT);
    const projectRef = await addProject(projectName, user.uid);
    const projectData = (await projectRef.get()).data() as Project;
    setShowModal(false);
    setAddLoading(false);
    selectProject(projectData);
    push(`canvas/${projectData?.canvasId}`);
  };

  const handleNameChange = async (name: string, projectId: string) => {
    try {
      await updateProject(projectId, {
        name,
      });
    } catch (e) {
      throw new Error(
        `Failed to update project (projectId: ${projectId}, name: ${name})`
      );
    }
  };

  const handleDelete = async (project) => {
    logEvent(AnalyticsEvents.PROJECTS_PAGE_DELETE_PROJECT);
    try {
      await deleteProject(project);
    } catch (e) {
      throw new Error(`Failed to delete project (projectId: ${project.id})`);
    }
  };

  const handleProjectSelection = (project: Project) => {
    selectProject(project);
    logEvent(AnalyticsEvents.PROJECTS_PAGE_SELECT_PROJECT);
    push(`/canvas/${project.canvasId}`);
  };

  const projectList = projects?.length ? (
    <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4">
      {projects?.map((project) => (
        <ProjectCard
          key={project.id}
          canvasId={project.canvasId}
          name={project.name}
          onClick={() => handleProjectSelection(project)}
          onNameChange={(name: string) => handleNameChange(name, project.id)}
          onDelete={() => handleDelete(project)}
        />
      ))}
    </div>
  ) : (
    <div className="m-auto text-center">
      {error ? (
        <>
          <span className="text-4xl" role="img" aria-label="Empty">
            👀
          </span>
          <p className="text-xl font-semibold">
            Something went wrong. <br />
            Please try again later
          </p>
        </>
      ) : (
        <>
          <span className="text-4xl" role="img" aria-label="Empty">
            👀
          </span>
          <p className="text-2xl font-semibold">You don't have any projects.</p>
          <Button
            color="primary"
            className="px-4 mt-4"
            onClick={handleNewProject}
          >
            Create your first project
          </Button>
        </>
      )}
    </div>
  );
  return (
    <>
      <Modal
        isOpen={showModal}
        title="Create a new project"
        onDismiss={() => setShowModal(false)}
      >
        <CreateProjectForm
          errors={errors}
          onSubmit={handleCreateNewProject}
          handleSubmit={handleSubmit}
          loading={addLoading}
          register={register}
        />
      </Modal>

      <div className="flex justify-between mb-4">
        <h1 className="text-primary-dark text-4xl font-medium mb-4">
          All Projects
        </h1>
        <div>
          <Button
            color="primary"
            className="px-4 w-40"
            onClick={handleNewProject}
          >
            + new project
          </Button>
        </div>
      </div>
      {loading ? (
        <Loader
          size="medium"
          color="primary"
          className="mt-12 flex justify-center"
        />
      ) : (
        projectList
      )}
    </>
  );
};
