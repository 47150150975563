import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TopBar } from './features/topbar/TopBar';
import { useProjectContext } from '../common/context/project';
import { Inspector } from './features/inspector/Inspector';
import { CanvasV2 } from './features/canvas/CanvasV2';
import { PanZoom } from './features/canvas/PanZoom/PanZoom';
import { useWindowSize } from './hooks/useWindowSize';
import { ExportContainer } from './features/export/ExportContainer';
import { useKeyboardShortcuts } from './hooks/useKeyboardShortcuts';
import { LinearProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import { CanvasStatsOverlay } from './CanvasStatsOverlay';
import { KnowledgeBaseContainer } from './features/knowledge-base/KnowledgeBaseContainer';

const Container = styled.div<any>`
  width: 100vw;
  height: ${(props) => props.height}px;
  position: relative;
  display: flex;
  flex-direction: column;
  touch-action: none;
`;

const EditorWrapper = styled.div`
  position: relative;
  background: #f5f5f5;
  z-index: 1;
  display: flex;

  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  &.capturing {
    #main-container,
    #topbar-container {
      display: none;
    }
  }
`;

export const EditorContainer = () => {
  const { currentProject } = useProjectContext();
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const [height, setHeight] = useState(null);
  const containerRef = useRef<any>();
  useKeyboardShortcuts();

  useEffect(() => {
    const [width, height] = windowSize;
    if (!width || !height) {
      return;
    }

    setHeight(height);
  }, [windowSize]);

  useEffect(() => {
    // Clear canvas history when leaving editor
    return () => dispatch(ActionCreators.clearHistory());
  }, [dispatch]);

  const isLoading = !height || !currentProject;

  useEffect(() => {
    if (containerRef.current && !isLoading) {
      const disableGestures = containerRef.current.querySelectorAll('.disable-gestures')

      disableGestures.forEach(el => el.addEventListener('gesturestart', (e) => {
        e.preventDefault();
      }))
    }
  }, [containerRef, isLoading]);


  return (
    <Container ref={containerRef} height={height}>
      <TopBar title={currentProject?.name} />
      {isLoading && <LinearProgress color="primary" />}

      {!isLoading && (
        <>
          <EditorWrapper id="editor">
            <Inspector />
            <PanZoom height={height}>
              <CanvasV2 />
            </PanZoom>
          </EditorWrapper>
          <ExportContainer />
          <CanvasStatsOverlay />
          <KnowledgeBaseContainer />
        </>
      )}
    </Container>
  );
};
