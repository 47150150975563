import React, { FC, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';

import { IoCheckmarkCircle as CheckmarkIcon } from 'react-icons/all';

import { ToastContext, Toast } from './ToastContext';

const iconsMap = {
  success: CheckmarkIcon,
};

export const ToastProvider: FC = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = useCallback(({ type, message, title }: Toast) => {
    const createdToast: Toast = {
      title,
      type,
      icon: iconsMap?.[type],
      message,
    };
    setToasts((prev) => [...prev, createdToast]);
  }, []);

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => {
        return setToasts((toasts) => toasts.slice(1));
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [toasts]);

  const contextValue = {
    addToast,
  };

  return (
    <ToastContext.Provider value={contextValue}>
      <Transition
        show={toasts.length > 0}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed inset-x-0 flex justify-center flex-col items-center px-4 py-6 pointer-events-none z-40"
      >
        {toasts.map(({ title, message = '', icon: Icon, type }) => (
          <div
            key={title}
            className={classNames(
              `max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden mb-2`,
              {
                'bg-warning text-white': type === 'warning',
                'bg-primary text-white': type === 'success',
              }
            )}
          >
            <div className="p-4">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  {Icon && (
                    <Icon
                      size="2em"
                      className={classNames(
                        `max-w-sm w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden`,
                        {
                          'text-black': type === 'warning',
                        }
                      )}
                    />
                  )}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium">{title}</p>
                  <p className="mt-1 text-sm">{message}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Transition>

      {children}
    </ToastContext.Provider>
  );
};
