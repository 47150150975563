import { CANVAS_PADDING } from '@editor/utils/editor.constants';

export const calcDimensions = (canvasWidth: number, canvasHeight: number) => {
  const windowDimensions = {
    width: window.innerWidth - CANVAS_PADDING,
    height: window.innerHeight - CANVAS_PADDING,
  };

  let widthDiff = windowDimensions.width - canvasWidth;
  let heightDiff = windowDimensions.height - canvasHeight;

  if (widthDiff < 0 || heightDiff < 0) {
    widthDiff = widthDiff * -1;
    heightDiff = heightDiff * -1;
    const widthRatio = windowDimensions.width / canvasWidth;
    const heightRatio = windowDimensions.height / canvasHeight;
    const lowestRatio = widthRatio > heightRatio ? heightRatio : widthRatio;
    return lowestRatio;
  } else {
    return 1;
  }
};