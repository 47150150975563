import { createLogger } from '@common/utils/logger';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

const SENTRY_ENABLED = process.env.REACT_APP_SENTRY_ENABLED;
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

const logger = createLogger('Sentry');

export const setupSentry = () => {
  if (!SENTRY_ENABLED || SENTRY_ENABLED === 'false') {
    logger.debug('Sentry disabled');
    return;
  }

  if (!SENTRY_DSN) {
    logger.error('Sentry DSN not provided');
    throw new Error('Sentry DSN not provided');
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsole({
        levels: ['error'],
      })
    ],
    tracesSampleRate: 1.0,
  });

  logger.debug('Sentry initialized');
};
