import { useCallback } from 'react';
import { useStripeSubscription } from './useStripeSubscription';
import { usePaypalSubscription } from './usePaypalSubscription';
import { PaymentProviders } from '../types';

export function useSubscriptions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/subscription`;
  const stripe = useStripeSubscription();
  const paypal = usePaypalSubscription();

  function subscribe(provider: PaymentProviders, args) {
    switch (provider) {
      case PaymentProviders.PAYPAL:
        return paypal.subscribe(args);
      case PaymentProviders.STRIPE:
        return stripe.subscribe(args);
    }
  }

  const getSubscriptionById = useCallback(
    (provider: PaymentProviders, id: string) => {
      return fetch(`${baseUrl}/${provider.toLowerCase()}/${id}`, {
        method: 'get',
      }).then((res) => res.json());
    },
    [baseUrl]
  );

  function getCouponByCode(couponCode: string) {
    return fetch(`${baseUrl}/validate-coupon`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ couponCode }),
    })
      .then((res) => res.json())
      .then((promo) => promo?.coupon || promo);
  }

  function cancelSubscription(provider: PaymentProviders, userId: string) {
    return fetch(`${baseUrl}/${provider.toLowerCase()}/cancel-subscription`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ userId }),
    });
  }

  function undoCancelSubscription(provider: PaymentProviders, userId: string) {
    return fetch(`${baseUrl}/${provider.toLowerCase()}/undo-cancel-subscription`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ userId }),
    });
  }

  return {
    subscribe,
    getCouponByCode,
    getSubscriptionById,
    cancelSubscription,
    changePaymentMethod: stripe.changePaymentMethod,
    undoCancelSubscription,
  };
}
