import React from 'react';
import { Modal } from '@common/components';
import { ChangePasswordForm } from './ChangePasswordForm';

export const ChangePasswordModal = ({
  onSubmit,
  loading,
  error,
  modalOpen,
  onDismiss,
}) => (
  <Modal
    className="w-1/3 md:w-1/2 lg:w-1/3"
    background="white"
    title="Change your password"
    isOpen={modalOpen}
    onDismiss={onDismiss}
  >
    <ChangePasswordForm
      onSubmit={onSubmit}
      loading={loading}
      error={error}
    />
  </Modal>
);
