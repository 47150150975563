import { css } from 'styled-components';
import { HumanSkeleton } from './engine/HumanSkeleton';

const hideSupports = () => {
  const selectors = HumanSkeleton.supportSelectors.join(',\n');

  return css`
    ${selectors} {
      visibility: hidden;
    }
  `;
};

hideSupports();

export const HumanV2Style = css`
  position: absolute;
  pointer-events: none;

  svg {
    pointer-events: fill;
    ${hideSupports()}
  }

  svg.canvas-object {
  }

  svg * {
    pointer-events: all;
  }

  #ArmSpanArc,
  #LowerBodyArc {
    transition: 0.2s opacity;
    opacity: 0.5;
  }

  .hoverable {
    transition: 0.2s fill;

    &:not(#ArmSpanRotateHandle):not(#LowerBodyRotateHandle):not(#UpperBodyRotateHandle) {
      fill: transparent;
    }
  }

  svg {
    pointer-events: none;
  }

  &:not(.locked) {
    .highlight {
      cursor: pointer;

      &:not(#ArmSpanArc):not(#ArmSpanRotateHandle):not(#LowerBodyArc):not(#LowerBodyRotateHandle):not(#UpperBodyRotateHandle) {
        fill: #bcc7d0 !important;
        opacity: 0.7;
      }

      &#ArmSpanRotateHandle,
      &#LowerBodyRotateHandle,
      &#UpperBodyRotateHandle {
        fill: #627789;
      }

      &#ArmSpanArc,
      &#LowerBodyArc {
        opacity: 1;
        stroke-width: 2;
      }
    }

    svg {
      /* Hovers */
      #FaceMask:hover {
        cursor: crosshair;
      }

      #Hand {
        cursor: pointer;
      }

      #Chest {
        pointer-events: fill;
        cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAMAAABhEH5lAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAANVQTFRFAAAACgoKBgYGAAAACgoKAwMDAQEBCAgIBQUFAAAAAAAAFxcXCAgIAQEBBgYGCAgIBAQEAQEBAQEBAgICCgoKEBAQBQUFAgICBQUFDAwMAAAABwcHAAAAAQEBBgYGGRkZBQUFBAQEAQEBBAQEBQUFAgICAgICAAAAAwMDCAgIAAAAAgICAAAAAwMDBQUFAwMDAQEBAwMDBQUFCgoKCAgIBQUFCAgIDg4OAAAAAAAAAAAABAQEBAQEAwMDDg4OBQUFAwMDAAAABQUFAAAABgYGAwMDCwsLWkWURQAAAEd0Uk5TADJ5CRql4ERj+P8LQd+JQ4XP95xmEKDwOEIhZwTehR+mROOQM8/b+bRh/vP1r2Rk8spqGWYwqiUBMfKrjskkMcz0aANcqhgaDmZgAAAAs0lEQVR4nHXO1w6CQBAF0EUF3MGOW1RUwAKyKnaxd///kyQGE9fE+zQ5yZ0ZhJIoqTSSk1E1/YeyGFRDklweABckKpbKFbMqFwll/LtV+1C9Yb1vZZstQto20wlx3E7XQoqKoUep3fd8Sn0PegOU0gAqjDEvEIyJAIYjlNZVbHKu+2LMeTiZzubxMqOw+KxfrtbRvyfi1B1fhBuJFq4XbHcSWXuAw1Funs6XayTT7f54JuMLnvIQjMuAU0AAAAAASUVORK5CYII='),
          crosshair;
      }
    }
  }
`;
