import styled from 'styled-components';

export interface KnowledgeBaseTopic {
  id: string;
  label: string;
  premium: boolean;
  Content: Function,
}

export const DemoGIF = styled.img`
  margin: auto;
  border-radius: 8px;
  width: ${props => props.width || 400}px;
`;