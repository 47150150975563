export const getOriginOffset = (childSvg: Element, parentSvg: SVGElement) => {
  const { left, top, width, height } = childSvg.getBoundingClientRect();
  const { left: excessX, top: excessY } = parentSvg.getBoundingClientRect();

  const offset = {
    x: left - excessX + width / 2,
    y: top - excessY + height / 2,
  };

  return offset;
};

export const calculateAngle = (
  clientX: number,
  clientY: number,
  mouseStartAngle: number,
  elementStartAngle: number,
  offset: { x: number, y: number },
  svgElement: Element,
  isFlippedHorizontally: boolean,
) => {
  const {
    left: excessX,
    top: excessY,
  } = (svgElement as SVGElement).getBoundingClientRect();

  const xFromCenter = clientX - excessX - offset.x;
  const yFromCenter = clientY - excessY - offset.y;
  const mouseAngle = Math.atan2(yFromCenter, xFromCenter);

  if (isFlippedHorizontally) {
    return -(mouseAngle - mouseStartAngle + -elementStartAngle);
  } else {
    return mouseAngle - mouseStartAngle + elementStartAngle;
  }
};

export const radToDeg = (radians: number) => {
	return radians * 180 / Math.PI;
};