import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const SVGContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #efefef;
  padding: 12px;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  svg {
    stroke-dasharray: 3 3;
  }
`;

export const HandThumbnail = ({ svgString, style, onClick }) => {
  const svgRef = useRef();

  useEffect(() => {
    if (svgRef.current) {
      const el = svgRef.current as SVGElement;
      el.innerHTML = svgString;
      const svgEl = el.querySelector('svg');
      const pivotPointEl = svgEl.querySelector('#HandPivotPoint');
      pivotPointEl.remove();
    }
  });

  return <SVGContainer ref={svgRef} style={style} onClick={onClick} />;
};
