import React, { useEffect } from 'react';
import { Modal } from '@common/components';
import styled from 'styled-components';

import * as hands from './hands';
import { HandThumbnail } from './HandThumbnail';
import { AnalyticsEvents, useProjectAnalytics } from '@common/analytics/analytics.util';

const THUMBNAIL_SIZE = 100;

const ThumbnailsContainer = styled.div`
  width: ${THUMBNAIL_SIZE * 3}px;
  max-height: ${(THUMBNAIL_SIZE * 3) + THUMBNAIL_SIZE / 2}px;

  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  ::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
  }
`;

export const HandSelectionModal = ({
  open,
  onClose,
  onHandFlipped,
  onHandSelected,
  flipped,
}) => {
  const { logEvent } = useProjectAnalytics();

  useEffect(() => {
    logEvent(AnalyticsEvents.EDITOR_SET_HUMAN_HAND_MODAL_OPEN);
  }, [logEvent]);

  const renderHands = () => {
    return Object.entries(hands).map(([name, hand]) => {
      return (
        <HandThumbnail
          key={name}
          style={{ width: THUMBNAIL_SIZE, height: THUMBNAIL_SIZE }}
          svgString={hand.svg}
          onClick={() => {
            logEvent(AnalyticsEvents.EDITOR_SET_HUMAN_HAND, { handName: name });
            onHandSelected(name);
          }}
        />
      );
    });
  };

  return (
    <Modal
      isOpen={open}
      title="Select a hand"
      onDismiss={onClose}
      contentPadding={false}
    >
      <ThumbnailsContainer
        className="flex flex-wrap content-start overflow-hidden overflow-y-auto"
      >
        {renderHands()}
      </ThumbnailsContainer>
      <div className="bg-white text-center py-2 rounded-b-xl">
        <input
          id="hand-flip-checkbox"
          type="checkbox"
          checked={flipped}
          onChange={e => onHandFlipped(e.target.checked)}
        />
        <label className="ml-1" htmlFor="hand-flip-checkbox">Flip hand</label>
      </div>
    </Modal>
  );
};
