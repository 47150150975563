export interface CustomMouseEvent extends MouseEvent {
  data: any;
}

export interface SimplifiedEvent {
  type: 'touch' | 'mouse';
  clientX: number;
  clientY: number;
  pageX: number;
  pageY: number;
  stylus: boolean;
  multiTouch: boolean;
}

export const simplifyEvent = (e: MouseEvent | TouchEvent): SimplifiedEvent => {
  const isTouchEvent = !!(e as TouchEvent).touches;

  if (isTouchEvent) {
    const touches = (e as TouchEvent).touches;
    const first = touches[0];

    const stylus = first.touchType === 'stylus';

    return {
      clientX: first.clientX,
      clientY: first.clientY,
      pageX: first.pageX,
      pageY: first.pageY,
      type: 'touch',
      stylus,
      multiTouch: touches.length > 1,
    };
  } else {
    const { clientX, clientY, pageX, pageY } = (e as MouseEvent);
    return { clientX, clientY, type: 'mouse', stylus: false, multiTouch: false, pageX, pageY };
  }
}

function touchHandler(event, ignoreMultiTouch)
{
    var touches = event.changedTouches,
        first = touches[0],
        type = "";


    if (ignoreMultiTouch && touches.length > 1) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    switch(event.type)
    {
        case "touchstart": type = "mousedown"; break;
        case "touchmove":  type = "mousemove"; break;        
        case "touchend":   type = "mouseup";   break;
        default:           return;
    }

    const ev = new CustomEvent(type, {
      ...event,
      bubbles: true,
      screenX: first.screenX,
      screenY: first.screenY,
      clientX: first.clientX,
      clientY: first.clientY,
    });

    first.target.dispatchEvent(ev);
    event.preventDefault();
    event.stopPropagation();
}

export function mapTouchToMouse(elem, ignoreMultiTouch = false) 
{
    elem.addEventListener("touchstart", e => touchHandler(e, ignoreMultiTouch), true);
    elem.addEventListener("touchmove", e => touchHandler(e, ignoreMultiTouch), true);
    elem.addEventListener("touchend", e => touchHandler(e, ignoreMultiTouch), true);
    elem.addEventListener("touchcancel", e => touchHandler(e, ignoreMultiTouch), true);

    // elem.addEventListener("pointerdown", pointerHandler, true);
    // elem.addEventListener("pointermove", pointerHandler, true);
    // elem.addEventListener("pointerup", pointerHandler, true);
    // elem.addEventListener("pointercancel", pointerHandler, true);
}

export const isStylusEvent = (e) => {
  if (e.touches && e.touches[0]?.touchType === 'stylus') {
    return true;
  }

  return false;
};

export const isTouchEvent = (e) => {
  return !!e.touches && e.touches[0]?.touchType !== 'styled';
}

export const getTouchFingersAmount = (e) => {
  return e.touches.length;
}