import React from 'react';
import styled from 'styled-components';
import { BiUndo as UndoIcon, BiRedo as RedoIcon } from 'react-icons/bi';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import cn from 'classnames';
import { Tooltip } from '@material-ui/core';

const OverlayContainer = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 0;
  margin-left: 30px;
  margin-bottom: 30px;
  color: white;
  font-size: 18px;
`;

const RedoUndo = () => {
  const dispatch = useDispatch();
  const canvasPast = useSelector((state: RootStateOrAny) => state.canvas.past);
  const canvasFuture = useSelector(
    (state: RootStateOrAny) => state.canvas.future
  );

  const undoDisabled = canvasPast.length === 0;
  const redoDisabled = canvasFuture.length === 0;

  const handleUndo = () => !undoDisabled && dispatch(ActionCreators.undo());
  const handleRedo = () => !redoDisabled && dispatch(ActionCreators.redo());

  const undoButtonStyle = {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    color: undoDisabled
      ? 'rgba(255, 255, 255, 0.5)'
      : 'rgba(255, 255 ,255, 0.8)',
  };

  const redoButtonStyle = {
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    color: redoDisabled
      ? 'rgba(255, 255, 255, 0.5)'
      : 'rgba(255, 255 ,255, 0.8)',
  };

  return (
    <div className="relative z-0 block">
        <Tooltip placement="right" title="Undo">
          <button
            onClick={handleUndo}
            type="button"
            className={cn(
              'bg-black flex items-center p-2 border border-transparent rounded-full shadow-sm focus:outline-none',
              undoDisabled
                ? 'bg-opacity-30 cursor-not-allowed'
                : 'hover:bg-opacity-70 bg-opacity-50'
            )}
          >
            <UndoIcon size={22} color={undoButtonStyle.color} />
          </button>
        </Tooltip>
      <Tooltip placement="right" title="Redo">
        <button
          onClick={handleRedo}
          type="button"
          className={cn(
            'bg-black flex items-center mt-2 p-2 border border-transparent rounded-full shadow-sm focus:outline-none',
            redoDisabled
              ? 'bg-opacity-30 cursor-not-allowed'
              : 'hover:bg-opacity-70 bg-opacity-50'
          )}
        >
          <RedoIcon size={22} color={redoButtonStyle.color} />
        </button>
        </Tooltip>
      </div>
  );
};

export const CanvasStatsOverlay = () => {
  return (
    <OverlayContainer>
      <RedoUndo />
    </OverlayContainer>
  );
};
