import React from 'react';
import { Switch } from '@material-ui/core';
import { Modal } from '@common/components';

export const FootSelectionModal = ({
  open,
  onClose,
  onFootFlipped,
  flipped,
}) => {
  return (
    <Modal isOpen={true} title="Foot options" onDismiss={onClose}>
      <div className="grid grid-cols-4 h-10 items-center justify-center">
        <div className="col-span-2">Flip foot</div>
        <div className="col-span-1 flex items-center justify-center">
          <Switch
            color="primary"
            checked={flipped}
            onClick={(e: React.ChangeEvent<any>) =>
              onFootFlipped(e.target.checked)
            }
          />
        </div>
      </div>
    </Modal>
  );
};
