import React, { FC } from 'react';
import { Button } from './Button';

import GoogleIcon from '../../assets/social/google_icon.svg';

interface GoogleButtonProps {
  className?: string;
  label?: string;
  onClick?: () => void;
}

export const GoogleButton: FC<GoogleButtonProps> = ({
  label,
  className,
  onClick,
}) => {
  return (
    <div className={className}>
      <Button
        color="secondary"
        className="text-gray-500 shadow-md w-full rounded-full"
        shape="block"
        size="large"
        onClick={onClick}
      >
        <div className="flex items-center">
          <img src={GoogleIcon} alt="google" />
          <span className="ml-4">{label}</span>
        </div>
      </Button>
    </div>
  );
};
