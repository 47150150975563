import { useEffect, MutableRefObject } from 'react';

export const useOutsideClickDetection = (
  ref: MutableRefObject<HTMLDivElement | null>,
  outsideClickCallback: () => void
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(e: any) {
      if (ref.current && !ref?.current.contains(e?.target)) {
        outsideClickCallback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, outsideClickCallback]);
};
