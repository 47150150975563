import React, { useState } from 'react';
import styled from 'styled-components';
import * as EditorConstants from '@editor/utils/editor.constants';
import Tooltip from '@material-ui/core/Tooltip';

import { MdAdd as AddObjectIcon } from 'react-icons/md';
import { RiMenu3Line as MenuIcon } from 'react-icons/ri';
import { BsDownload as ExportIcon } from 'react-icons/bs';
import { AiOutlineQuestionCircle as KnowledgeBaseIcon } from 'react-icons/ai';
import { AddObjectMenu } from './AddObjectMenu';
import { SandwichMenu } from './SandwichMenu';
import { ExportMenu } from './ExportMenu';
import { useCanvasV2 } from '@editor/hooks/useCanvasV2';
import { useKnowledgeBase } from '../knowledge-base/useKnowledgeBase';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';

const TopBarContainer = styled.div`
  position: relative;
  background: #f8f8f8;
  width: 100%;
  height: ${EditorConstants.TOPBAR_HEIGHT}px;
  display: flex;
  align-items: center;
  z-index: 102;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  * {
    outline: none !important;
  }
`;

const TopBarSection = styled.div<any>`
  flex-grow: 1;
  display: flex;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  padding: 8px;
`;

const ProjectTitle = styled.div`
  flex-grow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 14px;
    margin-top: 4px;
  }
`;

const TopBarItem = styled.div`
  padding: 6px;
  border-radius: 10px;
`;

const TopBarButton = styled.button<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 10px;
  background: ${(props) => props.background || 'transparent'};
  outline: none !important;

  &:hover {
    opacity: 0.7;
  }
`;

const BackSection = styled.div`
  background: #eeeeee;
  height: 100%;
  width: ${EditorConstants.TOPBAR_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  * {
    outline: none !important;
  }

  &:hover {
    cursor: pointer;
    background: #d3d3d3;
  }
`;

export const TopBar = ({ title }) => {
  const [mainMenuAnchorEl, setMainMenuAnchorEl] = useState(null);
  const [addObjectMenuAnchorEl, setAddObjectMenuAnchorEl] = useState(null);
  const [exportMenuAnchorEl, setExportMenuAnchorEl] = useState(null);
  const { disabled, disableReason } = useCanvasV2();
  const { setIsKnowledgeBaseOpen } = useKnowledgeBase();

  return (
    <TopBarContainer className="disable-gestures">
      <AddObjectMenu
        keepMounted
        open={!!addObjectMenuAnchorEl}
        anchorEl={addObjectMenuAnchorEl}
        onClose={() => setAddObjectMenuAnchorEl(null)}
        style={{ marginTop: 50 }}
      />
      <SandwichMenu
        anchorEl={mainMenuAnchorEl}
        keepMounted
        open={!!mainMenuAnchorEl}
        onClose={() => setMainMenuAnchorEl(null)}
        style={{ marginTop: 50 }}
      />
      <ExportMenu
        anchorEl={exportMenuAnchorEl}
        keepMounted
        open={!!exportMenuAnchorEl}
        onClose={() => setExportMenuAnchorEl(null)}
        style={{ marginTop: 50 }}
      />
      <BackSection onClick={(e) => setMainMenuAnchorEl(e.currentTarget)}>
        {/* Back to Projects */}
        <TopBarItem>
          <MenuIcon size={20} />
        </TopBarItem>
      </BackSection>
      <TopBarSection>
        {/* Add Object */}
        <Tooltip title="Add Object">
          <TopBarItem
            onClick={(e) => setAddObjectMenuAnchorEl(e.currentTarget)}
          >
            <TopBarButton background="#5861f1">
              <AddObjectIcon size={18} color={'white'} />
            </TopBarButton>
          </TopBarItem>
        </Tooltip>
      </TopBarSection>

      <ProjectTitle>
        {!disabled ? (
          <h1>{title}</h1>
        ) : (
          <span className="text-red-500 text-bold">
            {disableReason}. This canvas is in read-only mode. Please contact
            support.
          </span>
        )}
      </ProjectTitle>

      <TopBarSection reverse>
        {/* Export */}
        <Tooltip title="Export">
          <TopBarItem onClick={(e) => setExportMenuAnchorEl(e.currentTarget)}>
            <TopBarButton background="#5861f1">
              <ExportIcon size={16} color={'white'} />
            </TopBarButton>
          </TopBarItem>
        </Tooltip>
        {/* Knowledge Base */}
        <Tooltip title="Knowledge Base">
          <TopBarItem onClick={() => setIsKnowledgeBaseOpen(true)}>
            <TopBarButton background="transparent">
              <KnowledgeBaseIcon
                style={{ marginTop: -3 }}
                size={26}
                color={'#7a7a7a'}
              />
            </TopBarButton>
          </TopBarItem>
        </Tooltip>
        {/* */}
        <TopBarItem className="cursor-pointer">
          <Link to="/contact" target="_blank">
            <Chip
              style={{ marginTop: 2 }}
              label="REPORT A PROBLEM"
            />
          </Link>
        </TopBarItem>
        <TopBarItem>
          <Chip style={{ marginLeft: 12, marginTop: 2 }} label="BETA" />
        </TopBarItem>
      </TopBarSection>
    </TopBarContainer>
  );
};
