class CanvasObjectEventBus {
  observables = new Map();

  addObservable(id: string, obs) {
    this.observables.set(id, obs);
  }

  getObservable(id: string) {
    return this.observables.get(id);
  }

  removeObservable(id: string) {
    this.observables.delete(id);
  }
}

const eventBus = new CanvasObjectEventBus();

export default eventBus;