import { selectObjectProperty } from '@editor/reducers/canvas.slice';
import { CanvasUserAssetObject } from '@editor/utils/canvas.types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Subject } from 'rxjs';
import CanvasEventBus from '../../../../../common/services/human-events.service';

export const useUserAsset = (object: CanvasUserAssetObject) => {
  const { properties, id } = object;
  const flipHorizontally = useSelector(
    selectObjectProperty(id, 'flipHorizontally')
  );
  const opacityFromState = useSelector(selectObjectProperty(id, 'opacity'));
  const [opacity, setOpacity] = useState(opacityFromState);
  const defaultWidth = properties.viewBoxWidth;
  const defaultHeight = properties.viewBoxHeight;
  const [width, setWidth] = useState(defaultWidth);
  const [height, setHeight] = useState(defaultHeight);

  // useEffect(() => {
  //   dispatchFetchAssetbyId(assetId);
  // }, [dispatchFetchAssetbyId, assetId]);

  useEffect(() => {
    // assetSvg.width('100%');
    // assetSvg.height('100%');

    if (flipHorizontally) {
      // assetGroup.transform({ ...assetGroup.transform, flip: 'x' });
    } else {
      // assetGroup.transform({ ...assetGroup.transform, flip: null });
    }

    // assetSvg.opacity(opacity);
  }, [width, height, flipHorizontally, opacity]);

  useEffect(() => {
    const setupAssetEventBus = () => {
      const opacity$ = new Subject();
      CanvasEventBus.addObservable(`${object.id}_opacity`, opacity$);
      const opacitySubscription = opacity$.subscribe((newOpacity: number) =>
        setOpacity(newOpacity)
      );

      return () => {
        opacitySubscription.unsubscribe();
        CanvasEventBus.removeObservable(`${object.id}_opacity`);
      };
    };

    const cleanup = setupAssetEventBus();
    return () => cleanup();
  }, [object.id]);

  useEffect(() => {
    setOpacity(opacityFromState);
  }, [opacityFromState]);

  return {
    width,
    height,
    setWidth,
    setHeight,
    aspectRatio: width / height,
    opacity
  };
};
