export const Foot1 = {
  svg: `
<svg width="143" height="61" viewBox="0 0 143 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Foot1">
<g id="FootGroup">
<path id="Foot" d="M115.8 58.9361H15.5891C-3.8762 53.6416 2.20342 46.1529 10.4266 41.4712C13.7413 39.5841 17.4809 38.6495 21.2355 37.9778C31.1764 36.1992 46.6577 33.3161 57.1037 30.9893C69.8491 28.1502 95.8033 17.6848 100.669 12.0879C105.534 6.49093 103.206 9.19746 106.779 4.98218C111.244 -0.286926 130.445 2.03506 134.375 7.66139C137.518 12.1625 139.257 17.4554 139.733 19.5392L140.378 38.9741C140.378 56.0083 123.992 59.3797 115.8 58.9361Z" stroke="black"/>
<circle id="FootPivotPoint" cx="120.76" cy="12.5774" r="1.8508" fill="#C4C4C4"/>
</g>
</g>
</svg>  
`,
};