import { Button, Input, Loader } from '@common/components';
import React, { useState } from 'react';

export const PreviewUploadView = ({ base64, onConfirm, isUploading }) => {
  const [assetName, setAssetName] = useState('');
  return (
    <>
      <div style={{ height: 300, width: 400 }} className="flex flex-col">
        {/* Asset Name */}
        <div className="mb-2">
          <h1>Choose a name for your new asset</h1>

          <Input
            type="text"
            placeholder="e.g Table"
            error={false}
            onChange={({ target }) => setAssetName(target.value)}
          />
        </div>

        <div className="m-auto flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
          <img
            src={base64}
            className="w-full h-full object-center object-cover m-auto"
            alt="preview"
          />
        </div>

        <div
          className="mt-auto"
          style={{ display: 'flex', justifyContent: 'flex-center' }}>
          <Button
            disabled={!assetName.length || isUploading}
            color="primary text-white"
            className="px-4"
            onClick={() => onConfirm(assetName)}>
            {isUploading ? (
              <div className="flex gap-4">
                <div>Uploading..</div>
                <Loader />
              </div>
            ) : (
              <>Confirm Upload</>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
