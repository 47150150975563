import { Canvas } from '@editor/utils/canvas.types';
import { FirebaseSaveDriver } from './saveDrivers/firebase.driver';
import firebase from 'firebase/app';
import { createLogger } from '@common/utils/logger';
import shortid from 'shortid';
import { CANVAS_ASSETS_URL } from '../../config/firebaseStorage';
// import { LocalStorageSaveDriver } from './saveDrivers/localstorage.driver';

const logger = createLogger('CanvasService');

const saveDriver = new FirebaseSaveDriver();
// const saveDriver = new LocalStorageSaveDriver();

export async function saveCanvas(canvas: Canvas) {
  const saveObject = {
    ...canvas,
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  saveDriver.save(saveObject);
}

const getFileExtension = (type: string) => {
  switch(type) {
    case 'image/png': return 'png';
    case 'image/jpeg': return 'jpeg';
  }
};

export async function uploadImageToCanvasAssets(file: File, base64: string, canvasId: string) {
  const ext = getFileExtension(file.type);
  const assetId = shortid();

  const blob = await (await fetch(base64)).blob();
  const bucketObjectUrl = `${canvasId}/${assetId}.${ext}`;

  logger.debug(`uploadImageToCanvas (bucketObjectUrl: ${CANVAS_ASSETS_URL}/${bucketObjectUrl})`);

  const ref = firebase.app().storage(CANVAS_ASSETS_URL).ref(bucketObjectUrl);
  await ref.put(blob);

  return {
    bucketObjectUrl,
    assetId,
    assetType: 'IMAGE',
  };
}