import { useAuthContext } from '@common/context';
import { useProjectContext } from '@common/context/project';
import firebase from 'firebase/app';

export enum AnalyticsEvents {
  // Plans
  CHOOSE_PLAN_BUSINESS_INTEREST = 'CHOOSE_PLAN_BUSINESS_INTEREST',

  // Sign up/sign in
  SIGN_IN_SSO_START = 'SIGN_UP_SSO_START',
  SIGN_UP_PAGE_VISIT = 'SIGN_UP_PAGE_VISIT',
  SIGN_UP_PASSWORD_SUBMIT = 'SIGN_UP_PASSWORD_SUBMIT',

  // Projects page
  PROJECT_PAGE_VISIT = 'PROJECT_PAGE_VISIT',
  PROJECT_PAGE_CREATE_MODAL_SHOW = 'PROJECT_PAGE_CREATE_MODAL_SHOW',
  PROJECTS_PAGE_CREATE_MODAL_SUBMIT = 'PROJECTS_PAGE_CREATE_MODAL_SUBMIT',
  PROJECTS_PAGE_SELECT_PROJECT = 'PROJECTS_PAGE_SELECT_PROJECT',
  PROJECTS_PAGE_DELETE_PROJECT = 'PROJECTS_PAGE_DELETE_PROJECT',

  // Editor
  EDITOR_ENTER = 'EDITOR_ENTER',

  // Human
  EDITOR_APPLY_HUMAN_PRESET = 'EDITOR_APPLY_HUMAN_PRESET',
  EDITOR_ADD_HUMAN_ATTEMPT = 'EDITOR_ADD_HUMAN_ATTEMPT',
  EDITOR_ADD_HUMAN = 'EDITOR_ADD_HUMAN',
  EDITOR_SET_HUMAN_HAND_MODAL_OPEN = 'EDITOR_SET_HUMAN_HAND_MODAL_OPEN',
  EDITOR_SET_HUMAN_HAND = 'EDITOR_SET_HUMAN_HAND',

  // Library Asset
  EDITOR_ADD_ASSET_MODAL_OPEN = 'EDITOR_ADD_ASSET_MODAL_OPEN',
  EDITOR_ADD_ASSET = 'EDITOR_ADD_ASSET',

  // User Asset
  EDITOR_ADD_CUSTOM_ASSET_MODAL_OPEN = 'EDITOR_ADD_CUSTOM_ASSET_MODAL_OPEN',
  EDITOR_ADD_CUSTOM_ASSET = 'EDITOR_ADD_CUSTOM_ASSET',

  // Upgrade modals
  UPGRADE_MODAL_DISPLAY = 'UPGRADE_MODAL_DISPLAY',
  UPGRADE_MODAL_OUTCOME = 'UPGRADE_MODAL_OUTCOME',
  UPGRADE_MODAL_CONFIRM = 'UPGRADE_MODAL_CONFIRM',
  UPGRADE_MODAL_DECLINE = 'UPGRADE_MODAL_DECLINE',

  // Subscription
  SUBSCRIPTION_FLOW_START = 'SUBSCRIPTION_FLOW_START',
  SUBSCRIPTION_FLOW_SUCCESS = 'SUBSCRIPTION_FLOW_SUCCESS',
}

export const useAnalytics = (baseParams = {}) => {
  const analytics = firebase.analytics();
  const { logEvent, setCurrentScreen } = analytics;

  return {
    logEvent: (event: AnalyticsEvents, params = {}) =>
      logEvent(event, { ...baseParams, params }),
    setCurrentScreen,
  };
};

export const useProjectAnalytics = () => {
  const { currentUser } = useAuthContext();
  const { currentProject } = useProjectContext();

  const params = {
    projectId: currentProject?.id,
    userId: currentUser.uid,
  };

  return useAnalytics(params);
};
