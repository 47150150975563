import React from 'react';

interface ScreensizeState {
  isPhone: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  windowWidth: number | null | undefined;
  windowHeight: number | null | undefined;
}

const initialState = {
  isPhone: false,
  isMobile: false,
  isTablet: false,
  isDesktop: true,
  windowWidth: null,
  windowHeight: null,
};

export const ScreensizeContext = React.createContext<ScreensizeState>(
  initialState
);
