import { selectObjectProperty } from '@editor/reducers/canvas.slice';
import { CanvasSvgAssetObject } from '@editor/utils/canvas.types';
import { G, SVG } from '@svgdotjs/svg.js';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAssetLibrary } from '../../useAssetLibrary';
import { Subject } from 'rxjs';
import CanvasEventBus from '../../../../../common/services/human-events.service';

export const useAsset = (object: CanvasSvgAssetObject) => {
  const { properties, id, assetId } = object;
  const { assetLibraryState, dispatchFetchAssetbyId } = useAssetLibrary();
  const flipHorizontally = useSelector(
    selectObjectProperty(id, 'flipHorizontally')
  );
  const opacityFromState = useSelector(selectObjectProperty(id, 'opacity'));
  const [opacity, setOpacity] = useState(opacityFromState);
  const asset = assetLibraryState?.canvasAssets?.[assetId];
  const [assetSvg, setAssetSvg] = useState(undefined);
  const defaultSvgWidth = properties.viewBoxWidth || assetSvg?.width();
  const defaultSvgHeight = properties.viewBoxHeight || assetSvg?.height();
  const [svgWidth, setSvgWidth] = useState(defaultSvgWidth);
  const [svgHeight, setSvgHeight] = useState(defaultSvgHeight);
  const [inlineSvg, setInlineSvg] = useState(assetSvg?.svg());

  useEffect(() => {
    dispatchFetchAssetbyId(assetId);
  }, [dispatchFetchAssetbyId, assetId]);

  useEffect(() => {
    if (!asset) {
      return;
    }
    const { svg } = asset;
    const AssetGroup = svg.findOne('#AssetGroup') as G;
    AssetGroup.attr('stroke-dasharray', '4 6');
    setAssetSvg(svg);
  }, [asset]);

  useEffect(() => {
    if (!assetSvg) {
      return;
    }

    assetSvg.width('100%');
    assetSvg.height('100%');

    const assetGroup = SVG(assetSvg.findOne('#AssetGroup')) as G;

    if (flipHorizontally) {
      assetGroup.transform({ ...assetGroup.transform, flip: 'x' });
    } else {
      assetGroup.transform({ ...assetGroup.transform, flip: null });
    }

    assetSvg.opacity(opacity);
    setInlineSvg(assetSvg.svg());
  }, [assetSvg, svgWidth, svgHeight, flipHorizontally, opacity]);

  useEffect(() => {
    const setupAssetEventBus = () => {
      const opacity$ = new Subject();
      CanvasEventBus.addObservable(`${object.id}_opacity`, opacity$);
      const opacitySubscription = opacity$.subscribe((newOpacity: number) =>
        setOpacity(newOpacity)
      );

      return () => {
        opacitySubscription.unsubscribe();
        CanvasEventBus.removeObservable(`${object.id}_opacity`);
      };
    };

    const cleanup = setupAssetEventBus();
    return () => cleanup();
  }, [object.id]);

  useEffect(() => {
    setOpacity(opacityFromState);
  }, [opacityFromState]);

  return {
    svg: assetSvg,
    inlineSvg,
    svgWidth,
    svgHeight,
    setSvgWidth,
    setSvgHeight,
    aspectRatio: svgWidth / svgHeight,
  };
};
