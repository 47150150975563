import {
  useProjectAnalytics,
  AnalyticsEvents,
} from '@common/analytics/analytics.util';
import { PermissionDefinition } from '@posturize/shared/permissions';
import { useUpgrade } from '../../+ui/UpgradeContext';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router';

export const usePermission = (permission: PermissionDefinition) => {
  const { name, message, upgradable } = permission;
  const history = useHistory();
  const { logEvent } = useProjectAnalytics();
  const upgrade = useUpgrade();

  const featureRef = uuid();

  const onDecline = () => {
    logEvent(AnalyticsEvents.UPGRADE_MODAL_OUTCOME, {
      permissionName: name,
      outcome: 'decline',
      featureRef,
    });
  };

  const onConfirm = () => {
    logEvent(AnalyticsEvents.UPGRADE_MODAL_OUTCOME, {
      permissionName: name,
      outcome: 'confirm',
      featureRef,
    });
    logEvent(AnalyticsEvents.SUBSCRIPTION_FLOW_START, {
      permissionName: name,
      featureRef,
    });
    history.push('/subscription', { featureRef });
  };

  const displayModal = (context = null, title?: string) => {
    logEvent(`UPGRADE_MODAL_${name}` as any, {
      permissionName: name,
      context,
      featureRef,
    });
    logEvent(AnalyticsEvents.UPGRADE_MODAL_DISPLAY, {
      permissionName: name,
      context,
      featureRef,
    });
    upgrade.displayModal({
      text: message,
      upgradable,
      title,
      onConfirm,
      onDecline,
    });
  };

  return {
    displayModal,

    value: permission.value,
  };
};
