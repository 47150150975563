import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-weight: bold;
    margin-top: 12px;
    margin-bottom: 6px;
    font-size: 24px;
  }

  p {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  a {
    font-weight: bold;
  }
`;

export const ContactRoute = () => {
  return (
    <Wrapper>
      <div>
        <h1>Contact Us</h1>

        <p>
          We know, fancy contact forms are great to have. As we are still on
          BETA, we prefer to focus on enhancing our functionality.
        </p>

        <p>For this reason, we do not have one of those contact forms!</p>

        <p>
          Feel free to contact us via e-mail regarding any subject (questions,
          account issues, technical issues, asset requests and more).
        </p>

        <p>
          You can contact us at{' '}
          <a
            className="font-medium text-primary"
            href="mailto:hello@posturize.app"
          >
            hello@posturize.app
          </a>
        </p>

        <p>
          Thanks,
          <br />
          <strong>The Posturize Team</strong>
        </p>
      </div>
    </Wrapper>
  );
};
