import React, { FC, ReactElement } from 'react';
import { Button } from '../Button';
import { v4 as uuid } from 'uuid';
import { Modal as MUIModal, Backdrop, Fade } from '@material-ui/core';
import { Card } from '../Card';
import cn from 'classnames';

interface ModalProps {
  isOpen: boolean;
  background?: 'primary' | 'secondary' | 'transperent' | 'white';
  title: string;
  subtitle?: ReactElement;
  onDismiss?: () => void;
  shape?: 'round';
  color?: string;
  withCloseBtn?: boolean;
  style?: any;
  className?: string;
  contentPadding?: boolean;
}

export const Modal: FC<ModalProps> = ({
  isOpen,
  title,
  withCloseBtn = true,
  onDismiss,
  children,
  style = {},
  subtitle,
  className = '',
  background = 'secondary',
  contentPadding = true,
}) =>
  isOpen ? (
    <MUIModal
      id={uuid()}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`flex items-center justify-center ui`}
      open={true}
      onClose={onDismiss}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <>
        <Fade in={isOpen}>
          <Card className={`relative ${className} ignore-deselect-capture`}>
            <div className="flex items-center justify-between p-6 border-b">
              <div>
                <h2 className="text-xl font-medium">{title}</h2>
                {subtitle && (
                  <div className="text-sm font-normal">{subtitle}</div>
                )}
              </div>
              {withCloseBtn && (
                <div className="flex items-center">
                  <Button
                    color="transperent"
                    className="text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 p-0"
                    style={{ padding: '0' }}
                    onClick={() => onDismiss?.()}>
                    <span className="sr-only">Close</span>
                    {/* <!-- Heroicon name: x --> */}
                    <svg
                      className="fill-current h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true">
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Button>
                </div>
              )}
            </div>

            <div
              className={cn(`bg-${background} rounded-b-xl w-full`, {
                'px-6 py-6': contentPadding,
              })}>
              {children}
            </div>
          </Card>
        </Fade>
      </>
    </MUIModal>
  ) : null;
