import {
  setObjectBoneProperty,
  setObjectProperty,
} from '@editor/reducers/canvas.slice';
import { Circle, G, SVG, Svg } from '@svgdotjs/svg.js';
import {
  humanBoneConfiguration,
  humanHighlightConfiguration,
} from './human.config';
import { Joint } from './Joint';
import { Face } from './Face';
import { CanvasSkeleton } from '@editor/utils/canvas.types';
import * as Hands from '../hands';
import * as Feet from '../feet';
import { calcProportions } from '@editor/utils/saveCanvas.utils';
import { createLogger, Logger } from '@common/utils/logger';

interface HumanSkeletonOptions {
  svgElement: SVGElement;
  object: CanvasSkeleton;
  canvasScale: number;
  canvasDimensions: { width: number; height: number };
  dispatch: Function;
  flipHorizontally: boolean;
}

export class HumanSkeleton {
  logger: Logger;

  svg: Svg;
  objectId: string;
  joints: Record<string, Joint> = {};
  initialized = false;
  face: Face;
  dispatch: Function;
  object: CanvasSkeleton;
  canvasScale: number;
  canvasDimensions: { width: number; height: number };
  flipHorizontally: boolean;
  bodyAngle: number;

  static supportSelectors = [
    '#FaceMask',
    '#HeadPivotPoint',
    '#GroinPivotPoint',
    '#ArmSpanPivotPoint',
    '#RightUpperArmPivotPoint',
    '#RightLowerArmPivotPoint',
    '#RightHandPivotPoint',
    '#RightHand #HandPivotPoint',
    '#LeftUpperArmPivotPoint',
    '#LeftLowerArmPivotPoint',
    '#LeftHandPivotPoint',
    '#LeftHand #HandPivotPoint',
    '#RightUpperLegPivotPoint',
    '#RightLowerLegPivotPoint',
    '#RightFootPivotPoint',
    '#RightFoot #FootPivotPoint',
    '#LeftUpperLegPivotPoint',
    '#LeftLowerLegPivotPoint',
    '#LeftFootPivotPoint',
    '#LeftFoot #FootPivotPoint',
  ];

  constructor(options: HumanSkeletonOptions) {
    const {
      object,
      svgElement,
      canvasScale,
      canvasDimensions,
      dispatch,
      flipHorizontally,
    } = options;

    this.logger = createLogger(`HumanSkeleton:${object.id}`);

    this.object = object;
    this.dispatch = dispatch;
    this.canvasScale = canvasScale;
    this.canvasDimensions = canvasDimensions;
    this.flipHorizontally = flipHorizontally;

    this.bodyAngle = this.object.properties.bodyAngle;

    const { id: objectId } = object;
    this.objectId = objectId;
    this.svg = SVG(svgElement) as Svg;
  }

  remove() {
    this.svg.remove();
  }

  renderInitialJointAngles() {
    this.logger.debug('renderInitialJointAngles');

    Object.values(this.joints).forEach((joint: Joint) => {
      joint.renderAngle(
        joint.boneFromState.properties.angle,
        joint.initialOffset
      );
    });
  }

  setJointsLockStatus(locked: boolean) {
    this.logger.debug('setLockStatus', locked);
    Object.values(this.joints).forEach((joint: Joint) => {
      joint.setLocked(locked);
    });
  }

  setFaceLockStatus(locked: boolean) {
    this.face.setLocked(locked);
  }

  initializeJoints() {
    this.logger.debug('initializeJoints');
    Object.entries(humanBoneConfiguration).forEach(([boneName, bone]) => {
      const boneFromState = this.object.bones[boneName];

      const boneSetPropertyFn = (property: string, value: any) => {
        this.logger.debug(
          `Setting property "${property}" to "${value}" (bone: ${boneName})`
        );
        this.dispatch(
          setObjectBoneProperty(this.objectId, boneName, property, value)
        );
      };

      const joint = new Joint({
        parentObjectId: this.objectId,
        bone,
        svg: this.svg,
        canvasScale: this.canvasScale,
        boneFromState,
        setPropertyFn: boneSetPropertyFn,
        flipHorizontally: this.flipHorizontally,
      });

      joint.applyRotationLogic();
      this.joints[boneName] = joint;
    });
  }

  initializeFace() {
    const { faceAngle } = this.object.properties;

    if (this.face) {
      this.face.removeExistingFaces();
    }

    this.logger.debug('initializeFace');
    this.face = new Face({
      svg: this.svg,
      objectId: this.objectId,
      defaultValue: faceAngle,
      canvasScale: this.canvasScale,
      flipHorizontally: this.flipHorizontally,
      onFaceAngleChange: (newFaceAngle) => {
        this.dispatch(
          setObjectProperty(this.objectId, 'faceAngle', newFaceAngle)
        );
      },
    });
  }

  removeSupports(softRemove = true) {
    HumanSkeleton.supportSelectors.forEach((selector) => {
      const node = this.svg.findOne(selector);

      if (softRemove) {
        SVG(node).attr({ visibility: 'hidden' });
      } else {
        node.remove();
      }
    });
  }

  applyBodyAngle(angle: number) {
    this.bodyAngle = angle;
    const armMultiplier = 1.55;
    const svg = this.svg;

    // Left Arm
    const LeftArm = SVG(svg.findOne('#LeftArm')) as G;
    const leftArmValue = angle * armMultiplier;
    LeftArm.transform({ relativeX: -leftArmValue });

    // Right Arm
    const RightArm = SVG(svg.findOne('#RightArm')) as G;
    const rightArmValue = -angle * armMultiplier;
    RightArm.transform({ relativeX: -rightArmValue });

    const legMultiplier = 0.75;

    // Left Leg
    const LeftLeg = SVG(svg.findOne('#LeftLeg')) as G;
    const leftLegValue = angle * legMultiplier;
    LeftLeg.transform({ relativeX: -leftLegValue });

    // Right Leg
    const RightLeg = SVG(svg.findOne('#RightLeg')) as G;
    const rightLegValue = -angle * legMultiplier;
    RightLeg.transform({ relativeX: -rightLegValue });
  }

  initializeHands() {
    this.logger.debug('initializeHands');
    const { leftHand, rightHand } = this.object.properties;

    this.initializeHand(
      this.joints.LeftHand,
      leftHand.type,
      leftHand.flipVertically,
      leftHand.flipHorizontally
    );

    this.initializeHand(
      this.joints.RightHand,
      rightHand.type,
      rightHand.flipVertically,
      rightHand.flipHorizontally
    );
  }

  initializeFeet() {
    this.logger.debug('initializeFeet');
    const { leftFoot, rightFoot } = this.object.properties;

    this.initializeFoot(this.joints.LeftFoot, leftFoot.flipHorizontally);

    this.initializeFoot(this.joints.RightFoot, rightFoot.flipHorizontally);
  }

  applyColors() {
    const rightSide = ['#UpperBody #RightArm', '#LowerBody #RightLeg'];

    const reduceRight = calcProportions(this.bodyAngle, 0, 100, 0, 0.5);
    const rightOpacity = 1.0 - reduceRight;

    const lowerBodyArcSvg = SVG(this.svg.findOne('#LowerBodyArc'));
    const armSpanArcSvg = SVG(this.svg.findOne('#ArmSpanArc'));
    const upperBodySvg = SVG(this.svg.findOne('#UpperBody'));
    const arcScaleX =
      this.bodyAngle <= 75
        ? calcProportions(this.bodyAngle, 0, 75, 1, 0.6)
        : calcProportions(this.bodyAngle, 76, 100, 0.6, 0.8);
    const upperBodyScaleX =
      this.bodyAngle <= 75
        ? calcProportions(this.bodyAngle, 0, 75, 1, 0.9)
        : calcProportions(this.bodyAngle, 76, 100, 0.9, 0.95);

    lowerBodyArcSvg.transform({ scaleX: arcScaleX });
    armSpanArcSvg.transform({ scaleX: arcScaleX });
    upperBodySvg.transform({
      ...upperBodySvg.transform(),
      scaleX: upperBodyScaleX,
    });

    rightSide.forEach((selector) => {
      const found = this.svg.findOne(selector);
      const svg = SVG(found);
      svg.attr({ opacity: rightOpacity });
    });
  }

  applyScale(scale: number) {
    this.logger.debug(`applyScale (value: ${scale})`);
    const initialWidth = parseInt(
      this.svg.node.getAttribute('data-initial-width')
    );
    const initialHeight = parseInt(
      this.svg.node.getAttribute('data-initial-height')
    );
    this.svg.width(initialWidth * scale);
    this.svg.height(initialHeight * scale);
  }

  initializeFoot(joint, flipHorizontally = false) {
    this.logger.debug(`initializeFoot`);
    const id = joint.jointId;
    const svg = this.svg;
    const target = SVG(svg.findOne(`#${id}`));
    const footObj = Feet.Foot1;

    const footTypeSvg: string = footObj.svg;
    const injectSVG = SVG(footTypeSvg.replace(/(\r\n|\n|\r)/gm, ''));

    // Remove unnecessary attributes
    injectSVG.node.removeAttribute('width');
    injectSVG.node.removeAttribute('height');
    injectSVG.node.removeAttribute('viewBox');

    const footGroup = SVG(injectSVG.findOne('#FootGroup'));
    target.add(injectSVG);

    const actualFoot = SVG(footGroup.findOne('#Foot'));

    if (flipHorizontally) {
      footGroup.flip('x');
      actualFoot.flip('x');
    }

    // Snap hand pivot point to joint anchor point
    const footPivotPoint = SVG(footGroup.findOne('#FootPivotPoint'));
    const attachmentPointEl = svg.findOne(`#${id}PivotPoint`) as Circle;

    const svgR = footPivotPoint.rbox();
    const attR = attachmentPointEl.rbox();

    const distX = attR.x - svgR.x;
    const distY = attR.y - svgR.y;

    footGroup.transform({
      translateX: distX / this.canvasScale,
      translateY: distY / this.canvasScale,
      scale: 1,
    });

    injectSVG.attr({ 'stroke-dasharray': '6 4' });

    return injectSVG;
  }

  initializeHand(
    joint,
    type: string,
    flipVertically = false,
    flipHorizontally = false
  ) {
    this.logger.debug(`initializeHand (${type})`);
    const id = joint.jointId;
    const svg = this.svg;
    const target = SVG(svg.findOne(`#${id}`));

    const handObj = Hands[type];

    const handTypeSvg: string = handObj.svg;
    const injectSVG = SVG(handTypeSvg.replace(/(\r\n|\n|\r)/gm, ''));

    // Remove unnecessary attributes
    injectSVG.node.removeAttribute('width');
    injectSVG.node.removeAttribute('height');
    injectSVG.node.removeAttribute('viewBox');

    const handGroup = SVG(injectSVG.findOne('#HandGroup'));

    target.add(injectSVG);

    const actualHand = SVG(handGroup.findOne('#Hand'));

    if (flipHorizontally) {
      handGroup.flip('x');
      actualHand.flip('x');
    }

    if (flipVertically) {
      handGroup.flip('y');
      actualHand.flip('y');
    }

    // Snap hand pivot point to joint anchor point
    const handPivotPoint = SVG(handGroup.findOne('#HandPivotPoint'));
    const attachmentPointEl = svg.findOne(`#${id}PivotPoint`) as Circle;

    const svgR = handPivotPoint.rbox();
    const attR = attachmentPointEl.rbox();

    const distX = attR.x - svgR.x;
    const distY = attR.y - svgR.y;

    handGroup.transform({
      translateX: distX / this.canvasScale,
      translateY: distY / this.canvasScale,
      scale: 1,
    });

    if (handObj.renderAttributes) {
      injectSVG.attr(handObj.renderAttributes);
    } else {
      injectSVG.attr({ 'stroke-dasharray': '6 4' });
    }

    return injectSVG;
  }

  initializeHoverEffects() {
    // This will help with linking highlights between one joint/component or another. Very useful!
    this.logger.debug('initializeHoverEffects');

    humanHighlightConfiguration.forEach(({ joint, selector, linked = [] }) => {
      const node = this.svg.node.querySelector(selector);

      if (!node) {
        throw new Error(
          `[Human:${this.objectId}.initializeHoverEffects] Cannot find initial node with selector "${selector}"`
        );
      }

      node.classList.add('hoverable');
      const highlightElements = [selector, ...linked].map((selector) => {
        const el = this.svg.node.querySelector(selector);

        if (!el) {
          throw new Error(
            `[Human:${this.objectId}.initializeHoverEffects] Cannot find continuous node with selector "${selector}"`
          );
        }

        return el;
      });

      const applyHighlight = () =>
        highlightElements.forEach((el) => el.classList.add('highlight'));
      const removeHighlight = () =>
        highlightElements.forEach((el) => el.classList.remove('highlight'));

      if (joint) {
        this.joints[joint].onRotateStart = () => applyHighlight();
        this.joints[joint].onRotateEnd = () => removeHighlight();
      }

      node.addEventListener('mouseover', () => {
        applyHighlight();
        node.addEventListener('mouseout', () => removeHighlight());
      });
    });
  }

  applyOpacity(value: number) {
    this.logger.debug(`applyOpacity (value: ${value})`);
    const container = SVG(this.svg.findOne('#HumanContainer'));
    container.opacity(value);
  }

  applyFlipHorizontally(value: boolean) {
    this.logger.debug(`applyFlipHorizontally (value: ${value})`);
    this.flipHorizontally = value;
    const container = SVG(this.svg.findOne('#HumanContainer'));

    if (this.flipHorizontally) {
      container.transform({ flip: 'x' });
    } else {
      container.transform({ flip: null });
    }

    Object.values(this.joints).forEach((joint: Joint) => {
      joint.flipHorizontally = value;
    });

    this.face.flipHorizontally = value;
  }
}
