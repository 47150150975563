import React, { useState } from 'react';
import { KnowledgeBaseModal } from './KnowledgeBaseModal';
import { OfferKnowledgeBaseModal } from './OfferKnowledgeBaseModal';
import { useKnowledgeBase } from './useKnowledgeBase';

export const KnowledgeBaseContainer = () => {
  const {
    isKnowledgeBaseOpen,
    setIsKnowledgeBaseOpen,
    shouldOfferKnowledgeBase,
    saveDontShowAgain,
  } = useKnowledgeBase();
  const [isOfferOpen, setIsOfferOpen] = useState(shouldOfferKnowledgeBase);

  const handleOfferOutcome = (
    showKnowledgeBase: boolean,
    dontShowAgain: boolean
  ) => {
    setIsOfferOpen(false);
    setIsKnowledgeBaseOpen(showKnowledgeBase);

    if (dontShowAgain) {
      saveDontShowAgain();
    }
  };

  return (
    <>
      <OfferKnowledgeBaseModal
        isOpen={isOfferOpen && !isKnowledgeBaseOpen}
        onConfirm={(dontShowAgain: boolean) =>
          handleOfferOutcome(true, dontShowAgain)
        }
        onDismiss={(dontShowAgain: boolean) =>
          handleOfferOutcome(false, dontShowAgain)
        }
      />

      <KnowledgeBaseModal
        isOpen={isKnowledgeBaseOpen}
        onDismiss={() => setIsKnowledgeBaseOpen(false)}
      />
    </>
  );
};
