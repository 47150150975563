import React, { FC, useState, forwardRef } from 'react';
import { InputProps, Input } from './Input';

interface EditableInputProps extends InputProps {
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const EditableInput: FC<EditableInputProps> = forwardRef(
  ({ children, error, onConfirm, onCancel, ...inputProps }, forwardedRef) => {
    const [isEditing, setIsEditing] = useState(false);

    const handleInputBlur = () => {
      if (error) {
        return;
      }
      setIsEditing(false);
      onConfirm?.();
    };

    const handleKeyDown = ({ key }: React.KeyboardEvent<HTMLDivElement>) => {
      if (key === 'Escape') {
        setIsEditing(false);
        onCancel?.();
      }

      if (error) {
        return;
      }

      if (key === 'Enter') {
        setIsEditing(false);
        onConfirm?.();
      }
    };

    return isEditing ? (
      <div
        className="flex items-center"
        onBlur={handleInputBlur}
        onKeyDown={handleKeyDown}
      >
        <Input ref={forwardedRef} {...inputProps} />
      </div>
    ) : (
      <div onClick={() => setIsEditing(true)}>{children}</div>
    );
  }
);
