import React from 'react';
import { KnowledgeBaseTopic, DemoGIF } from '../knowledge-base.common';

export const topic: KnowledgeBaseTopic = {
  id: 'human_properties',
  label: 'Human Properties',
  premium: false,
  Content: () => (
    <div>
      <p>If you select a Human object, you will be able to modify some properties in the Inspector.</p>
      <p>Some of the properties you can modify are <strong>scale</strong>, <strong>body angle</strong>, <strong>opacity</strong>, and <strong>horizontal flipping</strong>.</p>
      
      <div className="mt-6">
        <DemoGIF src="https://static-assets-cdn.aws.posturize.app/knowledge-base/HumanProperties.gif" />
      </div>
    </div>
  ),
};
