import {
  addObject,
  selectCanvas,
  setObjectProperty,
} from '@editor/reducers/canvas.slice';
import { Canvas } from '@editor/utils/canvas.types';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { calcDimensions } from '@editor/utils/canvas.utils';
import {
  createHumanObject,
  createSvgAssetObject,
  createUserAssetObject,
} from '@editor/utils/canvasObjectUtils';
import { Asset } from '@editor/features/canvas/object-types/Asset/asset.types';
import { UserAsset } from '@editor/features/canvas/object-types/Asset/user-asset.types';

export const useCanvasV2 = () => {
  const dispatch = useDispatch();
  const canvas = useSelector(selectCanvas) as Canvas;

  const objects = useMemo(() => canvas.objects, [canvas.objects]);
  const properties = useMemo(() => canvas.properties, [canvas.properties]);
  const scale = calcDimensions(properties.width, properties.height);

  const addSvgAssetObject = (asset: Asset) => {
    const obj = createSvgAssetObject(asset);
    dispatch(addObject(obj));
  };

  const addUserAssetObject = (userAsset: UserAsset) => {
    const obj = createUserAssetObject(userAsset);
    dispatch(addObject(obj));
  };

  const addHumanObject = () => {
    const obj = createHumanObject();
    dispatch(addObject(obj));
  };

  return {
    objects,
    objectsAmount: Object.keys(objects).length,
    properties,
    disabled: canvas.disabled,
    disableReason: canvas.disableReason,
    canvasDimensions: {
      width: properties.width,
      height: properties.height,
    },
    scale,
    addUserAssetObject,
    addSvgAssetObject,
    addHumanObject,
    setObjectProperty: (objectId: string, property: string, value: any) =>
      dispatch(setObjectProperty(objectId, property, value)),
    getObjectById: (objectId: string) => objects[objectId],
  };
};
