import React from 'react';
import { KnowledgeBaseTopic, DemoGIF } from '../knowledge-base.common';

export const topic: KnowledgeBaseTopic = {
  id: 'layers',
  label: 'Layers',
  premium: false,
  Content: () => (
    <div>
      <div className="mt-4">
        <h2 className="text-lg font-medium">Reordering layers</h2>
        <p>
          Your objects are layered in the editor. That means, one object can be
          on top of another.
        </p>
        <p>
          You can drag and drop layers in the Inspector to change the order.
        </p>
        <div className="mt-6">
          <DemoGIF width={240} src="https://static-assets-cdn.aws.posturize.app/knowledge-base/LayerOrder.gif" />
        </div>
      </div>

      <div className="mt-4">
        <h2 className="text-lg font-medium">Locking layers</h2>
        <p>
          You can lock and unlock layers in via the Inspector. This can help you prevent accidental changes to your object.
        </p>
        <div className="mt-6">
          <DemoGIF width={240} src="https://static-assets-cdn.aws.posturize.app/knowledge-base/LayerLock.gif" />
        </div>
      </div>
    </div>
  ),
};
