import React, { useState } from 'react';
import * as InspectorUI from '../InspectorUI';
import Slider from '@material-ui/core/Slider';
import {
  AiFillDelete as DeleteIcon,
  AiOutlinePlusCircle as ScaleIncrease,
  AiOutlineMinusCircle as ScaleDecrease,
} from 'react-icons/ai';
import { useObject } from '@editor/hooks/useObject';
import { ConfirmationModal } from '@common/modals/ConfirmationModal';
import { CanvasObjectTypes } from '@editor/utils/canvas.types';
import cn from 'classnames';
import { Switch } from '@material-ui/core';
import CanvasEventBus from '@common/services/human-events.service';

export const ObjectProperties = ({ object, setProperty }) => {
  const { properties, id: objectId, objectType } = object;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { deleteObjectFromCanvas } = useObject(objectId);

  const renderDeleteConfirmationModal = () => (
    <ConfirmationModal
      title="Are you sure?"
      bodyText="Are you sure you want to delete this object?"
      isOpen={isDeleteModalOpen}
      onDismiss={() => setIsDeleteModalOpen(false)}
      onConfirm={() => deleteObjectFromCanvas()}
      confirmColor="danger"
    />
  );

  function roundToDecimal(decimalPoint, number) {
    return +(
      Math.round((number + `e+${decimalPoint}`) as any) + `e-${decimalPoint}`
    );
  }

  const renderScale = () => {
    const scale = properties.scale;
    const max = 1.5;
    const min = 0.5;
    const step = 0.1;

    const disableIncrease = scale >= max;
    const disableDecrease = scale <= min;

    const increase = () =>
      !disableIncrease && setProperty('scale', roundToDecimal(1, scale + step));
    const decrease = () =>
      !disableDecrease && setProperty('scale', roundToDecimal(1, scale - step));

    return (
      <div className="grid grid-cols-6 border-b h-10 items-center justify-center">
        <div className="col-span-2 text-sm">Scale</div>
        <div className="col-span-4 flex space-x-3 align-items justify-content mx-auto">
          <div
            className={cn('flex-1 text-center', {
              'opacity-40': disableDecrease,
              'cursor-pointer': !disableDecrease,
            })}
          >
            <ScaleDecrease size={20} onClick={decrease} />
          </div>
          <div
            className="flex-1 text-center text-sm"
            style={{ lineHeight: 1.4, width: 30 }}
          >
            {scale}
          </div>
          <div
            className={cn('flex-1 text-center', {
              'opacity-40': disableIncrease,
              'cursor-pointer': !disableIncrease,
            })}
          >
            <ScaleIncrease size={20} onClick={increase} />
          </div>
        </div>
      </div>
    );
  };

  const BodyAngleSlider = ({ currentBodyAngleFromState }) => {
    const [value, setValue] = useState(currentBodyAngleFromState);
    const bodyAngle$ = CanvasEventBus.getObservable(
      `${objectId}_bodyAngle`
    );

    const handleChange = (newValue: number) => {
      setValue(newValue);
      bodyAngle$.next(newValue);
    };

    return (
      <div className="grid grid-cols-6 border-b h-10 items-center justify-center">
        <div className="col-span-2 text-sm">Angle</div>
        <div className="col-span-4">
          <Slider
            style={{ width: '100%' }}
            min={0}
            max={120}
            value={value}
            onChange={(e, newValue: number) => handleChange(newValue)}
            onChangeCommitted={(e, committedValue) =>
              setProperty('bodyAngle', committedValue)
            }
          />
        </div>
      </div>
    );
  };

  const OpacitySlider = ({ currentOpacityFromState }) => {
    const [value, setValue] = useState(currentOpacityFromState);
    const opacity$ = CanvasEventBus.getObservable(`${objectId}_opacity`);

    const handleChange = (newValue: number) => {
      setValue(newValue);
      opacity$.next(newValue);
    };

    return (
      <div className="grid grid-cols-6 border-b h-10 items-center justify-center">
        <div className="col-span-2 text-sm">Opacity</div>
        <div className="col-span-4">
          <Slider
            style={{ width: '100%' }}
            min={0}
            max={1}
            step={0.01}
            value={value}
            onChange={(e, newValue: number) => handleChange(newValue)}
            onChangeCommitted={(e, committedValue) =>
              setProperty('opacity', committedValue)
            }
          />
        </div>
      </div>
    );
  };

  const renderFlipHorizontally = () => (
    <div className="grid grid-cols-6 border-b h-10 items-center justify-center">
      <div className="col-span-2 text-sm">Flip</div>
      <div className="col-span-4 flex items-center justify-center">
        <Switch
          color="primary"
          checked={properties.flipHorizontally}
          onClick={(e: React.ChangeEvent<any>) =>
            setProperty('flipHorizontally', e.target.checked)
          }
        />
      </div>
    </div>
  );

  const renderDeleteObject = () => (
    <div className="grid grid-cols-6 h-10 items-center justify-center">
      <div className="col-span-2 text-sm">Delete</div>
      <div className="col-span-4 flex items-center justify-center">
        <DeleteIcon
          className="cursor-pointer"
          size={18}
          fill="#DD2B2B"
          onClick={deleteObjectFromCanvas}
        />
      </div>
    </div>
  );

  const HumanUI = () => (
    <>
      {renderScale()}
      <BodyAngleSlider currentBodyAngleFromState={properties.bodyAngle} />
      <OpacitySlider currentOpacityFromState={properties.opacity} />
      {renderFlipHorizontally()}
      {renderDeleteObject()}
    </>
  );

  const AssetUI = () => (
    <>
      <OpacitySlider currentOpacityFromState={properties.opacity} />
      {renderFlipHorizontally()}
      {renderDeleteObject()}
    </>
  );

  return (
    <>
      {renderDeleteConfirmationModal()}

      <InspectorUI.Section title={`Object (${objectType})`}>
        {properties.locked ? (
          <p className="text-center p-2">The object is locked.</p>
        ) : (
          <div className="px-4">
            {objectType === CanvasObjectTypes.HUMAN ? HumanUI() : AssetUI()}
          </div>
        )}
      </InspectorUI.Section>
    </>
  );
};
