import { Button, Modal } from '@common/components';
import React from 'react';

export const ConfirmationModal = ({
  title,
  bodyText = null,
  bodyHTML = null,
  isOpen,
  onDismiss,
  confirmColor = 'primary',
  confirmText = 'Confirm',
  onConfirm,
  onCancel = null,
}) => (
  <Modal title={title} isOpen={isOpen} onDismiss={onDismiss}>
    <>
      {bodyText || bodyHTML}
      <div className="flex justify-between mt-4">
        <Button
          color="transperent"
          className="text-black"
          onClick={() => (onCancel ? onCancel() : onDismiss())}
        >
          Cancel
        </Button>
        <Button
          color={confirmColor}
          onClick={onConfirm}
          className="px-4 text-white"
        >
          {confirmText}
        </Button>
      </div>
    </>
  </Modal>
);
