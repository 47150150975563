import { Button, Loader } from '@common/components';
import React from 'react';
import cn from 'classnames';

interface Props {
  buttonLoader?: boolean;
  buttonTitle: string;
  secondActionTitle?: string;
  data: string | React.ReactElement;
  label: string;
  onClick?: () => void;
  onSecondActionClick?: () => void;
  hasBorderBottom?: boolean;
}

export const DetailRow = ({
  buttonTitle,
  buttonLoader = false,
  secondActionTitle = '',
  data = '',
  label,
  onClick,
  onSecondActionClick = null,
  hasBorderBottom = true,
}: Props) => (
  <div
    className={cn('flex justify-between py-6 items-start', {
      'border-b border-secondary-dark': hasBorderBottom,
    })}
  >
    <h4 className="text-primary-darker w-4/12">{label}</h4>
    <div className="w-1/3">
      {typeof data === 'string' ? (
        <p className="text-primary-dark">{data}</p>
      ) : (
        data
      )}
    </div>
    <div className="w-1/3 flex flex-col justify-end items-end">
      {onClick && (
        <Button color="primary" variant="link" onClick={onClick}>
          {buttonLoader ? (<Loader />) : buttonTitle}
        </Button>
      )}
      {onSecondActionClick && (
        <Button
          color="primary"
          variant="link"
          className="mt-2"
          onClick={onSecondActionClick}
        >
          {secondActionTitle}
        </Button>
      )}
    </div>
  </div>
);
