import React, { useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';

import {
  MdKeyboardArrowUp as ClosedArrow,
  MdKeyboardArrowDown as OpenArrow,
} from 'react-icons/md';

export const Section = ({
  title,
  children,
  style = {},
  className = '',
  defaultCollapse = false,
}) => {
  const [collapse, setCollapse] = useState(defaultCollapse);

  return (
    <>
      <Heading
        onToggleCollapse={() => setCollapse(!collapse)}
        collapse={collapse}
      >
        {title}
      </Heading>
      <Content collapse={collapse} style={style} className={className}>
        {children}
      </Content>
    </>
  );
};

const CaretContainer = styled.div`
  opacity: 0.4;
  position: absolute;
  right: 10px;
  cursor: pointer;
`;

export const Heading = ({
  children,
  style = {},
  className = '',
  collapse,
  onToggleCollapse,
}) => (
  <div
    style={style}
    onClick={onToggleCollapse}
    className={`${className} relative bg-white border-solid border-gray-200 border p-2 font-semibold text-sm text-center uppercase cursor-pointer`}
  >
    <CaretContainer>
      {collapse ? <ClosedArrow size={22} /> : <OpenArrow size={22} />}
    </CaretContainer>
    {children}
  </div>
);

const Content = ({ children, style = {}, className = '', collapse }) => (
  <div
    style={style}
    className={cn('bg-gray-50', { hidden: collapse }, className)}
  >
    {children}
  </div>
);
