import React from 'react';
import { Card } from '@common/components/Card';
import { useAuthContext } from '@common/context';
import { DetailRow } from './DetailRow';

export const PersonalDetailsBox = ({ onChangePassword }) => {
  const { userData, currentUser } = useAuthContext();

  if (!userData) {
    return null;
  }

  const isUserAbleToChangePassword = !!currentUser.providerData.find(
    (provider) => provider.providerId === 'password'
  );

  const { email } = userData;
  return (
    <Card className="p-8 mb-4">
      <h2 className="text-primary-dark text-xl">Personal details</h2>

      {currentUser.displayName && (
        <DetailRow buttonTitle="Change name" data={currentUser.displayName} label="Name" />
      )}

      <DetailRow buttonTitle="Change e-mail" data={email} label="E-mail" />

      {isUserAbleToChangePassword && (
        <DetailRow
          buttonTitle="Change password"
          data="••••••••"
          label="Password"
          onClick={onChangePassword}
          hasBorderBottom={false}
        />
      )}
    </Card>
  );
};
