import React, { useRef } from 'react';
import { FileDrop } from 'react-file-drop';
import { MdCloudUpload as UploadIcon } from 'react-icons/md';

const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/svg+xml'];
const MAX_FILE_SIZE = 3000000; // 3MB

export const UploadFileView = ({ onFileUploaded }) => {
  const fileInputRef = useRef(null);
  const reader = new FileReader();

  reader.onloadend = async () => {
    const base64 = reader.result;

    /* Get metadata */
    const type = (base64 as string).split(';')[0].split(':')[1];
    
    const image = new Image();
    image.onload = () => {
      onFileUploaded({
        base64,
        type,
        width: image.width,
        height: image.height,
      });
    };

    image.src = reader.result as string;
  };

  const handleFileDrop = (fileList) => {
    if (fileList.length !== 1) {
      alert('You can only upload one file');
      return;
    }

    const file = fileList[0];

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      alert('Only PNG and JPEG formats are supported');
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      alert('The maximum file size is 3MB');
      return;
    }

    // setFile(file);
    reader.readAsDataURL(file);
  };

  const renderFileDropZone = () => (
    <FileDrop
      onDrop={handleFileDrop}
      onTargetClick={() => fileInputRef.current.click()}
    >
      <div
        style={{
          margin: 'auto',
          width: '100%',
          minHeight: 300,
          borderStyle: 'dashed',
          borderWidth: 2,
          borderColor: '#bfbfbf',
          borderRadius: 6,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: 32,
        }}
      >
        <UploadIcon color="#bfbfbf" size={84} />
        <p className="text-sm text-center mb-2 font-medium">
          You can drag and drop your file here, or click to browse your
          computer.
        </p>
        <p className="text-xs text-center">
          Files may be of type PNG/JPEG/SVG, and up to 3MB in size.
        </p>
      </div>

      <input
        onChange={({ target }) => handleFileDrop(target.files)}
        ref={fileInputRef}
        type="file"
        className="hidden"
        accept={ALLOWED_FILE_TYPES.join(', ')}
      />
    </FileDrop>
  );

  return (
    <>
      {renderFileDropZone()}
    </>
  );
};
