import React, { useState } from 'react';
import { captureException } from '@sentry/react';
import { ErrorBoundary } from 'react-error-boundary';

import { Header, ErrorMessageModal } from '@common/components';

import { AccountContainer } from './containers/Account';
import { Link } from 'react-router-dom';

export const AccountRoute = () => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  return (
    <>
      <Header />
      <ErrorBoundary
        FallbackComponent={ErrorMessageModal}
        onReset={() => setShowErrorModal(false)}
        onError={(e) => captureException(e)}
        resetKeys={[showErrorModal]}
      >
        <AccountContainer />
        <p className="text-center" style={{ marginTop: 12, fontSize: 12 }}>
          By using Posturize, you agree to our{' '}
          <Link className="font-medium" to="/terms" target="_blank">
            Terms of Service, Privacy Policy and Beta Terms of Service
          </Link>
        </p>

        <p className="text-center" style={{ marginTop: 12, fontSize: 12 }}>
          <Link className="font-medium text-primary" to="/contact" target="_blank">
            Report a Problem
          </Link>
        </p>
      </ErrorBoundary>
    </>
  );
};
