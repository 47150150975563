import { CanvasUserAssetObject } from '@editor/utils/canvas.types';
import styled from 'styled-components';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useObject } from '@editor/hooks/useObject';
import { ResizableContent } from './ResizableContent';
import { useUserAsset } from './useUserAsset';

const UserAssetContainer = styled.div<any>`
  .rect {
    border-radius: 4px;
    border: 1px solid transparent;
    transition: 0.4s border-color;

    .square {
      background: transparent;
      border: none;
      transition: 0.4s background-color;
      width: 18px;
      height: 18px;
    }

    .rotate {
      transform: scale(1.8);
      opacity: 0;
      transition: 0.4s opacity;
      margin-top: -4px;
    }
  }

  &:hover,
  &.controlled {
    &:not(.locked) {
      .rect {
        // Resize cursor
        cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAMAAABhEH5lAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAANVQTFRFAAAACgoKBgYGAAAACgoKAwMDAQEBCAgIBQUFAAAAAAAAFxcXCAgIAQEBBgYGCAgIBAQEAQEBAQEBAgICCgoKEBAQBQUFAgICBQUFDAwMAAAABwcHAAAAAQEBBgYGGRkZBQUFBAQEAQEBBAQEBQUFAgICAgICAAAAAwMDCAgIAAAAAgICAAAAAwMDBQUFAwMDAQEBAwMDBQUFCgoKCAgIBQUFCAgIDg4OAAAAAAAAAAAABAQEBAQEAwMDDg4OBQUFAwMDAAAABQUFAAAABgYGAwMDCwsLWkWURQAAAEd0Uk5TADJ5CRql4ERj+P8LQd+JQ4XP95xmEKDwOEIhZwTehR+mROOQM8/b+bRh/vP1r2Rk8spqGWYwqiUBMfKrjskkMcz0aANcqhgaDmZgAAAAs0lEQVR4nHXO1w6CQBAF0EUF3MGOW1RUwAKyKnaxd///kyQGE9fE+zQ5yZ0ZhJIoqTSSk1E1/YeyGFRDklweABckKpbKFbMqFwll/LtV+1C9Yb1vZZstQto20wlx3E7XQoqKoUep3fd8Sn0PegOU0gAqjDEvEIyJAIYjlNZVbHKu+2LMeTiZzubxMqOw+KxfrtbRvyfi1B1fhBuJFq4XbHcSWXuAw1Funs6XayTT7f54JuMLnvIQjMuAU0AAAAAASUVORK5CYII='),
          crosshair;
        border-color: #8c8c8c;

        .square {
          background: #4e4e4e;
        }

        .rotate {
          opacity: 1;
        }
      }
    }
  }
`;

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export interface UserAssetProps {
  zIndex?: number;
  object: CanvasUserAssetObject;
  controlled: boolean;
  locked: boolean;
  onSelected: Function;
}

export const UserAsset = ({
  zIndex = 0,
  object,
  controlled,
  locked,
  onSelected,
}: UserAssetProps) => {
  const {
    aspectRatio,
    width,
    height,
    setWidth,
    setHeight,
    opacity,
  } = useUserAsset(object);

  const [base64, setBase64] = useState(null);
  const [svgContents, setSVGContents] = useState(null);

  const { setBulkObjectProperties } = useObject(object.id);

  const {
    position,
    rotateAngle,
    viewBoxHeight,
    viewBoxWidth,
    flipHorizontally,
  } = object.properties;

  const calculatedStyle = {
    width,
    height,
    opacity,
    transform: flipHorizontally ? 'scaleX(-1)' : 'scaleX(1)',
  };

  const isSVG = object.metadata.mimetype === 'image/svg+xml';

  const fetchBase64 = async (fileUrl) => {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const base64 = await blobToBase64(blob);
    setBase64(base64);
  };

  // Prepare PNG/JPEG
  useEffect(() => {
    if (!isSVG) {
      fetchBase64(object.fileUrl);
    }
  }, [isSVG, object.fileUrl]);

  const fetchSVGContents = async (url) => {
    const res = await fetch(url);
    const plainSVG = await res.text();
    setSVGContents(plainSVG);
  };

  // Prepare SVG
  useEffect(() => {
    if (isSVG) {
      fetchSVGContents(object.fileUrl);
    }
  }, [isSVG, object.fileUrl]);

  if ((!isSVG && !base64) || (isSVG && !svgContents)) {
    return null;
  }

  return (
    <UserAssetContainer
      zIndex={zIndex}
      className={cn('object-bounds', { controlled, locked })}
      onMouseDown={onSelected}
      onPointerDown={onSelected}
      onTouchStart={onSelected}
    >
      <ResizableContent
        locked={locked}
        defaultX={position.x}
        defaultY={position.y}
        defaultWidth={viewBoxWidth}
        defaultHeight={viewBoxHeight}
        defaultRotateAngle={rotateAngle}
        aspectRatio={aspectRatio}
        onResize={({ width, height }) => {
          setWidth(width);
          setHeight(height);
        }}
        onResizeEnd={({
          width: viewBoxWidth,
          height: viewBoxHeight,
          top: y,
          left: x,
        }) =>
          setBulkObjectProperties({
            viewBoxWidth,
            viewBoxHeight,
            position: { x, y },
          })
        }
        onRotateEnd={(rotateAngle) => setBulkObjectProperties({ rotateAngle })}
        onDragEnd={({ top: newY, left: newX }) => {
          if (position.x !== newX || position.y !== newY) {
            // Only commit to store if something actually changed
            setBulkObjectProperties({ position: { x: newX, y: newY } });
          }
        }}
      >
        <img src={isSVG ? object.fileUrl : base64} alt="asset" style={calculatedStyle} />
      </ResizableContent>
    </UserAssetContainer>
  );
};
