import domtoimage from 'dom-to-image';
import { createCanvasSnapshot } from '../saveCanvas.utils';

const THUMBNAIL_WIDTH = 600;

export const generateCanvasThumbnail = async (): Promise<any> =>
  new Promise((resolve) => {
    createCanvasSnapshot(async canvas => {
      const { width, height } = canvas.getBoundingClientRect();
      const scaleFactor = THUMBNAIL_WIDTH / width;
      
      const newWidth = width * scaleFactor;
      const newHeight = height * scaleFactor;

      // Convert DOM element to image
      const domElementBase64 = await domtoimage.toJpeg(canvas, {
        quality: 0.3,
        bgcolor: 'white',
        width,
        height,
      });

      // Resize
      const canvasEl = document.createElement('canvas');
      const ctx = canvasEl.getContext('2d');

      var image = new Image();
      image.onload = function() {
        canvasEl.height = newHeight;
        canvasEl.width = newWidth;
        ctx.drawImage(image, 0, 0, newWidth, newHeight);
        resolve(canvasEl.toDataURL())
      };
      image.src = domElementBase64;
    });
  });
