import React, { FC, useEffect, useState } from 'react';

import { ScreensizeContext } from './ScreensizeContext';

export const ScreensizeProvider: FC = ({ children }) => {
  const [windowDimensions, setWindowDimensions] = useState({
    windowWidth: 0,
    windowHeight: 0,
  });
  const [screenSize, setScreensize] = useState<any>(null);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
      setScreensize(getScreenSize(getWindowDimensions().windowWidth));
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getWindowDimensions() {
    const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
    return {
      windowWidth,
      windowHeight,
    };
  }

  function getScreenSize(windowWidth: number) {
    const isMobile = windowWidth < 1030;
    const isPhone = windowWidth < 768;

    return {
      isPhone,
      isMobile,
      isTablet: isMobile && !isPhone,
      isDesktop: !isMobile,
    };
  }

  const contextValue = {
    ...windowDimensions,
    ...screenSize,
  };

  return (
    <ScreensizeContext.Provider value={contextValue}>
      {children}
    </ScreensizeContext.Provider>
  );
};
