import React, { FC, useRef } from 'react';
import { IconType } from 'react-icons';
import cn from 'classnames';

import { useOutsideClickDetection } from '../hooks/useOutsideClickDetection';

type Option = {
  divider?: boolean;
  value: string;
  viewValue: string;
  hidden?: boolean;
  icon?: IconType;
};
interface DropdownProps {
  isOpen: boolean;
  menuClassname?: string;
  options: Option[];
  onDismiss: () => void;
  onSelect: (value: string) => void;
  style?: any;
}

export const Dropdown: FC<DropdownProps> = ({
  children,
  isOpen,
  menuClassname,
  options,
  onSelect,
  onDismiss,
  style = {},
}) => {
  const dropdownWrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClickDetection(dropdownWrapperRef, onDismiss);
  const optionsHasIcon = options.some(({ icon }) => !!icon);

  return (
    <div
      ref={dropdownWrapperRef}
      className="relative"
      onClick={() => isOpen && onDismiss()}
      style={{ ...style }}
    >
      {children}
      {isOpen && (
        <div
          className={cn(
            'origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5  divide-y divide-gray-100',
            menuClassname
          )}
        >
          <ul
            className="py-1 list-none px-0"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options.map(
              ({ viewValue, value, hidden, icon: Icon, divider }) =>
                !hidden && (
                  <li
                    key={value}
                    className="cursor-pointer group flex flex-wrap items-center text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                    onClick={() => onSelect(value)}
                  >
                    {divider && <hr className="w-10/12 mx-auto" />}
                    <div className="px-4 py-3 w-full flex relative">
                      {Icon && (
                        <Icon
                          size="1.25em"
                          className="absolute top-1/2 transform -translate-y-1/2"
                        />
                      )}

                      <span className={cn('ml-2', { 'ml-9': optionsHasIcon })}>
                        {viewValue}
                      </span>
                    </div>
                  </li>
                )
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
