import { SVG, Svg } from '@svgdotjs/svg.js';

export const getSubstractedCenterOffset = (el1, el2) => {
  const el1Rect = el1.getBoundingClientRect();
  const el2Rect = el2.getBoundingClientRect();

  return {
    offsetX: el2Rect.left - el1Rect.left + (el2Rect.width / 2),
    offsetY: el2Rect.top - el1Rect.top + (el2Rect.height / 2),
  };
};

export const svgStringToObject = (svgString: string): Svg => {
  return SVG(svgString.replace(/(\r\n|\n|\r)/gm, '')) as Svg;
}