import React, { FC } from 'react';
import { Input, Button } from '@common/components';
import { DeepMap, FieldError, Ref } from 'react-hook-form';

interface CreateProjectFormProps {
  errors: DeepMap<Record<string, any>, FieldError>;
  register: (ref: (any & Ref) | null) => void;
  handleSubmit: any;
  onSubmit: any;
  loading: boolean;
}
export const CreateProjectForm: FC<CreateProjectFormProps> = ({
  errors,
  register,
  handleSubmit,
  loading,
  onSubmit,
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <Input
      type="text"
      name="projectName"
      placeholder="Untitled Project"
      label="Give a name to your new project 🥳"
      error={!!errors.projectName}
      autoComplete="off"
      errorMessage={
        errors.projectName?.type === 'maxLength'
          ? 'Project name can contain up to 20 characters.'
          : undefined
      }
      ref={register({ required: true, maxLength: 20 })}
    />
    <Button type="submit" color="primary" className="text-white my-4 w-full">
      {loading ? 'Creating....' : 'Create Project'}
    </Button>
  </form>
);
