import { Button, Modal } from '@common/components';
import React, { useState } from 'react';
import cn from 'classnames';
import * as PlatformInstructions from './PlatformInstructions';

import {
  AiFillWindows as WindowsIcon,
  AiFillApple as MacOSIcon,
  AiFillTablet as IPadIcon,
} from 'react-icons/ai';

export enum Platforms {
  Windows = 'Windows',
  macOS = 'macOS',
  iPad = 'iPad',
}

export const HowToDownloadModal = ({ onDismiss, open }) => {
  const [platform, setPlatform] = useState<Platforms>(Platforms.macOS);

  const selectedClass = 'bg-gray-200 hover:bg-gray-200';

  return (
    <Modal
      isOpen={open}
      title="How to download your export"
      onDismiss={onDismiss}
    >
      <div className="text-center">
        <span className="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            onClick={() => setPlatform(Platforms.macOS)}
            type="button"
            className={cn(
              'relative inline-flex items-center px-8 py-4 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none',
              platform === Platforms.macOS && selectedClass
            )}
          >
            <MacOSIcon size={22} />
            <span className="ml-2">macOS</span>
          </button>
          <button
            onClick={() => setPlatform(Platforms.Windows)}
            type="button"
            className={cn(
              '-ml-px relative inline-flex items-center px-8 py-4 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none',
              platform === Platforms.Windows && selectedClass
            )}
          >
            <WindowsIcon size={22} />
            <span className="ml-2">Windows</span>
          </button>
          <button
            onClick={() => setPlatform(Platforms.iPad)}
            type="button"
            className={cn(
              '-ml-px relative inline-flex items-center px-8 py-4 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none',
              platform === Platforms.iPad && selectedClass
            )}
          >
            <IPadIcon size={22} />
            <span className="ml-2">iPad</span>
          </button>
        </span>
      </div>

      <div className="p-6">{PlatformInstructions[platform]()}</div>

      <div className="mt-2 flex">
        <div className="justify-end flex flex-grow items-end mt-2">
          <Button onClick={onDismiss} color="primary" style={{ padding: 12, width: 100 }}>
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  );
};
