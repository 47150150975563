import { useEffect } from 'react';
import firebase from 'firebase/app';
import { captureException } from '@sentry/react';

export const useUpdateLastUserActivity = (
  isLoading: boolean,
  userId: string | undefined
) => {
  useEffect(() => {
    if (!isLoading && userId) {
      firebase
        .firestore()
        .doc(`users/${userId}`)
        .update({
          lastActivity: new Date().getTime(),
        })
        .catch((e) => captureException(e));
    }
  }, [isLoading, userId]);
};
