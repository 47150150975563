import { deleteObject } from '@editor/reducers/canvas.slice';
import { useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import hotkeys from 'hotkeys-js';
import { ActionCreators } from 'redux-undo';

const registerHotkey = (combo: string, cb: Function) => {
  hotkeys(combo, (e) => {
    if (e.repeat) {
      return;
    }
    cb();
  });
};

const keyActions = {
  DELETE: (dispatch, state) => {
    const objectId = state.controls.controlledObject;
    if (!objectId) {
      return;
    }
    dispatch(deleteObject(objectId));
  },
  UNDO: (dispatch, _state) => {
    dispatch(ActionCreators.undo())
  },
  REDO: (dispatch, _state) => {
    dispatch(ActionCreators.redo())
  },
};

export const useKeyboardShortcuts = () => {
  const dispatch = useDispatch();
  const { getState } = useStore();

  useEffect(() => {
    // Delete
    registerHotkey('delete', () => keyActions.DELETE(dispatch, getState()));
    registerHotkey('backspace', () => keyActions.DELETE(dispatch, getState()));
    
    // Undo
    registerHotkey('⌘+z', () => keyActions.UNDO(dispatch, getState()));
    registerHotkey('ctrl+z', () => keyActions.UNDO(dispatch, getState()));

    // Redo
    registerHotkey('⌘+shift+z', () => keyActions.REDO(dispatch, getState()));
    registerHotkey('ctrl+shift+z', () => keyActions.REDO(dispatch, getState()));

    // Unbind all hotkeys
    return () => (hotkeys as any).unbind();
  }, [dispatch, getState]);
};
