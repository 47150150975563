import React from 'react';
import styled from 'styled-components';

const DecorationOne = styled.div`
  position: absolute;
  top: -120px;
  left: -120px;
  width: 550px;
  display: block;
`;

const DecorationTwo = styled.div`
  position: absolute;
  bottom: -80px;
  right: -80px;
  display: block;
`;

export const DecorationBackground = ({ children }) => {
  return (
    <div className="h-full w-full" style={{ background: '#fff' }}>
      <DecorationOne>
        <img src="https://static-assets-cdn.aws.posturize.app/images/decoration_1.png" alt="decoration" style={{ width: 550 }} />
      </DecorationOne>
      <DecorationTwo>
        <img src="https://static-assets-cdn.aws.posturize.app/images/decoration_2.png" alt="decoration" style={{ width: 650 }} />
      </DecorationTwo>
      <div className="h-full block relative">{children}</div>
    </div>
  );
};
