export const TOPBAR_HEIGHT = 60;

export const INSPECTOR_WIDTH = 240;
export const CANVAS_PADDING = 80;

export const DEFAULT_CANVAS_WIDTH = 2388;
export const DEFAULT_CANVAS_HEIGHT = 1668;

export const MIN_PAN_ZOOM_SCALE = 0.4;
export const MAX_PAN_ZOOM_SCALE = 3.0;

export const HUMAN_SVG_SIZE = 1687;