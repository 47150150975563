import { Modal } from '@common/components';
import React, { useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import topics from './topics';
import { useWindowSize } from '@editor/hooks/useWindowSize';
import { TOPBAR_HEIGHT } from '@editor/utils/editor.constants';

const TopicsContainer = styled.div`
  width: 200px;
  padding: 10px;
`;

const ContentContainer = styled.div<any>`
  background: #efefef;
  width: calc(980px - 80px);
  max-width: calc(100vw - 80px - 200px);
  height: calc(920px - 80px);
  max-height: ${props => props.windowHeight - TOPBAR_HEIGHT - 80}px;
`;

const Topic = styled.div<any>`
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 4px;

  &:hover {
    cursor: pointer;
    background: #dedede;
  }

  &.selected {
    color: white;
    background: #5861f1;
  }
`;

export const KnowledgeBaseModal = ({ isOpen, onDismiss }) => {
  const [selectedTopic, setSelectedTopic] = useState(0);
  const windowSize = useWindowSize();

  return (
    <Modal
      isOpen={isOpen}
      title="Knowledge Base"
      onDismiss={onDismiss}
      contentPadding={false}
      withCloseBtn
    >
      <div className="flex">
        <TopicsContainer>
          {topics.map(({ label }, idx) => (
            <Topic
              key={label}
              className={cn({ selected: selectedTopic === idx })}
              onClick={() =>
                idx !== selectedTopic ? setSelectedTopic(idx) : undefined
              }
            >
              {label}
            </Topic>
          ))}
        </TopicsContainer>
        <ContentContainer windowHeight={windowSize[1]} className="flex flex-wrap content-start overflow-hidden overflow-y-auto rounded-md p-4">
          <h1 className="text-2xl font-medium mb-3">
            {topics[selectedTopic].label}
          </h1>
          <div className="block w-full">{topics[selectedTopic].Content()}</div>
        </ContentContainer>
      </div>
    </Modal>
  );
};
