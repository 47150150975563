import { selectExporting, ExportType, openExportWizard, closeExportWizard, selectExportType } from '@editor/reducers/export.slice';
import { useDispatch, useSelector } from 'react-redux';

export const useExport = () => {
  const dispatch = useDispatch();
  const exporting = useSelector(selectExporting);
  const exportType = useSelector(selectExportType);

  return {
    exporting,
    exportType,
    openExportWizard: (type: ExportType) => dispatch(openExportWizard(type)),
    closeExportWizard: () => dispatch(closeExportWizard()),
  };
};