import React from 'react';
import { KnowledgeBaseTopic, DemoGIF } from '../knowledge-base.common';

export const topic: KnowledgeBaseTopic = {
  id: 'face_angle',
  label: 'Face Angle',
  premium: false,
  Content: () => (
    <div>
      You can play with the face angle to help you get accurate face guidelines.
      <div className="mt-6">
        <DemoGIF src="https://static-assets-cdn.aws.posturize.app/knowledge-base/HumanFace.gif" width={280} />
      </div>
    </div>
  ),
};
