import {
  useStripe,
  useElements,
  CardElement,
  IdealBankElement,
} from '@stripe/react-stripe-js';
import {
  ChangeStripePaymentMethod,
  CreateStripeSubscription,
  PaymentMethods,
  SubscribeRequest,
} from '../types';

export function useStripeSubscription() {
  const stripe = useStripe();
  const elements = useElements();
  const baseUrl = `${process.env.REACT_APP_API_URL}/subscription/stripe`;

  async function createPaymentMethod(selectedPaymentMethod) {
    let paymentMethodData;
    if (selectedPaymentMethod === PaymentMethods.CARD) {
      paymentMethodData = {
        type: 'card',
        card: elements.getElement(CardElement),
      };
    }
    if (selectedPaymentMethod === PaymentMethods.IDEAL) {
      paymentMethodData = {
        type: 'ideal',
        ideal: elements.getElement(IdealBankElement),
      };
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod(
      paymentMethodData
    );

    if (error) {
      throw error;
    }
    return paymentMethod;
  }

  async function changePaymentMethod(
    changeStripePaymentMethod: ChangeStripePaymentMethod
  ) {
    const { selectedPaymentMethod, userId } = changeStripePaymentMethod;
    const { id: paymentMethodId } = await createPaymentMethod(
      selectedPaymentMethod
    );
    if (!paymentMethodId || !userId) {
      throw Error(
        `One of the following is missing: paymentId: ${!paymentMethodId}, userId: ${!userId}`
      );
    }
    return fetch(`${baseUrl}/change-payment-method`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ paymentMethodId, userId }),
    });
  }

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
    userId,
  }) {
    if (subscription?.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result.error;
          }
          if (result.paymentIntent.status === 'succeeded') {
            // There's a risk of the customer closing the window before callback
            // execution. To handle this case, set up a webhook endpoint and
            // listen to invoice.paid. This webhook endpoint returns an Invoice.
            fetch(`${baseUrl}/approve-subscription`, {
              method: 'post',
              headers: {
                'Content-type': 'application/json',
              },
              body: JSON.stringify({ userId }),
            });
            return {
              priceId: priceId,
              subscription: subscription,
              invoice: invoice,
              paymentMethodId: paymentMethodId,
            };
          }
        });
    } else {
      // No customer action needed
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      );
      throw Error('Your card was declined.');
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function createSubscription(
    createStripeSubscription: CreateStripeSubscription
  ) {
    const { paymentMethodId, priceId, userId } = createStripeSubscription;
    return (
      fetch(`${baseUrl}/create-subscription`, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(createStripeSubscription),
      })
        .then((response) => response.json())
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result.error;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((subscription) => {
          return {
            paymentMethodId,
            priceId,
            subscription,
            userId,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
    );
  }

  async function subscribe(createSubscriptionRequest: SubscribeRequest) {
    const {
      userId,
      email,
      organization,
      selectedPaymentMethod,
      priceId,
      couponId,
    } = createSubscriptionRequest;
    const { id: paymentMethodId } = await createPaymentMethod(
      selectedPaymentMethod
    );
    if (!paymentMethodId || !priceId) {
      throw Error(
        `One of the following is missing: paymentId: ${!paymentMethodId}, priceId: ${!priceId}`
      );
    }
    try {
      const { subscription } = await createSubscription({
        paymentMethodId,
        priceId,
        userId,
        email,
        organization,
        couponId,
      });

      return subscription;
    } catch (e) {
      throw e;
    }
  }

  return {
    subscribe,
    changePaymentMethod,
  };
}
