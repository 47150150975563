import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { LoginContainer } from './containers';
import { ForgotPasswordContainer } from './containers/ForgotPasswordContainer';
import { ResetPasswordContainer } from './containers/ResetPasswordContainer';
import { DecorationBackground } from '../+ui/DecorationBackground';
import { VerifyEmailContainer } from './containers/VerifyEmailContainer';

export const LoginRoute: FC<{
  forgotPassword?: boolean;
  verifyEmail?: boolean;
}> = ({ forgotPassword, verifyEmail }) => {
  const { search } = useLocation();
  const code = new URLSearchParams(search)?.get('oobCode');

  const determineComponentToRender = () => {
    if (verifyEmail && code) {
      return <VerifyEmailContainer verifyCode={code} />;
    }

    if (forgotPassword && code) {
      return <ResetPasswordContainer resetCode={code} />;
    }
    if (forgotPassword && !code) {
      return <ForgotPasswordContainer />;
    }

    return <LoginContainer />;
  };

  return (
    <DecorationBackground>{determineComponentToRender()}</DecorationBackground>
  );
};
