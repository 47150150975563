import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/app';
import { EditorContainer } from './EditorContainer';
import { useDispatch } from 'react-redux';
import { useProjectContext } from '../common/context/project';
import { setCurrentCanvasState } from './reducers/canvas.slice';
import { DefaultMuiTheme } from '../config/mui.theme';
import { MuiThemeProvider } from '@material-ui/core';
import { useDocumentOnce } from 'react-firebase-hooks/firestore';
import { ActionCreators } from 'redux-undo';

export const EditorRoute = () => {
  const dispatch = useDispatch();
  const { canvasId } = useParams<{ canvasId: string }>();
  const { currentProject, loadProject } = useProjectContext();

  const [canvas, loadingCanvas] = useDocumentOnce(firebase.firestore().doc(`canvases/${canvasId}`));

  useEffect(() => {
    if (canvas) {
      const canvasData = canvas.data();
      delete canvasData.updatedAt;
      dispatch(setCurrentCanvasState(canvasData));
      dispatch(ActionCreators.clearHistory());
    }
  }, [canvas, dispatch]);

  useEffect(() => {
    if (!currentProject && !loadingCanvas) {
      loadProject(canvasId);
    }

  }, [currentProject, canvasId, loadProject, loadingCanvas]);

  if (!canvasId) {
    return <>Invalid canvas</>;
  }

  return (
    <MuiThemeProvider theme={DefaultMuiTheme}>
      <EditorContainer />
    </MuiThemeProvider>
  );
};
