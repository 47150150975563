import React from 'react';
import styled from 'styled-components';
import saveOnMacOS from './assets/save-on-macos.png';
import saveOnIpad from './assets/save-on-ipad.png';
import saveOnWindows from './assets/save-on-windows.jpeg';

const SaveImage = styled.img`
  margin: auto;
  max-width: 260px;
  border-radius: 8px;
`;

export const macOS = () => (
  <>
    <ol className="list-decimal mb-4">
      <li>Go back to the Export screen (close this dialog)</li>
      <li>Right click the canvas image</li>
      <li>Choose "Save Image As..."</li>
    </ol>

    <SaveImage src={saveOnMacOS} alt="Save Instructions macOS" />
  </>
);

export const Windows = () => (
  <>
    <ol className="list-decimal mb-4">
      <li>Go back to the Export screen (close this dialog)</li>
      <li>Right click the canvas image</li>
      <li>Choose "Save image as"</li>
    </ol>

    <SaveImage src={saveOnWindows} alt="Save Instructions macOS" />
  </>
);

export const iPad = () => (
  <>
    <ol className="list-decimal mb-4">
      <li>Go back to the Export screen (close this dialog)</li>
      <li>Long press the canvas image</li>
      <li>Choose "Add to Photos"</li>
    </ol>

    <SaveImage src={saveOnIpad} alt="Save Instructions macOS" />
  </>
);
