import React, { FC } from 'react';
import { DecorationBackground } from '../+ui/DecorationBackground';
import { SignUpContainer } from './containers';

export const SignUpRoute: FC = () => {
  return (
    <DecorationBackground>
      <SignUpContainer />
    </DecorationBackground>
  );
};
