import { createSlice } from '@reduxjs/toolkit';
import * as CanvasService from '@editor/utils/canvas.service';
import * as canvasObjectUtils from '@editor/utils/canvasObjectUtils';
import * as EditorConstants from '@editor/utils/editor.constants';
import { controlsSlice } from './controls.slice';
import { Canvas, CanvasSkeleton } from '@editor/utils/canvas.types';

const initialState: Canvas = {
  slug: null,
  ownerId: null,
  canvasId: null,
  objects: {},
  properties: {
    width: EditorConstants.DEFAULT_CANVAS_WIDTH,
    height: EditorConstants.DEFAULT_CANVAS_HEIGHT,
  },
  layers: [],
  schemaVersion: 1,
};

export const canvasSlice = createSlice({
  name: 'canvas',
  initialState,
  reducers: {
    setCurrentCanvasState: (state, action) => {
      const { canvasId, objects, layers, schemaVersion, ownerId, disabled, disableReason } = action.payload;
      state.canvasId = canvasId;
      state.ownerId = ownerId;
      state.objects = objects;
      state.layers = layers;
      state.schemaVersion = schemaVersion;

      if (disabled) {
        state.disabled = disabled;
        state.disableReason = disableReason;
      }
    },
    _setObjectBoneProperty: (state, action) => {
      const { objectId, boneName, property, value } = action.payload;
      const object = state.objects[objectId] as CanvasSkeleton;
      const bone = object.bones[boneName];
      (bone as any).properties[property] = value;
    },
    _setObjectProperty: (state, action) => {
      const { objectId, property, value } = action.payload;
      const object = state.objects[objectId];
    
      object.properties = {
        ...object.properties,
        [property]: value,
      };
    },
    _setBulkObjectProperties: (state, action) => {
      const { objectId, properties } = action.payload;
      const object = state.objects[objectId];

      object.properties = { ...object.properties, ...properties };
    },
    _addObject: (state, action) => {
      const object = action.payload;
      state.objects[object.id] = object;
      state.layers.push(object.id);
    },
    _replaceObject: (state, action) => {
      const { originalObjectId, newObject } = action.payload;
      state.objects = {
        ...state.objects,
        [originalObjectId]: newObject,
      }
    },
    _replaceObjectBones: (state, action) => {
      const { objectId, bones } = action.payload;
      const object = state.objects[objectId] as CanvasSkeleton;
      object.bones = bones;
    },
    _deleteObject: (state, action) => {
      const { objectId } = action.payload;
      delete state.objects[objectId];
      state.layers = state.layers.filter(layerObjectId => layerObjectId !== objectId);
    },
    _setLayers: (state, action) => {
      state.layers = action.payload;
    },
    _renameObject: (state, action) => {
      const { objectId, name } = action.payload;
      state.objects[objectId].name = name;
    },
  },
})

export const { setCurrentCanvasState } = canvasSlice.actions;

export const setObjectProperty = (objectId: string, property: string, value: any) => async (dispatch, getState) => {
  dispatch(canvasSlice.actions._setObjectProperty({ objectId, property, value }));
  const canvasState = getState().canvas;
  await CanvasService.saveCanvas(canvasState.present);
};

export const setBulkObjectProperties = (objectId: string, properties) => async (dispatch, getState) => {
  dispatch(canvasSlice.actions._setBulkObjectProperties({ objectId, properties }));
  const canvasState = getState().canvas;
  await CanvasService.saveCanvas(canvasState.present);
};

export const replaceObjectBones = (objectId: string, bones) => async (dispatch, getState) => {
  dispatch(canvasSlice.actions._replaceObjectBones({ objectId, bones }));
  const canvasState = getState().canvas;
  await CanvasService.saveCanvas(canvasState.present);
};

export const setObjectBoneProperty = (objectId: string, boneName: string, property: string, value: any) => async (dispatch, getState) => {
  dispatch(canvasSlice.actions._setObjectBoneProperty({ objectId, boneName, property, value }));
  const canvasState = getState().canvas;
  await CanvasService.saveCanvas(canvasState.present);
};

export const createHumanObject = () => async (dispatch, getState) => {
  const object = canvasObjectUtils.createHumanObject();
  dispatch(canvasSlice.actions._addObject(object));
  const canvasState = getState().canvas;
  await CanvasService.saveCanvas(canvasState.present);
};

export const addObject = (object: any) => async (dispatch, getState) => {
  dispatch(canvasSlice.actions._addObject(object));
  const canvasState = getState().canvas;
  await CanvasService.saveCanvas(canvasState.present);
};

export const replaceObject = (originalObjectId: string, newObject) => async(dispatch, getState) => {
  dispatch(canvasSlice.actions._replaceObject({ originalObjectId, newObject }));
  const canvasState = getState().canvas;
  await CanvasService.saveCanvas(canvasState.present);
};

export const deleteObject = (objectId: string) => async(dispatch, getState) => {
  const { controls } = getState();
  if (controls.controlledObject === objectId) {
    dispatch(controlsSlice.actions.resetControls());
  }

  dispatch(canvasSlice.actions._deleteObject({ objectId }));
  const canvasState = getState().canvas;
  await CanvasService.saveCanvas(canvasState.present);
};

export const setLayers = (layers: string[]) => async(dispatch, getState) => {
  dispatch(canvasSlice.actions._setLayers(layers));
  const canvasState = getState().canvas;
  await CanvasService.saveCanvas(canvasState.present);
};

export const renameObject = (objectId: string, name: string) => async(dispatch, getState) => {
  dispatch(canvasSlice.actions._renameObject({ objectId, name} ));
  const canvasState = getState().canvas;
  await CanvasService.saveCanvas(canvasState.present);
};

export const selectCanvas = state => state.canvas.present;
export const selectCanvasProperty = (property: string) => state => state.canvas.present.properties[property];
export const selectObjects = state => state.canvas.present.objects;
export const selectObject = (objectId: string) => state => state.canvas.present.objects[objectId];
export const selectObjectBoneProperty = (objectId: string, boneName: string, property: string) => state => state.canvas.present.objects[objectId].bones[boneName].properties[property];
export const selectObjectProperty = (objectId: string, property: string) => state => state.canvas.present.objects[objectId].properties[property];
export const selectLayers = state => state.canvas.present.layers;

export default canvasSlice.reducer;