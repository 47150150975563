import React, { FC, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useAuthContext, useInvitationContext } from '@common/context';
import { SignUpFormDetails } from '@common/types';
import { Input, Button, GoogleButton, Loader } from '@common/components';
import { Link } from 'react-router-dom';
import { AnalyticsEvents, useAnalytics } from '@common/analytics/analytics.util';

export const SignUpContainer: FC = () => {
  const { signUp, signInWithGoogle, signUpError } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const { invitation } = useInvitationContext();
  const { logEvent } = useAnalytics();
  const { register, handleSubmit, errors, watch } = useForm<SignUpFormDetails>({
    defaultValues: {
      email: invitation?.email,
    },
  });
  const password = useRef({});
  password.current = watch('password', '');

  const history = useHistory();

  logEvent(AnalyticsEvents.SIGN_UP_PAGE_VISIT);

  const handleGoogleSignUp = async () => {
    setLoading(true);
    try {
      logEvent(AnalyticsEvents.SIGN_IN_SSO_START);
      await signInWithGoogle?.(invitation).finally(() => {
        history.push('/projects');
      });
    } catch (error) {}
    setLoading(false);
  };

  const handleSignup = async (values: SignUpFormDetails) => {
    setLoading(true);
    logEvent(AnalyticsEvents.SIGN_UP_PASSWORD_SUBMIT);
    signUp?.(values, invitation)
      .then(() => history.push('/projects'))
      .catch((err) => {
        // Already caught and will be displayed by AuthContext
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="h-full">
      <div className="h-full flex max-w-lg m-auto items-center justify-center">
        <div className="w-full px-8 py-8 bg-white shadow-xl rounded-2xl">
          <h1 className="font-light text-3xl py-4 text-center font-medium">
            Start using Posturize for free
          </h1>

          <form onSubmit={handleSubmit(handleSignup)} autoComplete="off">
            <p className="text-warning font-semibold my-4">{signUpError}</p>

            <Input
              ref={register({
                required: { value: true, message: 'Email is required' },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Enter a valid e-mail address',
                },
              })}
              name="email"
              type="email"
              error={!!errors?.email}
              errorMessage={errors?.email?.message}
              placeholder="Email"
              className="mb-8"
            />
            <Input
              ref={register({
                required: { value: true, message: 'Password is required' },
                minLength: {
                  value: 4,
                  message: 'Password must be at least 4 characters long',
                },
                maxLength: {
                  value: 16,
                  message: 'Password must maximum 16 characters long',
                },
              })}
              name="password"
              type="password"
              error={!!errors?.password}
              errorMessage={errors?.password?.message}
              placeholder="Password"
              className="mb-8"
            />
            <Input
              ref={register({
                required: {
                  value: true,
                  message: 'Confirm password is required',
                },
                validate: (value) =>
                  value === password.current || 'The passwords do not match',
              })}
              name="password_repeat"
              type="password"
              error={!!errors?.password_repeat}
              errorMessage={errors?.password_repeat?.message}
              placeholder="Confirm Password"
              className="mb-8"
            />

            <Button
              color="primary"
              size="large"
              type="submit"
              className="w-full"
              onClick={handleSubmit(handleSignup)}
              disabled={loading}
            >
              {loading ? (
                <Loader className="flex justify-center" />
              ) : (
                ' Sign up for free'
              )}
            </Button>
          </form>
          <span className="text-center block my-4 text-gray-400">— or —</span>
          <GoogleButton
            className="mb-4"
            label="Sign up with Google"
            onClick={handleGoogleSignUp}
          />

          <Link
            to="/login"
            className="text-primary hover:text-primary-dark block text-center mt-4"
          >
            I already have an account
          </Link>

          <p className="text-center" style={{ marginTop: 12, fontSize: 12 }}>
            By signing up to Posturize, you agree to our{' '}
            <Link className="font-medium" to="/terms">
              Terms of Service, Privacy Policy and Beta Terms of Service
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
