import React, { FC, useState } from 'react';
import firebase from 'firebase/app';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocumentData } from 'react-firebase-hooks/firestore';
// import { cfaSignIn } from 'capacitor-firebase-auth'; //DO NOT REMOVE, sign in for mobile apps

import { SignUpFormDetails, User, Invitation } from '@common/types';
import { addUser, addUserToOrganization } from '@common/services/auth.service';

import { AuthContext } from './AuthContext';

export const AuthProvider: FC = ({ children }) => {
  const [currentUser, loading, error] = useAuthState(firebase.auth());

  const [userData] = useDocumentData<User>(
    firebase.firestore().doc(`users/${currentUser?.uid}`)
  );

  const [signUpError, setSignUpError] = useState('');

  const signUp = async (
    { email, password }: SignUpFormDetails,
    invitation?: Invitation
  ) => {
    try {
      const userCredential = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);

      await userCredential.user.sendEmailVerification();

      await addUser(userCredential);

      if (userCredential.user.email === invitation?.email) {
        await addUserToOrganization(userCredential.user.uid, invitation);
      }

      return userCredential;
    } catch (error) {
      setSignUpError(error.message);
      throw error.message;
    }
  };

  const signOut = async () => await firebase.auth().signOut();

  const sendPasswordResetEmail = async (email: string) => {
    try {
      return await firebase.auth().sendPasswordResetEmail(email);
    } catch (error) {}
  };

  const signInWithEmailPassword = async (email: string, password: string) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signInWithGoogle = async (invitation: Invitation) => {
    try {
      const userCredential = await firebase
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider());

      if (userCredential?.additionalUserInfo.isNewUser) {
        await addUser(userCredential);
      }

      if (invitation && invitation.email === userCredential.user.email) {
        await addUserToOrganization(userCredential.user.uid, invitation);
      }
    } catch (error) {
      throw error;
    }
  };

  const contextValue = {
    signInWithGoogle,
    signOut,
    signUp,
    signInWithEmailPassword,
    sendPasswordResetEmail,
    currentUser,
    userData,
    loading,
    error,
    signUpError,
  };
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
