import { Canvas } from '../canvas.types';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { generateCanvasThumbnail } from './save.utils';
import { Logger } from '@common/utils/logger';

export abstract class BaseSaveDriver {
  protected logger: Logger;
  protected readonly saveCanvas$ = new Subject();
  protected readonly saveThumbnail$ = new Subject();

  constructor(
    protected readonly SAVE_CANVAS_DEBOUNCE_TIME: number,
    protected readonly SAVE_THUMBNAIL_DEBOUNCE_TIME: number,
  ) {
    this.saveCanvas$.pipe(
      debounceTime(this.SAVE_CANVAS_DEBOUNCE_TIME)
    ).subscribe(async (canvas: Canvas) => {
      await this._saveCanvas(canvas);

      this.saveThumbnail$.next(canvas.canvasId);
    });
   
    this.saveThumbnail$.pipe(
      debounceTime(this.SAVE_THUMBNAIL_DEBOUNCE_TIME)
    ).subscribe(async (canvasId: string) => {
      const thumbnailImageBlob = await generateCanvasThumbnail();
      this._saveThumbnail(canvasId, thumbnailImageBlob)
    });
  }

  public async save(canvas: Canvas) {
    this.saveCanvas$.next(canvas);
  }
  
  protected abstract _saveCanvas(canvas: Canvas): void;
  protected abstract _saveThumbnail(canvasId: string, string: string): void;
}
