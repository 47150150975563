import { useWindowSize } from '@editor/hooks/useWindowSize';
import { selectCanvasProperty } from '@editor/reducers/canvas.slice';
import {
  selecgtPanEnabled,
  setPanZoomScale,
} from '@editor/reducers/panzoom.slice';
import { calcDimensions } from '@editor/utils/canvas.utils';
import { MAX_PAN_ZOOM_SCALE } from '@editor/utils/editor.constants';
import { isStylusEvent } from '@editor/utils/touch.utils';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const usePanZoom = () => {
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const width = useSelector(selectCanvasProperty('width'));
  const height = useSelector(selectCanvasProperty('height'));
  const scale = calcDimensions(width, height);
  const panEnabled = useSelector(selecgtPanEnabled);
  const controlsRef = useRef();

  useEffect(() => {
    dispatch(setPanZoomScale(scale));
  }, [windowSize, dispatch, scale]);

  const fullScreenStep = windowSize[0] * 0.04;

  const transformProps = {
    onZoomChange: ({ scale }) => {
      dispatch(setPanZoomScale(scale));
    },
    onPanningStart: ({ event }) => {
      const isStylus = isStylusEvent(event);

      if (isStylus) {
        (window as any).panZoomOverrides.disabled = true;
      }
    },
    onPanningStop: () => {
      (window as any).panZoomOverrides.disabled = false;
    },
    onPanning: () => {},
    pan: {
      disabled: !panEnabled,
    },
    scalePadding: {},
    options: {
      minScale: scale,
      maxScale: MAX_PAN_ZOOM_SCALE,
      limitToBounds: false,
    },
    scale: scale,
    wheel: {
      step: fullScreenStep,
    },
  };

  return {
    transformProps,
    controlsRef,
    scale,
  };
};
