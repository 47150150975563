import React, { useEffect, useState } from 'react';

import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { IoCubeOutline as AddAssetIcon } from 'react-icons/io5';
import { IoBodyOutline as AddHumanIcon } from 'react-icons/io5';
import { AddLibraryAssetModal } from '../canvas/object-types/Asset/AddLibraryAssetModal';
import { useCanvasV2 } from '@editor/hooks/useCanvasV2';
import { usePermission } from '@common/hooks/usePermission';
import {
  AnalyticsEvents,
  useProjectAnalytics,
} from '@common/analytics/analytics.util';
import { useSubscriptionContext } from '@common/context/subscription/useSubscriptionContext';
import { useAssetLibrary } from '../canvas/useAssetLibrary';
import {
  AssetCategories,
  Asset,
} from '../canvas/object-types/Asset/asset.types';
import Chip from '@material-ui/core/Chip';
import { AddUserAssetModal } from '../canvas/object-types/Asset/UserAsset/AddUserAssetModal';
import { UserAsset } from '../canvas/object-types/Asset/user-asset.types';

export const AddObjectMenu = (props: MenuProps) => {
  const { onClose } = props;
  const [isAddLibraryAssetModalOpen, setIsAddLibraryAssetModelOpen] = useState(
    false
  );
  const [isAddUserAssetModalOpen, setIsAddUserAssetModalOpen] = useState(false);
  const { permissions } = useSubscriptionContext();
  const canvasObjectsLimitPermission = usePermission(
    permissions.CANVAS_OBJECTS_LIMIT
  );
  const canvasCanUseAssetsPermission = usePermission(
    permissions.CANVAS_CAN_USE_ASSETS
  );
  const { addHumanObject, objectsAmount, addSvgAssetObject, addUserAssetObject } = useCanvasV2();
  const {
    assetLibraryState,
    fetchCategories,
    dispatchFetchAssets,
  } = useAssetLibrary();
  const [selectedCategory, setSelectedCategory] = useState<AssetCategories>(
    AssetCategories.ALL
  );
  const { logEvent } = useProjectAnalytics();

  useEffect(() => {
    if (isAddLibraryAssetModalOpen === true) {
      fetchCategories();
    }
  }, [isAddLibraryAssetModalOpen]); // eslint-disable-line

  const isPermittedToAddObject =
    objectsAmount < canvasObjectsLimitPermission.value;
  const isPermittedToUseAssets = canvasCanUseAssetsPermission.value;

  const handleAddHuman = () => {
    onClose(null, null);
    logEvent(AnalyticsEvents.EDITOR_ADD_HUMAN_ATTEMPT);

    if (!isPermittedToAddObject) {
      return canvasObjectsLimitPermission.displayModal();
    }

    logEvent(AnalyticsEvents.EDITOR_ADD_HUMAN);
    addHumanObject();
  };

  const handleAddLibraryAsset = () => {
    onClose(null, null);
    setIsAddLibraryAssetModelOpen(true);
    dispatchFetchAssets();

    logEvent(AnalyticsEvents.EDITOR_ADD_HUMAN_ATTEMPT);
  };

  const handleAddUserAsset = () => {
    onClose(null, null);
    setIsAddUserAssetModalOpen(true);
    // dispatchFetchAssets();

    logEvent(AnalyticsEvents.EDITOR_ADD_HUMAN_ATTEMPT);
  };

  const handleLibraryAssetSelected = (asset: Asset) => {
    setIsAddLibraryAssetModelOpen(false);
    const { id, category } = asset;
    logEvent(AnalyticsEvents.EDITOR_ADD_ASSET_MODAL_OPEN, {
      id,
      category,
    });

    if (!isPermittedToUseAssets) {
      return canvasCanUseAssetsPermission.displayModal({ id });
    }

    logEvent(AnalyticsEvents.EDITOR_ADD_ASSET, { id, category });

    addSvgAssetObject(asset);
  };

  const handleUserAssetSelected = (userAsset: UserAsset) => {
    setIsAddLibraryAssetModelOpen(false);
    const { id, uploadedBy } = userAsset;

    if (!isPermittedToUseAssets) {
      return canvasCanUseAssetsPermission.displayModal({ id });
    }

    logEvent(AnalyticsEvents.EDITOR_ADD_CUSTOM_ASSET, { id, uploadedBy });

    addUserAssetObject(userAsset);
  }

  return (
    <>
      <AddLibraryAssetModal
        isOpen={isAddLibraryAssetModalOpen}
        onAssetSelected={handleLibraryAssetSelected}
        assets={assetLibraryState.assets?.[selectedCategory]}
        categories={assetLibraryState.categories}
        loading={assetLibraryState.loading}
        selectedCategory={selectedCategory}
        onCategorySelected={(categoryId: AssetCategories) => {
          setSelectedCategory(categoryId);
        }}
        onDismiss={() => setIsAddLibraryAssetModelOpen(false)}
      />

      {isAddUserAssetModalOpen && (
        <AddUserAssetModal
          isOpen={true}
          onAssetSelected={handleUserAssetSelected}
          onDismiss={() => setIsAddUserAssetModalOpen(false)}
        />
      )}

      <Menu {...props}>
        <MenuItem onClick={handleAddHuman}>
          <ListItemIcon>
            <AddHumanIcon size={18} />
          </ListItemIcon>
          <ListItemText>Human</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleAddLibraryAsset}>
          <ListItemIcon>
            <AddAssetIcon size={18} />
          </ListItemIcon>
          <ListItemText>Library Asset</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleAddUserAsset}>
          <ListItemIcon>
            <AddAssetIcon size={18} />
          </ListItemIcon>
          <ListItemText>
            Custom Asset
            <Chip style={{ marginLeft: 12 }} label="NEW" />
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
