import React, { FC } from 'react';
import cn from 'classnames';

import { ButtonProps, Button } from './Button';

type IconButtonProps = Omit<ButtonProps, 'color'>;

export const IconButton: FC<IconButtonProps> = ({ children, ...props }) => (
  <Button
    color="transperent"
    className={cn('text-gray-600', props?.className)}
    {...props}
  >
    {children}
  </Button>
);
