import { Button } from '@common/components';
import React from 'react';

export const BrowseExistingView = ({
  assets,
  onSelectAsset,
  onDeleteAsset,
}) => {
  return (
    <div>
      <div className="p-4 flow-root">
        <ul className="-my-6 divide-y divide-gray-200">
          {assets.map((asset) => (
            <li key={asset.id} className="py-6 px-4 flex">
              <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                <img
                  src={asset.fileUrl}
                  alt="An asset"
                  className="w-full h-full object-center object-cover"
                />
              </div>

              <div className="ml-4 flex-1 flex flex-col">
                <div>
                  <div className="flex justify-between text-base font-medium text-gray-900">
                    <h3>
                      <Button
                        color="#000"
                        className="font-medium"
                        variant="link"
                        onClick={() => onSelectAsset(asset)}>
                        {asset.name}
                      </Button>
                    </h3>
                  </div>
                </div>
                <div className="flex-1 flex items-end flex-row-reverse justify-between text-sm">
                  <div className="flex">
                    <Button
                      color="primary"
                      variant="link"
                      onClick={() => onDeleteAsset(asset.id)}>
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/* <ul>
        {assets.map((asset) => (
          <li key={asset._id} classNameName="mb-2 text-primary cursor-pointer">
            <div>
              <span onClick={() => onSelectAsset(asset)}>
                {asset.name} ({asset._id}){' '}
              </span>
              <span
                onClick={() => onDeleteAsset(asset._id)}
                classNameName="text-red-600">
                delete
              </span>

              <img src={asset.fileUrl} alt="preview" width={40} />
            </div>
          </li>
        ))}
      </ul> */}
    </div>
  );
};
