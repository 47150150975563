import React, { useEffect } from 'react';
import { useLocation, Redirect, useHistory } from 'react-router';

enum ForwardModes {
  VERIFY_EMAIL = 'verifyEmail',
  RESET_PASSWORD = 'resetPassword',
}

export const ForwarderRoute = () => {
  const { search } = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(search);

  const code = urlParams?.get('oobCode');
  const mode = urlParams?.get('mode');

  useEffect(() => {
    if (!code || !mode) {
      return;
    }
    if (mode === ForwardModes.RESET_PASSWORD) {
      history.push(`/forgot-password?oobCode=${code}`);
    } else if (mode === ForwardModes.VERIFY_EMAIL) {
      history.push(`/verify-email?oobCode=${code}`);
    }
  }, [mode, code, history]);

  return <Redirect to="/" />;
};
