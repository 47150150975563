import { Button, Loader, Modal } from '@common/components';
import React, { useState, useEffect, useCallback } from 'react';
import { BrowseExistingView } from './BrowseExistingView';
import { PreviewUploadView } from './PreviewUploadView';
import { UploadFileView } from './UploadFileView';
import * as UserAssetsService from './user-assets.service';
import { useAuthContext } from '@common/context';
import { usePermission } from '@common/hooks/usePermission';
import { useSubscriptionContext } from '@common/context/subscription/useSubscriptionContext';

enum View {
  UPLOAD = 'UPLOAD',
  PREVIEW = 'PREVIEW',
  BROWSE = 'BROWSE',
}

interface UploadData {
  base64: string;
  type: 'image/jpeg' | 'image/png' | 'image/svg+xml';
  width: number;
  height: number;
}

export const AddUserAssetModal = ({ isOpen, onDismiss, onAssetSelected }) => {
  const [view, setView] = useState<View>(View.BROWSE);
  const [uploadData, setUploadData] = useState<UploadData>(null);
  const [accessToken, setAccessToken] = useState(null);
  const [assets, setAssets] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [, setAssetName] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const { currentUser } = useAuthContext();
  const { permissions } = useSubscriptionContext();

  const customAssetsPermission = usePermission(permissions.CUSTOM_ASSETS_LIMIT);

  const fetchUserAssets = useCallback(async () => {
    setIsLoading(true);
    const assets = await UserAssetsService.getAssets(accessToken);
    setAssets(assets);
    setIsLoading(false);
  }, [accessToken]);

  // get access token
  useEffect(() => {
    const getToken = async () => {
      const token = await currentUser.getIdToken(true);
      setAccessToken(token);
    };
    getToken();
  }, [currentUser]);

  useEffect(() => {
    if (view === View.BROWSE && accessToken) {
      fetchUserAssets();
    }
  }, [accessToken, view, fetchUserAssets]);

  const handleFileUploaded = (data: UploadData) => {
    setUploadData(data);
    setView(View.PREVIEW);
  };

  const handleConfirmCreateAsset = async (assetName: string) => {
    setAssetName(assetName);
    setIsUploading(true);
    try {
      const asset = await UserAssetsService.createAsset(
        assetName,
        uploadData,
        accessToken
      );
      onAssetSelected(asset);
      onDismiss();
    } catch (error) {
      throw new Error('Error while uploading custom asset');
    }
    setIsUploading(false);
  };

  const handleDeleteAsset = async (assetId: string) => {
    const assetsCopy = assets;
    try {
      await UserAssetsService.deleteAsset(assetId, accessToken);
      setAssets((prevAssets) =>
        prevAssets.filter((asset) => asset.id !== assetId)
      );
    } catch (error) {
      setAssets(assetsCopy);
      throw new Error(`Error while deleting asset ${assetId}`);
    }
  };

  const handleAssetUploadClick = () => {
    if (assets?.length >= customAssetsPermission.value) {
      customAssetsPermission.displayModal();
    } else {
      setView(View.UPLOAD);
    }
  };

  const renderView = () => {
    switch (view) {
      case View.BROWSE:
        return (
          <BrowseExistingView
            assets={assets}
            onDeleteAsset={handleDeleteAsset}
            onSelectAsset={onAssetSelected}
          />
        );
      case View.PREVIEW:
        return (
          <PreviewUploadView
            base64={uploadData.base64}
            onConfirm={handleConfirmCreateAsset}
            isUploading={isUploading}
          />
        );
      case View.UPLOAD:
        return <UploadFileView onFileUploaded={handleFileUploaded} />;
    }
  };

  const getModalTitle = () => {
    switch (view) {
      case View.BROWSE:
        return 'Browse Your Assets';
      case View.UPLOAD:
        return 'Upload New Asset';
      case View.PREVIEW:
        return 'Almost done...';
    }
  };

  return (
    <Modal
      title={getModalTitle()}
      subtitle={
        view === View.BROWSE && (
          <div style={{ width: 450 }}>
            <div>
              Choose from your existing assets, or{' '}
              <Button
                variant="link"
                color="primary"
                onClick={handleAssetUploadClick}>
                Upload new asset
              </Button>
            </div>
          </div>
        )
      }
      contentPadding={false}
      isOpen={isOpen}
      onDismiss={onDismiss}>
      <div className="overflow-y-auto p-4" style={{ height: 350 }}>
        {isLoading ? (
          <div className="flex flex-col h-full justify-center items-center">
            <Loader size="medium" />
            <h1 className="mt-2 text-center text-lg">Loading...</h1>
          </div>
        ) : !assets?.length && view === View.BROWSE ? (
          <div className="flex flex-col h-full justify-center items-center">
            <h1 className="my-2 text-center text-lg">
              You have no custom assets.
            </h1>
            <Button
              color="primary"
              className="px-4"
              onClick={handleAssetUploadClick}>
              + Upload new asset
            </Button>
          </div>
        ) : (
          <div style={{ maxHeight: '90vh' }}>{renderView()}</div>
        )}
      </div>
    </Modal>
  );
};
