import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import firebase from 'firebase/app';

import { useAuthContext } from '@common/context';

import { ProjectsContainer } from './containers/ProjectsContainer';
import { Header, ErrorMessageModal } from '@common/components';
import useControls from '@editor/hooks/useControls';
import { captureException } from '@sentry/react';
import { Link } from 'react-router-dom';

export const ProjectsRoute = () => {
  const { currentUser } = useAuthContext();
  const { resetControls } = useControls();
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    resetControls();
  });

  return (
    <>
      <Header />
      <div className="max-w-screen-xl md:px-12 m-auto">
        <div className="mx-12 mx-auto my-4 p-12 rounded-lg bg-secondary">
          <ErrorBoundary
            FallbackComponent={ErrorMessageModal}
            onReset={() => setShowErrorModal(false)}
            onError={(e) => captureException(e)}
            resetKeys={[showErrorModal]}
          >
            <ProjectsContainer user={currentUser as firebase.User} />
          </ErrorBoundary>
        </div>
        <p className="text-center" style={{ marginTop: 12, fontSize: 12 }}>
          By using Posturize, you agree to our{' '}
          <Link className="font-medium" to="/terms" target="_blank">
            Terms of Service, Privacy Policy and Beta Terms of Service
          </Link>
        </p>

        <p className="text-center" style={{ marginTop: 12, fontSize: 12 }}>
          <Link className="font-medium text-primary" to="/contact" target="_blank">
            Report a Problem
          </Link>
        </p>
      </div>
    </>
  );
};
