import Axios from 'axios';

const assetsServiceUrlMap = {
  prod: 'https://user-assets-service.posturize-api.com',
  dev: 'https://user-assets-service.dev.posturize-api.com',
};

const assetsServiceUrl =
  assetsServiceUrlMap[process.env.REACT_APP_DEPLOYMENT_STAGE] ||
  assetsServiceUrlMap.prod;

function dataURItoBlob(dataURI) {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
}

export const getAssets = async (idToken) => {
  const result = await Axios.get(`${assetsServiceUrl}/user-assets`, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });

  const assets = result.data.map(normalizeAsset);
  return assets;
};

export const createAsset = async (assetName: string, file, idToken) => {
  const formData = new FormData();
  const blob = dataURItoBlob(file.base64);
  formData.append('file', blob);
  formData.append('name', assetName);
  formData.append('fileType', file.type);
  formData.append('width', file.width);
  formData.append('height', file.height);

  const result = await Axios.post(`${assetsServiceUrl}/user-assets`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${idToken}`,
    },
  });

  const asset = normalizeAsset(result.data);
  return asset;
};

const normalizeAsset = (asset) => ({
  ...asset,
  metadata: {
    ...asset.metadata,
    width: parseInt(asset.metadata.width, 10),
    height: parseInt(asset.metadata.height, 10),
  },
});

export const deleteAsset = async (assetId: string, idToken) => {
  const result = await Axios.delete(
    `${assetsServiceUrl}/user-assets/${assetId}`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    }
  );

  return result.data;
};
