import { createSlice } from '@reduxjs/toolkit';

export enum ExportType {
  JPEG = 'JPEG',
  PNG = 'PNG',
};

interface ExportState{
  exporting: boolean;
  exportType: ExportType;
}

const initialState: ExportState = {
  exporting: false,
  exportType: null,
}

export const exportSlice = createSlice({
  name: 'export',
  initialState,
  reducers: {
    setExporting: (state, action) => {
      const { exporting, exportType = null } = action.payload;
      state.exporting = exporting;
      state.exportType = exportType;
    },
  }
});

// export const {} = exportSlice.actions;

export const openExportWizard = (exportType: ExportType) => dispatch => dispatch(exportSlice.actions.setExporting({ exporting: true, exportType }));
export const closeExportWizard = () => dispatch => dispatch(exportSlice.actions.setExporting({ exporting: false }));
export const selectExporting = state => state.export.exporting;
export const selectExportType = state => state.export.exportType;

export default exportSlice.reducer;