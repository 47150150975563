import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  panZoomScale: 1,
  panEnabled: true,
}

export const panZoomSlice = createSlice({
  name: 'panZoom',
  initialState,
  reducers: {
    setPanZoomScale: (state, action) => {
      state.panZoomScale = action.payload;
    },
    setPanEnabled: (state, action) => {
      state.panEnabled = action.payload;
    },
  }
});

export const { setPanZoomScale, setPanEnabled } = panZoomSlice.actions;

export const selectPanZoomScale = state => state.panZoom.panZoomScale;
export const selecgtPanEnabled = state => state.panZoom.panEnabled;

export default panZoomSlice.reducer;