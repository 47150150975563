import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { useAuthContext } from '@common/context';
import { ProjectProvider } from '@common/context/project';
import './App.css';

import { SignUpRoute } from './+signup';
import { LoginRoute } from './+login';
import { UpgradePlanModal } from './+ui/UpgradePlanModal';
import { EditorRoute } from '@editor/EditorRoute';
import { ProjectsRoute } from './+projects';
import { AccountRoute } from './+account';
import { ForwarderRoute } from './ForwarderRoute';
import { PrivateRoute } from '@common/components';
import { UpgradeProvider } from './+ui/UpgradeContext';
import { useSubscriptionContext } from '@common/context/subscription/useSubscriptionContext';
import { useUpdateLastUserActivity } from '@common/hooks/useUpdateLastActive';
import { TermsRoute } from './+terms';
import { ContactRoute } from './+contact/ContactRoute';

const PhoneSorryAlert = () => (
  <div className="flex align-center h-screen">
    <div className="px-4 py-4 self-center">
      <div className="bg-secondary shadow-md rounded-md">
        <div className="px-4 py-4">
          <p className="p-0 text-xl font-bold">
            Sorry{' '}
            <span role="img" aria-label="sorry">
              😞
            </span>
          </p>
          Unfortunately, we do not support phone screens at the moment. For the
          best experience, visit the app from a computer or a tablet.
        </div>
      </div>
    </div>
  </div>
);

function App() {
  const { loading, currentUser, signOut } = useAuthContext();
  const { loading: loadingSubscriptions } = useSubscriptionContext();
  useUpdateLastUserActivity(loading && loadingSubscriptions, currentUser?.uid);

  const windowWidth = window.innerWidth;
  const isPhone = windowWidth < 768;

  if (loading || loadingSubscriptions) {
    return null;
  }

  return (
    <Router>
      <Switch>
        <Route path="/terms" exact>
          <TermsRoute />
        </Route>

        <Route path="/contact" exact>
          <ContactRoute />
        </Route>

        <Route path="/login" exact>
          {/* <InvitationProvider> */}
          <LoginRoute />
          {/* </InvitationProvider> */}
        </Route>

        <Route path="/signup" exact>
          {/* <InvitationProvider> */}
          <SignUpRoute />
          {/* </InvitationProvider> */}
        </Route>

        <Route path="/version" exact>
          <p>
            Release tag: {process.env.REACT_APP_RELEASE_TAG || 'unavailable'}
          </p>
          <p>Commit SHA: {process.env.REACT_APP_COMMIT_SHA || 'unavailable'}</p>
          <p>
            Pipeline ID:{' '}
            {process.env.REACT_APP_GITHUB_ACTIONS_RUN_ID || 'unavailable'}
          </p>
        </Route>

        <Route path="/forgot-password" exact>
          <LoginRoute forgotPassword />
        </Route>

        <Route path="/verify-email" exact>
          <LoginRoute verifyEmail />
        </Route>

        <Route path="/forward" exact>
          <ForwarderRoute />
        </Route>

        {/* <Route path="/invitation" exact>
          <InvitationProvider>
            <InvitationRoute />
          </InvitationProvider>
        </Route> */}

        <Route
          path="/signout"
          render={() => {
            if (currentUser) {
              signOut();
            }
            return <Redirect to="/" />;
          }}
          exact
        />
        <Redirect from="/" to="/projects" exact />

        <UpgradeProvider>
          <UpgradePlanModal />

          <PrivateRoute path="/canvas/:canvasId" exact>
            {isPhone ? (
              <PhoneSorryAlert />
            ) : (
              <ProjectProvider>
                <EditorRoute />
              </ProjectProvider>
            )}
          </PrivateRoute>

          <PrivateRoute path="/projects" exact>
            {isPhone ? (
              <PhoneSorryAlert />
            ) : (
              <ProjectProvider>
                <ProjectsRoute />
              </ProjectProvider>
            )}
          </PrivateRoute>

          {/* <PrivateRoute path="/subscription" exact>
            <SubscriptionRoute />
          </PrivateRoute> */}

          <PrivateRoute path="/account" exact>
            <AccountRoute />
          </PrivateRoute>
        </UpgradeProvider>
      </Switch>
    </Router>
  );
}

export default App;
