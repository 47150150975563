import React from 'react';
import { KnowledgeBaseTopic, DemoGIF } from '../knowledge-base.common';

export const topic: KnowledgeBaseTopic = {
  id: 'exporting',
  label: 'Exporting',
  premium: false,
  Content: () => (
    <div>
      You can export your canvas in multiple formats via the "Export" menu at the top bar.
      <div className="mt-6">
        <DemoGIF src="https://static-assets-cdn.aws.posturize.app/knowledge-base/Exporting.png" />
      </div>
    </div>
  ),
};
