import { ApprovePaypalSubscriptionRequest, CreateSubscriptionBaseRequest } from '../types';

export function usePaypalSubscription() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/subscription/paypal`;

  async function subscribe(createSubscriptionRequest: CreateSubscriptionBaseRequest) {
    try {
      const subscription = await fetch(`${baseUrl}/create-subscription`, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(createSubscriptionRequest),
      }).then(res => res.json());
      return subscription;
    } catch (error) {
      return error;
    }
  }

  async function approveSubscription(approvePaypalSubscriptionRequest: ApprovePaypalSubscriptionRequest) {
    try {
      const approval = await fetch(`${baseUrl}/approve-subscription`, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(approvePaypalSubscriptionRequest),
      });
      return approval;
    } catch (error) {
      return error;
    }
  }

  return {
    subscribe,
    approveSubscription,
  };
}
