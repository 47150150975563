import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useCanvasV2 } from '@editor/hooks/useCanvasV2';
import { Asset } from './object-types/Asset/Asset';
import useControls from '@editor/hooks/useControls';
import {
  CanvasSkeleton,
  CanvasSvgAssetObject,
  CanvasObjectTypes,
  CanvasObject,
  CanvasUserAssetObject,
} from '@editor/utils/canvas.types';
import HumanV2 from './object-types/HumanV2/HumanV2';
import Draggable from './components/Draggable';
import { ObjectContext } from './ObjectContext';
import { useLayers } from '@editor/hooks/useLayers';
import {
  AnalyticsEvents,
  useProjectAnalytics,
} from '@common/analytics/analytics.util';
import { Grid } from '../../../+ui/Grid';
import { UserAsset } from './object-types/Asset/UserAsset';

const Canvas = styled.div<any>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background: #fff;
  flex-basis: ${(props) => props.width}px;
  min-width: ${(props) => props.width}px;
  overflow: hidden;
  position: relative;

  border-radius: 4px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);

  &.capturing {
    /* Elements to hide while capturing */
    .rect {
      display: none;
    }

    .hide-on-export {
      display: none !important;
    }
  }
`;

const CanvasObjectWrapper = styled.div<any>`
  position: absolute;
  z-index: ${(props) => props.zIndex};
`;

export const CanvasV2 = () => {
  const canvasRef = useRef<HTMLCanvasElement>();
  const { objects, properties, scale, setObjectProperty } = useCanvasV2();

  const {
    isControlled,
    setControlledObject,
    controlledObject,
    displayGrid,
  } = useControls();
  const { width, height } = properties;
  const { layers } = useLayers();
  const { logEvent } = useProjectAnalytics();

  useEffect(() => {
    logEvent(AnalyticsEvents.EDITOR_ENTER);
  }, [logEvent]);

  useEffect(() => {
    if (canvasRef.current) {
      // This will prevent the mouse drags from moving the canvas around,
      // when dragging over a "locked" object.
      // const handleLockedMouseEvents = (e) => {
      //   const lockedObjectsClicked = canvasRef.current.querySelectorAll(':hover.object-bounds.locked')
      //   if (lockedObjectsClicked.length) {
      //     e.stopPropagation();
      //   }
      // }
      // canvasRef.current.addEventListener('mousedown', handleLockedMouseEvents);
    }
  }, [canvasRef]);

  const renderObject = (object: CanvasObject, zIndex: number) => {
    const { properties } = object;
    const controlled = isControlled(object.id);
    const locked = object.properties.locked;

    switch (object.objectType) {
      case CanvasObjectTypes.HUMAN:
        return (
          <CanvasObjectWrapper
            className="canvas-object"
            data-canvasobjectid={object.id}
            zIndex={zIndex}
          >
            <Draggable
              key={object.id}
              locked={locked}
              handle={'#Chest'}
              position={properties.position}
              onDragStart={() => !locked && setControlledObject(object.id)}
              onPositionChange={(position) =>
                setObjectProperty(object.id, 'position', position)
              }
              scalePercentage={100}
              canvasElement={canvasRef.current}
              canvasScale={scale}
              canvasWidth={width}
              canvasHeight={height}
              viewBoxWidth={object.properties.viewBoxWidth}
              viewBoxHeight={object.properties.viewBoxHeight}
            >
              <HumanV2
                controlled={controlled}
                object={object as CanvasSkeleton}
                onSelected={() => !locked && setControlledObject(object.id)}
              />
            </Draggable>
          </CanvasObjectWrapper>
        );
      case CanvasObjectTypes.ASSET:
        return (
          <CanvasObjectWrapper data-canvasobjectid={object.id} zIndex={zIndex}>
            <Asset
              key={object.id}
              locked={locked}
              controlled={controlled}
              object={object as CanvasSvgAssetObject}
              onSelected={() => !locked && setControlledObject(object.id)}
            />
          </CanvasObjectWrapper>
        );
      case CanvasObjectTypes.USER_ASSET:
        return (
          <CanvasObjectWrapper data-canvasobjectid={object.id} zIndex={zIndex}>
            <UserAsset
              key={object.id}
              locked={locked}
              controlled={controlled}
              object={object as CanvasUserAssetObject}
              onSelected={() => !locked && setControlledObject(object.id)}
            />
          </CanvasObjectWrapper>
        );
    }
  };

  const objectsToRender = layers.map((objectId: string, layerIndex: number) => {
    const object = objects[objectId];
    const controlled = isControlled(objectId);
    const zIndex = controlled ? layers.length : layers.length - layerIndex;

    return (
      <ObjectContext.Provider key={object.id} value={{ objectId: object.id }}>
        {renderObject(object, zIndex)}
      </ObjectContext.Provider>
    );
  });

  const deselectControlledObject = (e: MouseEvent) => {
    const { target } = e;
    const ignored = (target as HTMLCanvasElement).closest(
      '.ignore-deselect-capture'
    );

    // deselect selected element if not clicking it or any of its children
    if (!controlledObject || ignored) {
      return;
    }

    const controlledObjectNode = canvasRef.current.querySelector(
      `[data-canvasobjectid="${controlledObject}"]`
    );

    if (!controlledObject) {
      return;
    }

    const contains = controlledObjectNode.contains(target as Node);
    if (!contains) {
      setControlledObject(null);
    }
  };

  return (
    <Canvas
      id="canvas"
      width={width}
      height={height}
      ref={canvasRef}
      onMouseDown={deselectControlledObject}
      onTouchStart={deselectControlledObject}
    >
      {objectsToRender}
      {displayGrid && <Grid />}
    </Canvas>
  );
};
