import React, { FC } from 'react';

import firebase from 'firebase/app';
import { useState } from 'react';
import { ProjectContext } from './ProjectContext';
import { Project } from '@common/types/Project';
import { createLogger } from '@common/utils/logger';
import { useAuthContext } from '../auth';

const logger = createLogger('ProjectProvider');

export const ProjectProvider: FC = ({ children }) => {
  const { currentUser } = useAuthContext();
  const [currentProject, setCurrentProject] = useState<Project | undefined>();

  const selectProject = (project: Project) => {
    setCurrentProject(project);
  };

  const loadProject = async (canvasId: string) => {
    try {
      const projectSnapshot = await firebase
        .firestore()
        .collection('projects')
        .where('ownerId', '==', currentUser.uid)
        .where('canvasId', '==', canvasId)
        .limit(1)
        .get();

      const [project] = projectSnapshot.docs;
      setCurrentProject(project.data() as Project);
    } catch (e) {
      logger.error(e);
      throw new Error(`Failed to load project with canvasId ${canvasId}`);
    }
  };

  const contextValue = {
    currentProject,
    selectProject,
    loadProject,
  };
  return (
    <ProjectContext.Provider value={contextValue}>
      {children}
    </ProjectContext.Provider>
  );
};
