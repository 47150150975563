import React from 'react';
import { KnowledgeBaseTopic, DemoGIF } from '../knowledge-base.common';

export const topic: KnowledgeBaseTopic = {
  id: 'human_joints',
  label: 'Human Joints',
  premium: false,
  Content: () => (
    <div>
      Human objects have a variety of joints you can play with to achieve your desired pose.
      <div className="mt-6">
        <DemoGIF src="https://static-assets-cdn.aws.posturize.app/knowledge-base/HumanJoints.gif" />
      </div>
    </div>
  ),
};
