import React from 'react';
import { Project } from '../../types/Project';

export const noop = () => void 0;

interface ProjectState {
  currentProject: Project | undefined;
  selectProject: (project: Project) => void;
  loadProject: (projectId: string) => void;
}

const initialState: ProjectState = {
  currentProject: undefined,
  selectProject: noop,
  loadProject: noop,
};

export const ProjectContext = React.createContext<ProjectState>(initialState);
