import React, { FC, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useAuthContext, useInvitationContext } from '@common/context';
import { Input, Button, GoogleButton, Loader } from '@common/components';
import { Link } from 'react-router-dom';

export const LoginContainer: FC = () => {
  const {
    signInWithGoogle,
    signInWithEmailPassword,
    currentUser,
  } = useAuthContext();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { invitation } = useInvitationContext();
  const { register, handleSubmit, errors } = useForm<{
    email: string;
    password: string;
  }>({
    defaultValues: {
      email: invitation?.email,
    },
  });

  const history = useHistory();

  useEffect(() => {
    if (currentUser) {
      setTimeout(() => history.push('/projects'), 1000);
    }
  }, [currentUser, history]);

  const handleGoogleSignIn = async () => {
    setLoading(true);
    try {
      await signInWithGoogle(invitation);
    } catch (error) {}
    setLoading(false);
  };

  const handleSignInWithEmailPassword = async ({ email, password }) => {
    setLoading(true);
    try {
      await signInWithEmailPassword(email, password, invitation);
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  return (
    <div className="h-full">
      <div className="h-full flex max-w-md m-auto items-center justify-center">
        <div className="w-full px-8 py-8 bg-white shadow-xl rounded-2xl">
          <h1 className="font-light text-3xl py-4 text-center font-medium">
            Sign in to your account
          </h1>
          <form
            onSubmit={handleSubmit(handleSignInWithEmailPassword)}
            autoComplete="off"
          >
            <p className="text-warning font-semibold my-4">{error}</p>

            <Input
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Enter a valid e-mail address',
                },
              })}
              name="email"
              type="email"
              error={!!errors?.email}
              errorMessage={errors?.email?.message}
              placeholder="Email"
              className="mb-8"
            />

            <Input
              ref={register({
                required: true,
                maxLength: 16,
              })}
              name="password"
              type="password"
              error={!!errors?.password}
              placeholder="Password"
              className="mb-8"
            />
            <Button
              color="primary"
              size="large"
              type="submit"
              className="w-full"
              onClick={handleSubmit(handleSignInWithEmailPassword)}
              disabled={loading}
            >
              {loading ? <Loader className="flex justify-center" /> : 'Sign in'}
            </Button>
          </form>
          <span className="text-center block my-4 text-gray-400">— or —</span>
          <GoogleButton
            className="mb-4"
            label="Sign in with Google"
            onClick={handleGoogleSignIn}
          />
          <div className="flex justify-between">
            <Link
              to="/signup"
              className="text-primary hover:text-primary-dark block text-center mt-4"
            >
              I don't have an account
            </Link>
            <Link
              to="/forgot-password"
              className="text-primary hover:text-primary-dark block text-center mt-4"
            >
              Forgot password
            </Link>
          </div>

          <p className="text-center" style={{ marginTop: 12, fontSize: 12 }}>
            By using Posturize, you agree to our{' '}
            <Link className="font-medium" to="/terms" target="_blank">
              Terms of Service, Privacy Policy and Beta Terms of Service
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
