import React from 'react';
import { KnowledgeBaseTopic, DemoGIF } from '../knowledge-base.common';

export const topic: KnowledgeBaseTopic = {
  id: 'asset_manipulation',
  label: 'Asset Manipulation',
  premium: false,
  Content: () => (
    <div>
      <p>
        You can manipulate the assets by <strong>dragging</strong>, <strong>resizing</strong> or <strong>rotating</strong> them.
      </p>

      <div className="mt-6">
        <DemoGIF src="https://static-assets-cdn.aws.posturize.app/knowledge-base/AssetManipulation.gif" />
      </div>
    </div>
  ),
};
