import React, { FC, Fragment } from 'react';

export const BetaTermsOfService: FC<{}> = () => {
  return (
    <Fragment>
      <h2>Product Offering</h2>
      <p>
        As a Beta user, you understand taht Posturize may change, revoke, or add features at any time, which might affect your usage of the app, including potential data loss.
      </p>

      <h2>Email Communications</h2>
      <p>
        As a Beta user, you agree that we may send you emails from Posturize. Such emails
        may contain:
      </p>
      <ul>
        <li>Links to feedback forms about the product</li>
        <li>Direct communications from our team</li>
        <li>Follow-up on bug reports and contact requests</li>
        <li>Information about changes to our terms, conditions and policies</li>
        <li>Communications about new features</li>
        <li>Changes to product offerings that might affect you</li>
      </ul>

      <p>You may opt out at any time. During Beta, opting our will automatically revoke your access to the application. This is because we offer the Beta version for free, in exchange of valuable information that will allow us to enhance the application further.</p>

      <h2>Potential Data Loss</h2>
      <p>
        As a Beta user, you understand and agree that the usage of Posturize is not entirely stable nor guaranteed. This means that the work done on the paltform might be erased at any time without notice, whether accidentally or intentionally.
      </p>
    </Fragment>
  );
};
