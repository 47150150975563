import domtoimage from 'dom-to-image';
import { DEFAULT_CANVAS_HEIGHT, DEFAULT_CANVAS_WIDTH } from '@editor/utils/editor.constants';

export async function saveElementAsPNG(element: HTMLElement) {
  const dataUrl = await domtoimage.toPng(element, { width: DEFAULT_CANVAS_WIDTH, height: DEFAULT_CANVAS_HEIGHT});
  return dataUrl;
}

export async function saveElementAsJPEG(element: HTMLElement) {
  const dataUrl = await domtoimage.toJpeg(element, { bgcolor: '#ffffff', width: DEFAULT_CANVAS_WIDTH, height: DEFAULT_CANVAS_HEIGHT});
  return dataUrl;
}

export async function createCanvasSnapshot(handler: (canvas: HTMLElement) => Promise<any>) {
  const canvas = document.querySelector('#editor #canvas') as HTMLElement;

  if (!canvas) {
    return;
  }

  const canvasClone = canvas.cloneNode(true) as HTMLElement;
  canvasClone.classList.add('capturing');
  canvasClone.style.background = 'transparent';
  canvasClone.style.position = 'absolute';
  canvasClone.style.top = '0';
  canvasClone.style.left = '0';
  canvasClone.style.zIndex = '0';

  document.body.append(canvasClone);
  await handler(canvasClone);
  canvasClone.remove();
};

export function calcProportions(oldValue, oldMin, oldMax, newMin, newMax) {
  let newValue: number, newRange: number;
  const oldRange = oldMax - oldMin;

  if (oldRange === 0) {
    newValue = newMin;
  } else {
    newRange = newMax - newMin;
    newValue = ((oldValue - oldMin) * newRange) / oldRange + newMin;
  }

  return newValue;
}

export function normalizeClientXY(e: MouseEvent | TouchEvent) {
  const normalized =
    e.type === 'touchstart' || e.type === 'touchmove'
      ? (e as TouchEvent).targetTouches[0]
      : (e as MouseEvent);

  return {
    clientX: normalized.clientX,
    clientY: normalized.clientY,
  };
}
