import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MuiModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button } from '@common/components';
import { useUpgrade } from './UpgradeContext';
import DiamongImage from './diamond.png';

const Modal = styled(MuiModal)`
  * {
    outline: none !important;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

const UpgradeImage = styled.img`
  width: 160px;
  margin: auto;
  margin-bottom: 14px;
`;

const UpgradeTitle = styled.h1`
  color: #220451;
  font-weight: bold;
  margin-bottom: 14px;
  font-size: 24px;
  text-align: center;
`;

const ModalContent = styled.div`
  border-radius: 12px;
  background: #f4f4f7;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.09);
  padding: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 540px;
`;

export const UpgradePlanModal = () => {
  const {
    open,
    text,
    upgradable,
    title = '',
    confirm,
    decline,
    hideModal,
  } = useUpgrade();
  const [modalOpen, setModalOpen] = useState(open);

  const handleConfirm = () => {
    confirm();
    setModalOpen(false);
  };

  const handleDecline = () => {
    decline();
    setModalOpen(false);
  };
  const handleHideModal = () => {
    hideModal();
    setModalOpen(false);
  };

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  return (
    <Modal
      id="upgrade-plan-modal"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      onClose={upgradable ? decline : handleHideModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalOpen}>
        <ModalContent>
          <UpgradeImage src={DiamongImage} alt="upgrade" />
          {!title ? (
            <UpgradeTitle>
              {upgradable ? 'Time for an upgrade!' : 'Limit reached'}
            </UpgradeTitle>
          ) : (
            <UpgradeTitle>{title}</UpgradeTitle>
          )}
          <div>
            <p className="text-center">{text}</p>
            {upgradable && (
              <p className="mt-1 text-center">
                Upgrade now to remove this limit and unlock all premium
                features.
              </p>
            )}
          </div>

          <div className="mt-4 flex">
            <div className="flex-grow flex items-center">
              {upgradable && (
                <Button
                  color="transparent"
                  style={{ color: '#686868' }}
                  onClick={handleDecline}
                >
                  Not now
                </Button>
              )}
            </div>

            <div className="justify-end flex flex-grow items-end mt-2">
              {upgradable ? (
                <Button
                  color="primary"
                  style={{ padding: 12 }}
                  onClick={handleConfirm}
                >
                  Tell me more
                </Button>
              ) : (
                <Button
                  color="primary"
                  style={{ padding: 12, width: 100 }}
                  onClick={handleHideModal}
                >
                  OK
                </Button>
              )}
            </div>
          </div>
        </ModalContent>
      </Fade>
    </Modal>
  );
};
