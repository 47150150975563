import { useReducer, useCallback } from 'react';
import {
  getCategories,
  getAssetLinks,
  getSVGFromAssetLink,
  getAssetLinkById,
} from './assetLibrary.service';
import {
  AssetCategory,
  Asset,
  AssetCategories,
} from './object-types/Asset/asset.types';
import { Svg } from '@svgdotjs/svg.js';

interface AssetLibraryState {
  loading: boolean;
  error: Error | undefined;
  categories: AssetCategory[];
  assets: AssetListDict | undefined;
  canvasAssets: CanvasAssetDict | undefined;
}

type AssetLibraryAction =
  | { type: 'event/setAssets'; payload: AssetListDict | undefined }
  | { type: 'event/setCanvasAssets'; payload: Asset }
  | { type: 'event/setCategories'; payload: AssetCategory[] }
  | { type: 'event/setLoading'; payload: boolean }
  | { type: 'event/setError'; payload: Error };

type CanvasAssetDict = { [key: string]: Asset };
type AssetListDict = { [category: string]: Asset[] };

const initialState = {
  loading: false,
  error: undefined,
  categories: [],
  assets: undefined,
  canvasAssets: undefined,
};

const assetLibraryReducer = (
  state: AssetLibraryState,
  action: AssetLibraryAction
): AssetLibraryState => {
  switch (action.type) {
    case 'event/setCategories':
      return {
        ...state,
        categories: action.payload,
      };
    case 'event/setAssets':
      return {
        ...state,
        assets: action.payload,
      };
    case 'event/setCanvasAssets':
      return {
        ...state,
        canvasAssets: {
          ...state.canvasAssets,
          [action.payload.id]: {
            ...action.payload,
          },
        },
      };
    case 'event/setLoading':
      return {
        ...state,
        loading: action.payload,
      };
  }
  return state;
};

export const useAssetLibrary = () => {
  const [state, dispatch] = useReducer(assetLibraryReducer, initialState);

  const fetchCategories = async () => {
    dispatch({ type: 'event/setLoading', payload: true });
    getCategories()
      .then((categories: AssetCategory[]) => {
        dispatch({ type: 'event/setCategories', payload: categories });
      })
      .finally(() => {
        dispatch({ type: 'event/setLoading', payload: false });
      });
  };

  const dispatchFetchAssets = async () => {
    dispatch({ type: 'event/setLoading', payload: true });

    getAssetLinks()
      .then(async (assetLinks) => {
        const svgPromises = assetLinks.map((assetLink) =>
          getSVGFromAssetLink(assetLink.url)
        );
        const svgs = await Promise.all(svgPromises);

        const assets: Asset[] = svgs.map((svg: Svg, index) => {
          const assetLink = assetLinks[index];
          return {
            name: assetLink.name,
            id: assetLink.id,
            category: assetLink.category,
            svg,
          };
        });
        const assetsListMap = mapAssetsToAssetsListMap(assets);

        dispatch({ type: 'event/setAssets', payload: assetsListMap });
      })
      .finally(() => {
        dispatch({ type: 'event/setLoading', payload: false });
      });
  };

  const dispatchFetchAssetbyId = useCallback(async (assetId: string) => {
    getAssetLinkById(assetId).then(async (assetLink) => {
      const svg = await getSVGFromAssetLink(assetLink.url);
      const asset: Asset = {
        name: assetLink.name,
        id: assetLink.id,
        category: assetLink.category,
        svg,
      };

      dispatch({ type: 'event/setCanvasAssets', payload: asset });
    });
  }, []);

  const mapAssetsToAssetsListMap = (assets: Asset[]) => {
    return assets.reduce((acc: AssetListDict, asset: Asset) => {
      if (asset) {
        const allAssetsCategory = acc?.[AssetCategories.ALL];
        const assetCategory = acc?.[asset.category];
        allAssetsCategory?.push(asset);
        assetCategory?.push(asset);

        if (!assetCategory) {
          acc = {
            ...acc,
            [asset.category]: [asset],
          };
        }
        if (!allAssetsCategory) {
          acc = {
            ...acc,
            [AssetCategories.ALL]: [asset],
          };
        }
      }
      return acc;
    }, {});
  };
  return {
    assetLibraryState: state,
    dispatchFetchAssets,
    dispatchFetchAssetbyId,
    fetchCategories,
  };
};
