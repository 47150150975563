import React, { useContext, useReducer, useRef } from 'react';

export const UpgradeContext = React.createContext(null);

interface UpgradeModalOptions {
  text: string;
  title?: string;
  upgradable?: boolean;
  onConfirm?: Function;
  onDecline?: Function;
}

export const useUpgrade = () => {
  return useContext(UpgradeContext);
};

const initialState = {
  open: false,
  text: null,
  upgradable: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'displayUpgradeModal':
      return {
        ...state,
        open: true,
        text: action.payload.text,
        title: action.payload.title,
        upgradable: action.payload.upgradable,
      };
    case 'hideUpgradeModal':
      return { ...state, open: false };
  }
};

export const UpgradeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { open, text, upgradable, title } = state;
  const onConfirmCallback = useRef(null);
  const onDeclineCallback = useRef(null);

  const displayModal = ({
    text,
    upgradable = false,
    title,
    onConfirm,
    onDecline,
  }: UpgradeModalOptions) => {
    if (onConfirm) {
      onConfirmCallback.current = () => onConfirm();
    }

    if (onDecline) {
      onDeclineCallback.current = () => onDecline();
    }

    dispatch({
      type: 'displayUpgradeModal',
      payload: {
        text,
        upgradable,
        title,
      },
    });
  };

  const hideModal = () => {
    onConfirmCallback.current = null;
    onDeclineCallback.current = null;
    dispatch({ type: 'hideUpgradeModal' });
  };

  const decline = () => {
    if (onDeclineCallback.current) {
      onDeclineCallback.current();
    }
    hideModal();
  };

  const confirm = () => {
    if (onConfirmCallback.current) {
      onConfirmCallback.current();
    }
    hideModal();
  };

  return (
    <UpgradeContext.Provider
      value={{
        open,
        text,
        upgradable,
        title,
        displayModal,
        confirm,
        decline,
        hideModal,
      }}
    >
      {children}
    </UpgradeContext.Provider>
  );
};
