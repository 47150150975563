import { useDispatch, useSelector } from 'react-redux';
import {
  resetControls,
  selectControlledObject,
  selectFocusedObject,
  selectIsCapturingCanvas,
  selectDisplayGrid,
  setControlledObject,
  setFocusedObject,
  setIsCapturingCanvas,
  setDisplayGrid,
} from '@editor/reducers/controls.slice';

const useControls = () => {
  const dispatch = useDispatch();
  const controlledObject = useSelector(selectControlledObject);
  const focusedObject = useSelector(selectFocusedObject);
  const displayGrid = useSelector(selectDisplayGrid);

  return {
    controlledObject,
    isControlled: (objectId: string): boolean => controlledObject === objectId,
    setControlledObject: (objectId: string) =>
      dispatch(setControlledObject(objectId)),
    focusedObject,
    isFocused: (objectId: string): boolean => focusedObject === objectId,
    setFocusedObject: (objectId: string) =>
      dispatch(setFocusedObject(objectId)),
    isCapturingCanvas: useSelector(selectIsCapturingCanvas),
    setIsCapturingCanvas: (value: boolean) =>
      dispatch(setIsCapturingCanvas(value)),
    resetControls: () => dispatch(resetControls()),
    displayGrid,
    setDisplayGrid: (displayGrid: boolean) => dispatch(setDisplayGrid(displayGrid)),
  };
};

export default useControls;
