import { configureStore } from '@reduxjs/toolkit';
import canvasReducer from './+editor/reducers/canvas.slice';
import controlsReducer from './+editor/reducers/controls.slice';
import panZoomReducer from './+editor/reducers/panzoom.slice';
import exportReducer from './+editor/reducers/export.slice';
import undoable from 'redux-undo';

const enableDevTools = process.env.REACT_APP_DEPLOYMENT_STAGE === 'dev';

export default configureStore({
  devTools: enableDevTools,
  reducer: {
    canvas: undoable(canvasReducer, { ignoreInitialState: true, limit: 50 }),
    controls: controlsReducer,
    panZoom: panZoomReducer,
    export: exportReducer,
  },
});
