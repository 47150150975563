export interface BoneConfig {
  jointId: string;
  customRotateHandleSelector?: string;
  customPivotPointSelector?: string;
}

// ORDER MATTERS! DO NOT CHANGE!
export const humanBoneConfiguration = {
  Head: { jointId: 'Head' },
  RightHand: { jointId: 'RightHand' },
  RightLowerArm: { jointId: 'RightLowerArm' },
  RightUpperArm: { jointId: 'RightUpperArm' },
  LeftHand: { jointId: 'LeftHand' },
  LeftLowerArm: { jointId: 'LeftLowerArm' },
  LeftUpperArm: { jointId: 'LeftUpperArm' },
  RightFoot: { jointId: 'RightFoot' },
  RightLowerLeg: { jointId: 'RightLowerLeg' },
  RightUpperLeg: { jointId: 'RightUpperLeg' },
  LeftFoot: { jointId: 'LeftFoot' },
  LeftLowerLeg: { jointId: 'LeftLowerLeg' },
  LeftUpperLeg: { jointId: 'LeftUpperLeg' },
  ArmSpan: {
    jointId: 'ArmSpan',
    customRotateHandleSelector: '#ArmSpanRotateHandle',
  },
  UpperBody: {
    jointId: 'UpperBody',
    customRotateHandleSelector: '#UpperBodyRotateHandle',
    customPivotPointSelector: '#GroinPivotPoint'
  },
  LowerBody: {
    jointId: 'LowerBody',
    customRotateHandleSelector: '#LowerBodyRotateHandle',
    customPivotPointSelector: '#GroinPivotPoint',
  },
};

export const humanHighlightConfiguration = [
  // Left Arm
  { joint: 'LeftUpperArm', selector: '#ActualLeftUpperArm', linked: ['#ActualLeftLowerArm', '#LeftHand #Hand']},
  { joint: 'LeftLowerArm', selector: '#ActualLeftLowerArm', linked: ['#LeftHand #Hand']},
  { joint: 'LeftHand', selector: '#LeftHand #Hand'},
  // Right Arm
  { joint: 'RightUpperArm', selector: '#ActualRightUpperArm', linked: ['#ActualRightLowerArm', '#RightHand #Hand']},
  { joint: 'RightLowerArm', selector: '#ActualRightLowerArm', linked: ['#RightHand #Hand']},
  { joint: 'RightHand', selector: '#RightHand #Hand'},
  // Left Leg
  { joint: 'LeftUpperLeg', selector: '#ActualLeftUpperLeg', linked: ['#ActualLeftLowerLeg', '#LeftFoot #Foot']},
  { joint: 'LeftLowerLeg', selector: '#ActualLeftLowerLeg', linked: ['#LeftFoot #Foot']},
  { joint: 'LeftFoot', selector: '#LeftFoot #Foot'},
  // Right Leg
  { joint: 'RightUpperLeg', selector: '#ActualRightUpperLeg', linked: ['#ActualRightLowerLeg', '#RightFoot #Foot']},
  { joint: 'RightLowerLeg', selector: '#ActualRightLowerLeg', linked: ['#RightFoot #Foot']},
  { joint: 'RightFoot', selector: '#RightFoot #Foot'},
  // Chest
  { joint: 'UpperBody', selector: '#Chest' },
  // Head
  { joint: 'Head', selector: '#NewHead' },
  // ArmSpanArc
  { joint: 'ArmSpan', selector: '#ArmSpanRotateHandle', linked: ['#ArmSpanArc', '#ActualLeftUpperArm', '#ActualLeftLowerArm', '#LeftHand #Hand', '#ActualRightUpperArm', '#ActualRightLowerArm', '#RightHand #Hand']},
  // LowerBodyArc
  { joint: 'LowerBody', selector: '#LowerBodyRotateHandle', linked: ['#LowerBodyArc', '#ActualLeftUpperLeg', '#ActualLeftLowerLeg', '#LeftFoot', '#ActualRightUpperLeg', '#ActualRightLowerLeg', '#RightFoot']},
  // Chest Rotate Handle
  { joint: null, selector: '#UpperBodyRotateHandle'},
];