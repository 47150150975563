import React from 'react';
import { KnowledgeBaseTopic, DemoGIF } from '../knowledge-base.common';

export const topic: KnowledgeBaseTopic = {
  id: 'asset_library',
  label: 'Asset Library',
  premium: false,
  Content: () => (
    <div>
      <p>
        Posturize offers a rich <i>Assets Library</i> that contains various
        objects. These objects can help you craft a beautiful scene.
      </p>
      <p className="mt-2">You can add an asset via the Add Object menu at the top bar.</p>

      <div className="mt-6">
        <DemoGIF src="https://static-assets-cdn.aws.posturize.app/knowledge-base/AssetLibrary.png" />
      </div>
    </div>
  ),
};
