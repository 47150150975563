import React, { useState, useEffect } from 'react';
import Avatar from 'react-avatar';
import {
  VscSignOut as SignOutIcon,
  VscAccount as ProfileIcon,
  VscChevronDown as ChevronDownIcon,
} from 'react-icons/vsc';

import { useAuthContext } from '../context/auth';
import { NavLink, useHistory, Link } from 'react-router-dom';
import { Dropdown } from './Dropdown';
import { Button } from '@common/components/Button';
import { useSubscriptionContext } from '@common/context/subscription/useSubscriptionContext';
import { PlanTypes } from '@posturize/shared/payments';
import { useToastContext } from '@common/context';
import Chip from '@material-ui/core/Chip';

export const Header = () => {
  const { currentUser, userData } = useAuthContext();
  const { addToast } = useToastContext();
  const { planType } = useSubscriptionContext();
  const history = useHistory();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isVerified, setIsVerified] = useState(currentUser.emailVerified);
  const dropdownOptions = [
    {
      viewValue: 'Account',
      value: '/account',
      icon: ProfileIcon,
    },
    {
      viewValue: 'Sign out',
      value: '/signout',

      icon: SignOutIcon,
    },
  ];

  useEffect(() => {
    if (!currentUser.emailVerified) {
      // reload to check again
      currentUser?.reload().then(() => {
        setIsVerified(currentUser.emailVerified);
      });
    }
  }, [currentUser]);

  const showUpgradeYourPlan = false;

  return (
    <>
      {!isVerified && (
        <div className="relative bg-indigo-600">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="pr-16 sm:text-center sm:px-16">
              <p className="font-medium text-white">
                <span className="inline">
                  You have not verified your email address yet. Please check
                  your email inbox.
                </span>
                <span className="block sm:ml-2 sm:inline-block">
                  <Button
                    color="transperent"
                    variant="link"
                    className="text-white font-bold underline"
                    onClick={() =>
                      currentUser
                        .sendEmailVerification()
                        .then(() => {
                          addToast({
                            type: 'success',
                            title: 'Success',
                            message: 'We just sent you an email!',
                          });
                        })
                        .catch(() => {
                          addToast({
                            type: 'warning',
                            title: 'Something went wrong',
                            message: 'Try again later.',
                          });
                        })
                    }
                  >
                    {' '}
                    Resend Email
                  </Button>
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
      <nav className="fixed left-0 right-0 z-10 bg-white shadow">
        <div className="max-w-screen-xl md:px-12 mx-auto">
          <div className="relative flex justify-between h-16">
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0 flex items-center">
                <Link to="/" className="flex">
                  <span className="sr-only">Posturize</span>
                  <img className="h-8 w-auto sm:h-10" src="https://static-assets-cdn.aws.posturize.app/images/logo.png" alt="" />
                </Link>
                <Chip style={{ marginLeft: 12 }} label="BETA" />
              </div>

              <div className="ml-6 flex space-x-8">
                <NavLink
                  to="/projects"
                  className={`text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium border-transperent$`}
                  activeClassName="border-primary"
                >
                  Projects
                </NavLink>
              </div>
            </div>

            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <div className="flex">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => setDropdownVisible(true)}
                >
                  <Avatar
                    name={currentUser?.displayName || currentUser?.email}
                    round
                    size="40"
                    maxInitials={1}
                  />
                  <span className="text-xl md:text-lg mx-2">
                    {currentUser?.displayName || userData?.email}
                  </span>
                  <ChevronDownIcon />
                </div>
                <Dropdown
                  onDismiss={() => setDropdownVisible(false)}
                  isOpen={dropdownVisible}
                  options={dropdownOptions}
                  onSelect={(value) => history.push(value)}
                  style={{ top: 50 }}
                />
                {showUpgradeYourPlan &&
                  planType === PlanTypes.FREE &&
                  isVerified && (
                    <Button
                      color="primary"
                      variant="outlined"
                      shape="rounded"
                      className="px-5 ml-4"
                      onClick={() => history.push('/subscription')}
                    >
                      Upgrade your plan
                    </Button>
                  )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="mt-20" />
    </>
  );
};
