import { BoneConfig } from './human.config';
import { SVG, Svg } from '@svgdotjs/svg.js';
import { simplifyEvent } from '@editor/utils/touch.utils';
import { calculateAngle, getOriginOffset, radToDeg } from './engine.utils';
import { CanvasSkeletonBone } from '@editor/utils/canvas.types';
import { createLogger, Logger } from '@common/utils/logger';

interface JointOptions {
  parentObjectId: string;
  bone: BoneConfig;
  svg: Svg;
  canvasScale: number;
  boneFromState: CanvasSkeletonBone;
  setPropertyFn: Function;
  flipHorizontally: boolean;
  onClickWithoutMove?: Function;
  locked?: boolean;
}

export class Joint {
  logger: Logger;

  bone: BoneConfig;
  svg: Svg;
  canvasScale: number;
  boneFromState: CanvasSkeletonBone;
  setPropertyFn: Function;
  flipHorizontally: boolean;

  jointId: string;
  jointNode: Svg;
  pivotPointNode: Svg;
  rotationHandleNode: Svg;
  initialOffset: { x: number; y: number };
  angle: number;
  onClickWithoutMove: Function;

  onRotateStart?: Function;
  onRotateEnd?: Function;
  
  locked: boolean;

  constructor(options: JointOptions) {
    const {
      parentObjectId,
      bone,
      svg,
      canvasScale,
      boneFromState,
      setPropertyFn,
      flipHorizontally,
      onClickWithoutMove,
      locked = false,
    } = options;

    this.logger = createLogger(
      `Object:${parentObjectId}:Joint:${bone.jointId}`
    );

    this.bone = bone;
    this.svg = svg;
    this.canvasScale = canvasScale;
    this.boneFromState = boneFromState;
    this.setPropertyFn = setPropertyFn;
    this.flipHorizontally = flipHorizontally;
    this.onClickWithoutMove = onClickWithoutMove;
    this.locked = locked;

    this.jointId = bone.jointId;
    this.logger.debug('Initializing Joint');

    const { angle } = boneFromState.properties;
    this.angle = angle;
    this.initializeNodes();
  }

  setLocked(locked: boolean) {
    this.locked = locked;
  }

  private initializeNodes() {
    this.logger.debug(`initializeNodes`);

    this.jointNode = SVG(this.svg.findOne(`#${this.jointId}`)) as Svg;
    this.jointNode.node.classList.add('joint');
    this.jointNode.node.classList.add('rotatable');

    // pivotPointNode
    const {
      customPivotPointSelector,
      customRotateHandleSelector,
      jointId,
    } = this.bone;
    let pivotPointNode = this.svg.findOne(
      customPivotPointSelector || `#${jointId}PivotPoint`
    );
    this.pivotPointNode = SVG(pivotPointNode) as Svg;

    // rotationHandleNode
    this.rotationHandleNode = customRotateHandleSelector
      ? (SVG(this.svg.findOne(customRotateHandleSelector)) as Svg)
      : this.jointNode;

    // save initial offset
    this.initialOffset = this.getOriginOffset();
  }

  public applyRotationLogic() {
    const { rotationHandleNode } = this;

    // Convert touch events into mouse events
    // mapTouchToMouse(rotationHandleNode.node, true);
    rotationHandleNode.node.addEventListener('mousedown', (e) =>
      !this.locked && this.mouseDownHandler(e)
    );
    rotationHandleNode.node.addEventListener('touchstart', (e) =>
      !this.locked && this.mouseDownHandler(e)
    );
  }

  private mouseDownHandler(e: MouseEvent | TouchEvent) {
    const { clientX, clientY, type, stylus, multiTouch } = simplifyEvent(e);

    if (multiTouch) {
      return;
    }

    e.stopPropagation();
    
    let didMove = false;
    
    if (this.onRotateStart) {
      this.onRotateStart();
    }
    
    const MOVE_BUFFER_TIME = stylus ? 150 : 0;
    
    const offset = this.getOriginOffset();
    const {
      left: excessX,
      top: excessY,
    } = this.svg.node.getBoundingClientRect();
    const xFromCenter = clientX - excessX - offset.x;
    const yFromCenter = clientY - excessY - offset.y;

    const mouseStartAngle = Math.atan2(yFromCenter, xFromCenter);
    let newAngle: number = this.angle;
    const mouseDownTime = Date.now();

    const mouseMoveHandler = (e: MouseEvent) => {
      e.stopPropagation();

      const timeDiffSinceMouseDown = Date.now() - mouseDownTime;
      if (timeDiffSinceMouseDown < MOVE_BUFFER_TIME) {
        return;
      }

      const { clientX, clientY } = simplifyEvent(e);

      newAngle = calculateAngle(
        clientX,
        clientY,
        mouseStartAngle,
        this.angle,
        offset,
        this.svg.node,
        this.flipHorizontally
      );

      this.renderAngle(newAngle, this.initialOffset);
      didMove = true;
    };

    const mouseMoveEventName = type === 'mouse' ? 'mousemove' : 'touchmove';
    const mouseUpEventName = type === 'mouse' ? 'mouseup' : 'touchend';

    const mouseUpHandler = () => {
      window.removeEventListener(mouseUpEventName, mouseUpHandler);
      window.removeEventListener(mouseMoveEventName, mouseMoveHandler);
      const mouseUpTime = Date.now();
      const isDiffIndicatingNoMove = (mouseUpTime - mouseDownTime) < MOVE_BUFFER_TIME;

      this.angle = newAngle;
      this.setPropertyFn('angle', this.angle);

      if ((!didMove || isDiffIndicatingNoMove) && this.onClickWithoutMove) {
        this.onClickWithoutMove();
      }

      if (this.onRotateEnd) {
        this.onRotateEnd();
      }
    };

    window.addEventListener(mouseMoveEventName, mouseMoveHandler);
    window.addEventListener(mouseUpEventName, mouseUpHandler);
  }

  public renderAngle(
    angleValue: number,
    offset: { x: number; y: number },
    compensate: number = 0
  ) {
    this.logger.debug(`render offset is ${offset.x} ${offset.y}`);
    const actualOffset = this.getOriginOffset();
    this.logger.debug(`actual offset is ${actualOffset.x} ${actualOffset.y}`);

    const cx = (offset.x + compensate) / this.canvasScale;
    const cy = offset.y / this.canvasScale;

    this.logger.debug(`rotate`, `rotate(${radToDeg(angleValue)} ${cx} ${cy})`);
    this.jointNode.attr(
      'transform',
      `rotate(${radToDeg(angleValue)} ${cx} ${cy})`
    );
  }

  private getOriginOffset() {
    return getOriginOffset(this.pivotPointNode.node, this.svg.node);
  }
}
