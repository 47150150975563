import React from 'react';
import { Modal } from '../Modal';

export const ErrorMessageModal = ({ resetErrorBoundary }) => (
  <Modal
    isOpen={true}
    title="Oops!"
    onDismiss={resetErrorBoundary}
    className="w-1/3 md:w-1/2 lg:w-1/3"
    withCloseBtn
  >
    Oops! Something went wrong. The error has been reported and our engineering
    team will make sure to address it as soon as possible. If you are still
    experiencing issues, please contact us at{' '}
    <a href="mailto:hello@posturize.app" className="text-primary">
      hello@posturize.app
    </a>
    . Sorry for the inconvenience.
  </Modal>
);
